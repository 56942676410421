/* eslint-disable no-template-curly-in-string */

import { AssetItem } from "./AssetItem";
import { Withdraw } from "./Withdraw";
import { Deposit } from "./Deposit";
import { Receive } from "./Receive";
import { Send } from "./Send";

export const Assets = {
  component: "@orderbook/fe-assets",
  children: {
    component: "@ambisafe/ui-list",
    props: {
      variable: "${assets}"
    },
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        mb: "xxxs"
      },
      children: {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.15)"
        },
        children: {
          component: "@ambisafe/ui-background",
          props: {
            backgroundColor: "elements.cardBackground"
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: "xs"
            },
            children: [
              AssetItem,
              Deposit,
              Receive,
              Send,
              Withdraw,
            ]
          }
        }
      }
    }
  }
};
