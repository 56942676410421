/* eslint-disable no-template-curly-in-string */

export const ProjectDiscounts = {
  component: "@ambisafe/ui-layout/{Spacing}",
  children: [
    {
      component: "@ambisafe/ui-text/{Heading4}",
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: {
          component: "react-intl/{FormattedMessage}",
          props: {
            id: "discount"
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: {
        component: "@ambisafe/ui-list",
        props: {
          variable: "${project.promo.stepsProperties.steps}"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 4
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "uppercase",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: [
                          {
                            component: "react-intl/{FormattedMessage}",
                            props: {
                              id: "from"
                            }
                          },
                          {
                            component: "span",
                            children: " "
                          },
                          {
                            component: "@ambisafe/ui-text/{Heading5}",
                            props: {
                              noBottomGutter: true,
                              inline: true,
                              color: "primary.main"
                            },
                            children: {
                              component: "@ambisafe/ui-text/{Normal}",
                              children: [
                                {
                                  component: "span",
                                  children: "$"
                                },
                                {
                                  component: "react-intl/{FormattedNumber}",
                                  props: {
                                    value: "${from}"
                                  }
                                }
                              ]
                            }
                          },
                          {
                            component: "br",
                            children: " "
                          },
                          {
                            component: "react-intl/{FormattedMessage}",
                            props: {
                              id: "to"
                            }
                          },
                          {
                            component: "span",
                            children: " "
                          },
                          {
                            component: "@ambisafe/ui-text/{Heading5}",
                            props: {
                              noBottomGutter: true,
                              inline: true,
                              color: "primary.main"
                            },
                            children: {
                              component: "@ambisafe/ui-text/{Normal}",
                              children: [
                                {
                                  component: "span",
                                  children: "$"
                                },
                                {
                                  component: "react-intl/{FormattedNumber}",
                                  props: {
                                    value: "${to}"
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading2}",
                      props: {
                        noBottomGutter: true,
                        color: "primary.main"
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children: [
                          {
                            component: "react-intl/{FormattedNumber}",
                            props: {
                              value: "${value}"
                            }
                          },
                          {
                            component: "span",
                            children: "%"
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        pb: "sm"
      }
    }
  ]
};
