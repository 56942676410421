/* eslint-disable no-template-curly-in-string */
import { PhoneConfirmationContainer } from "../PhoneConfirmationContainer";

const Form = {
  component: "@ambisafe/ui-form/{Form}",
  props: {
    onSubmit: "${recoveryVerifyPhoneCode}"
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        pb: "xxs"
      },
      children: {
        component: "@ambisafe/ui-form/{Field}",
        props: {
          name: "verificationCode",
          type: "text",
          label: "form.phone_code",
          placeholder: "",
          validate: ["required"]
        }
      }
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        pb: "xxs"
      },
      children: {
        component: "@ambisafe/ui-text/{Paragraph}",
        children: [
          {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: "havent_received_phone_code"
            }
          },
          {
            component: "span",
            children: " "
          },
          {
            component: "@ambisafe/react-router-dom/{Link}",
            props: {
              display: "${resendDisplay}",
              onClick: "${resendPhoneCode}",
              disabled: "${isResendDisabled}"
            },
            children: {
              component: "@ambisafe/locale/{FormattedMessage}",
              props: {
                id: "resend_phone_code"
              }
            }
          },
          {
            component: "@ambisafe/fe-statements/{IfTrue}",
            props: {
              if: "${verifyPhoneCodeError}"
            },
            children: {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                color: "error.main",
                noBottomGutter: true,
              },
              children: "${verifyPhoneCodeError}"
            }
          },
          {
            component: "@ambisafe/fe-statements/{IfTrue}",
            props: {
              if: "${resendPhoneCodeError}"
            },
            children: {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                color: "error.main"
              },
              children: "${resendPhoneCodeError}"
            }
          }
        ]
      }
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        pb: "xxs"
      },
      children: {
        component: "@ambisafe/ui-button",
        props: {
          type: "submit",
          width: "100%"
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            py: "xxs",
            px: "xs"
          },
          children: {
            component: "@ambisafe/ui-text/{Paragraph}",
            props: {
              noBottomGutter: true,
              textAlign: "center",
              textTransform: "uppercase"
            },
            children: {
              component: "react-intl/{FormattedMessage}",
              props: {
                id: "confirm_phone"
              }
            }
          }
        }
      }
    }
  ]
};

export default {
  component: "@orderbook/fe-recovery-phone-code",
  children: PhoneConfirmationContainer(Form)
};

export const MigrationPhoneConfirm = {
  component: "@orderbook/fe-recovery-phone-code/{MigrationPhoneCode}",
  children: PhoneConfirmationContainer(Form)
};
