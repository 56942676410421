/* eslint-disable no-template-curly-in-string */

import { Form } from "./Form";
import { Confirmation } from "./Confirmation";
import { Congratulations } from "./Congratulations";

export const TradeDialog = {
  component: "@ambisafe/react-router-dom/{Switch}",
  children: [
    Form,
    Confirmation,
    Congratulations
  ]
};
