/* eslint-disable no-template-curly-in-string */
import { FormFields } from "./FormFields";
import { FormControls } from "./FormControls";
import { SubmitErrors } from "./SubmitErrors";

export const Crypto = {
  component: "@ambisafe/fe-statements/{IfEqualToSome}",
  props: {
    if: "${type}",
    to: ["CRYPTO", "PROJECT", "BTC", "CREDIT"]
  },
  children: [
    {
      component: "@ambisafe/ui-divider",
      props: {
        opacity: 0.2
      }
    },
    {
      component: "@ambisafe/ui-form/{Form}",
      props: {
        onSubmit: "${handleWithdrawalSubmit}",
        disabled: "${selectedForWithdrawServerCode}"
      },
      children: [
        FormFields,
        FormControls,
        SubmitErrors
      ]
    }
  ]
};
