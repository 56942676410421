/* eslint-disable no-template-curly-in-string */
export const FormControls = {
  component: "@ambisafe/ui-layout/{GridRow}",
  children: [
    {
      component: "@ambisafe/ui-layout/{GridCol}",
      props: {
        flex: {
          mobile: "auto",
          tablet: 1
        },
        width: {
          mobile: "100%",
          tablet: "auto"
        }
      },
      children: {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          color: "text.secondary"
        },
        children: {
          component: "@ambisafe/locale/{FormattedMessage}",
          props: {
            id: "withdrawal_fees_disclaimer"
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-layout/{GridCol}",
      props: {
        alignRight: true
      },
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        children: [
          {
            component: "@ambisafe/ui-layout/{GridCol}",
            children: {
              component: "@ambisafe/react-router-dom/{Link}",
              props: {
                to: "${match.url}"
              },
              children: {
                component: "@ambisafe/ui-button",
                props: {
                  inverse: true,
                  width: "100%"
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    py: "xxs",
                    px: "xs"
                  },
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      noBottomGutter: true,
                      textAlign: "center",
                      textTransform: "uppercase"
                    },
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "cancel"
                      }
                    }
                  }
                }
              }
            }
          },
          {
            component: "@ambisafe/ui-layout/{GridCol}",
            children: {
              component: "@ambisafe/ui-button",
              props: {
                type: "submit",
                width: "100%"
              },
              children: {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  py: "xxs",
                  px: "xs"
                },
                children: {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  props: {
                    noBottomGutter: true,
                    textAlign: "center",
                    textTransform: "uppercase"
                  },
                  children: {
                    component: "react-intl/{FormattedMessage}",
                    props: {
                      id: "confirm"
                    }
                  }
                }
              }
            }
          }
        ]
      }
    }
  ]
};
