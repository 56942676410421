/* eslint-disable no-template-curly-in-string */
export default {
  colors: {
    text: {
      primary: "black600",
      contrast: "white600",
      secondary: "grey600"
    },
    primary: {
      dark: "blue700",
      main: "blue600",
      light: "blue500",
      contrastText: "white600"
    },
    secondary: {
      dark: "blue700",
      main: "blue600",
      light: "blue500",
      contrastText: "white600"
    },
    success: {
      dark: "blue700",
      main: "blue600",
      light: "blue500",
      contrastText: "white600"
    },
    warning: {
      dark: "orange700",
      main: "orange600",
      light: "orange500",
      contrastText: "black600"
    },
    error: {
      dark: "red700",
      main: "red600",
      light: "red500",
      contrastText: "black600"
    },
    elements: {
      bodyBackground: "white600",
      cardBackground: "white100",
      headerBackground: "white300",
      footerBackground: "black600"
    }
  },
  text: {
    size: { xxxxl: "3.5rem" },
    fontFamily: "sans-serif",
    lineHeight: 1.5,
    letterSpacing: "0.3px"
  },
  input: { borderRadius: "2px" },
  button: { standard: { borderRadius: "2px" } },
  container: { maxWidth: "1200px" },
  fonts: []
};
