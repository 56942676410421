/* eslint-disable no-template-curly-in-string */

export const SignedDocument = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${documents.length}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Container}",
    props: {
      display: "flex",
      flex: "wrap"
    },
    children: [
      {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          lineHeight: 1,
          color: "text.secondary",
        },
        children: [
          {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: "Documents"
            }
          },
          {
            component: "span",
            children: ": "
          }
        ]
      },
  
      {
        component: "@ambisafe/ui-list",
        props: {
          variable: "${documents}"
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pb: "0.4rem",
            pl: "0.5rem"
          },
          children: {
            component: "@ambisafe/react-router-dom/{Link}",
            props: {
              to: "${match.url}/view/${type}/${signedDocumentId}",
            },
            children: {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                lineHeight: 1,
                color: "text.main",
                noBottomGutter: true
              },
              children: "${displayName}"
            }
          }
        }
      }
    ]
  }
};

export const Document = {
  component: "@ambisafe/react-router-dom/{WithRouter}",
  children: {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/view/:type/:documentId"
    },
    children: {
      component: "@orderbook/fe-project-show-document",
      props: {
        documentId: "${match.params.documentId}",
        type: "${match.params.type}",
      },
      children: {
        component: "@ambisafe/ui-fullscreen-modal",
        props: {
          onClose: "${onDocumentClose}"
        },
        children: {
          component: "@ambisafe/ui-show-base64-file",
          props: {
            fileBase64: "${fileBase64}"
          }
        }
      }
    }
  }
};
