/* eslint-disable no-template-curly-in-string */

import { DepositForm } from "./DepositForm";
import { Disclaimer } from "./Disclaimer";
import { Document } from "./Document";

export const Fiat = {
  component: "@ambisafe/fe-statements/{IfEqualToSome}",
  props: {
    if: "${type}",
    to: ["FIAT", "CRYPTOFIAT"]
  },
  children: [
    {
      component: "@ambisafe/ui-divider",
      props: {
        opacity: 0.2
      }
    },
    {
      component: "@orderbook/fe-project-show-fiat-deposit-document",
      props: {
        assetId: "${assetId}"
      },
      children: [
        Document,
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            DepositForm,
            Disclaimer
          ]
        },
      ]
    }
  ]
};
