/* eslint-disable no-template-curly-in-string */
const SocialIcon = (icon, path) => ({
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    px: "xxs"
  },
  children: {
    component: "@ambisafe/react-router-dom/{Link}",
    props: {
      to: path,
      rel: "noopener noreferrer",
      target: "_blank",
      height: "100%"
    },
    children: {
      component: "@ambisafe/ui-button",
      props: {
        colors: {
          default: {
            color: "text.contrast",
            borderColor: "transparent",
            backgroundColor: "transparent"
          },
          disabled: {
            color: "text.contrast",
            borderColor: "transparent",
            backgroundColor: "transparent"
          },
          hovered: {
            color: "text.contrast",
            borderColor: "transparent",
            backgroundColor: "transparent"
          },
          focused: {
            color: "text.contrast",
            borderColor: "transparent",
            backgroundColor: "transparent"
          }
        }
      },
      children: {
        component: "@ambisafe/ui-icons/{Icon}",
        props: {
          color: "grey500",
          icon: icon,
          width: "28px",
          height: "22px"
        }
      }
    }
  }
});

export const FollowUs = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    span: {
      mobile: 12,
      tablet: 5,
      laptop: 4
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    props: {
      display: "flex",
      margin: "auto",
      flexWrap: "wrap",
      justifyContent: {
        mobile: "space-around",
        tablet: "space-between"
      }
    },
    children: [
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          ml: 0,
          pl: 0
        },
        children: {
          component: "@ambisafe/ui-text/{Heading5}",
          props: {
            color: "grey100"
          },
          children: "Follow us:"
        }
      },
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          span: 12
        },
        children: {
          component: "@ambisafe/ui-layout/{Container}",
          props: {
            margin: {
              mobile: "0 auto",
              tablet: "0 0"
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{GridRow}",
            props: {
              display: "flex",
              margin: "auto",
              flexWrap: "wrap",
              justifyContent: {
                mobile: "space-around",
                tablet: "space-between"
              },
              textAlign: "center"
            },
            children: [
              SocialIcon("twitter", "https://twitter.com/ohanae"),
              SocialIcon("facebook", "https://www.facebook.com/ohanaeplatform"),
              SocialIcon("instagram", "https://www.instagram.com/ohanae.inc/"),
              SocialIcon("linkedin", "https://www.linkedin.com/company/ohanae/"),
              SocialIcon("youtube", "https://www.youtube.com/@ohanaeplatform7007"),
            ]
          }
        }
      }
    ]
  }
};
