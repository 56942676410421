/* eslint-disable no-template-curly-in-string */
const Title = {
  component: "@ambisafe/ui-layout/{Spacing}",
  children: {
    component: "@ambisafe/ui-text/{Heading4}",
    props: {
      noBottomGutter: true
    },
    children: {
      component: "@ambisafe/ui-text/{Medium}",
      children: "${title}"
    }
  }
};

const Offering = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "50%",
    alignItems: "left"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true,
        opacity: 0.6
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: "Offering"
      }
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true
      },
      children: {
        component: "@ambisafe/ui-text/{Light}",
        children:  "${offeringType}"
      }
    }
  ] 
};

const OfferingSize = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "50%",
    alignItems: "left"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true,
        opacity: 0.6,
        width: "50%",
        alignItems: "left"
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: "Offering Size"
      }
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true
      },
      children: {
        component: "@ambisafe/ui-text/{Light}",
        children: [
          {
            component: "span",
            children: "$"
          },
          {
            component: "react-intl/{FormattedNumber}",
            props: {
              value: "${offeringSize}"
            }
          }
        ]
      }
    }
  ] 
};

const StartDate = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "50%",
    alignItems: "left"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true,
        opacity: 0.6
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: "Open Date"
      }
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true
      },
      children: {
        component: "@ambisafe/ui-text/{Light}",
        children: {
          component: "react-intl/{FormattedDate}",
          props: {
            year: "numeric",
            month: "short",
            day: "2-digit",
            value: "${startDate}"
          }
        }
      }
    }
  ] 
};

const EndDate = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "50%",
    alignItems: "left"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true,
        opacity: 0.6
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: "Closing Date"
      }
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true
      },
      children: {
        component: "@ambisafe/ui-text/{Light}",
        children: {
          component: "react-intl/{FormattedDate}",
          props: {
            year: "numeric",
            month: "short",
            day: "2-digit",
            value: "${endDate}"
          }
        }
      }
    }
  ]     
};

const MinInvestmentInUSD = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "50%",
    alignItems: "left"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true,
        textTransform: "capitalize",
        opacity: 0.6
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: "Min Investment"
      }
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true
      },
      children: {
        component: "@ambisafe/ui-text/{Light}",
        children: [
          {
            component: "span",
            children: "$"
          },
          {
            component: "react-intl/{FormattedNumber}",
            props: {
              value: "${minInvestmentInUSD}"
            }
          }
        ]
      }
    }
  ]
};

const OfferingMax = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "50%",
    alignItems: "left"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true,
        opacity: 0.6
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: "Offering Max"
      }
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true
      },
      children: {
        component: "@ambisafe/ui-text/{Light}",
        children: [
          {
            component: "span",
            children: "$"
          },
          {
            component: "react-intl/{FormattedNumber}",
            props: {
              value: "${hardCap}"
            }
          }
        ]
      }
    }
  ]
};

export const ProjectInfo = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    flex: 1
  },
  children: {
    component: "@ambisafe/ui-background",
    props: {
      backgroundColor: "white100",
      height: "100%"
    },
    children: {
      component: "@ambisafe/ui-layout/{Container}",
      children: [
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            px: "xs",
            py: "sm"
          },
          children: [
            Title,
            {
              component: "@ambisafe/ui-divider"
            },
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                pb: "xs"
              },
              children: [   
                {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  props: {
                    justifyContent: "space-between"
                  },
                  children: [
                    Offering,
                    OfferingSize
                  ]
                }
              ]
            },
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                pb: "xs"
              },
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  props: {
                    justifyContent: "space-between"
                  },
                  children: [
                    StartDate,
                    EndDate
                  ]
                }
              ] 
            },
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  props: {
                    justifyContent: "space-between"
                  },
                  children: [
                    MinInvestmentInUSD,
                    OfferingMax
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
};
