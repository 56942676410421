/* eslint-disable no-template-curly-in-string */
import { Logo } from "./Logo";
import { LocaleSwitcher } from "./LocaleSwitcher";
import { Links } from "./Links";

export const Header = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    position: "fixed",
    width: "100%",
    zIndex: 2,
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
  },
  children: {
    component: "@ambisafe/ui-background",
    props: {
      backgroundColor: "elements.headerBackground",
      height: "100%"
    },
    children: {
      component: "@ambisafe/ui-layout/{GridContainer}",
      children: {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          fluid: false
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          props: {
            height: "54px",
            alignItems: "center"
          },
          children: [
            Logo,
            LocaleSwitcher,
            Links
          ]
        }
      }
    }
  }
};
