/* eslint-disable no-template-curly-in-string */
export const Divider = {
  component: "@ambisafe/ui-divider",
  props: {
    opacity: 0.2
  }
};

export const RecepientAddressField = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    span: {
      mobile: 12,
      tablet: 12,
      tabletLandscape: 7,
      laptop: 6
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "xs"
    },
    children: [
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          noBottomGutter: true,
          gutter: 0
        },
        children: [
          {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              mb: "xxs",
              ml: "xs",
            },
            children: {
              component: "@ambisafe/ui-layout/{GridRow}",
              props: {
                alignItems: "center"
              },
              children: [
                {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  props: {
                    fontSize: "sm",
                    whiteSpace: "pre",
                    inline: true
                  },
                  children: [
                    {
                      component: "@ambisafe/locale/{FormattedMessage}",
                      props: {
                        id: "form.withdraw_to_address"
                      }
                    },
                    " (", "${providerDescription}", ")"
                  ]
                }
              ]
            }
          },
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "withdrawToAddress",
              type: "text",
              placeholder: "",
              validate: ["required", "isHex"]
            }
          }
        ]
      },
    ]
  }
};

export const AmountField = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    span: {
      mobile: 12,
      tablet: 6,
      tabletLandscape: 2,
      laptop: 3
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "xs"
    },
    children: {
      component: "@ambisafe/ui-form/{Field}",
      props: {
        name: "amount",
        type: "text",
        label: "form.amount",
        placeholder: "",
        validate: ["required"],
        onChange: "${handleWithdrawAmountChange}"
      }
    }
  }
};

export const TotalValues = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    span: {
      mobile: 12,
      tablet: 6,
      tabletLandscape: 3,
      laptop: 3
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "xs"
    },
    children: [
      {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          fontSize: "sm"
        },
        children: [
          {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: "total"
            }
          },
          {
            component: "span",
            children: " ("
          },
          {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: "incl_fee"
            }
          },
          {
            component: "span",
            children: " "
          },
          {
            component: "span",
            children: "${withdrawFee}"
          },
          {
            component: "span",
            children: " "
          },
          {
            component: "span",
            children: "${displaySymbol}"
          },
          {
            component: "span",
            children: ")"
          }
        ]
      },
      {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          noBottomGutter: true,
          lineHeight: 1,
          fontSize: "lg"
        },
        children: "${totalWithdrawAmount}"
      },
      {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          noBottomGutter: true,
          color: "text.secondary",
          lineHeight: 1,
          fontSize: "sm"
        },
        children: [
          {
            component: "span",
            children: "≈"
          },
          {
            component: "@ambisafe/locale/{FormattedNumber}",
            props: {
              value: "${totalWithdrawPriceInUSD}"
            }
          },
          {
            component: "span",
            children: " "
          },
          {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: "usd"
            }
          }
        ]
      }
    ]
  }
};

export const Disclaimer = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    flex: {
      mobile: "auto",
      tablet: 1
    },
    width: {
      mobile: "100%",
      tablet: "auto"
    }
  },
  children: {
    component: "@ambisafe/ui-text/{Paragraph}",
    props: {
      color: "text.secondary"
    },
    children: {
      component: "@ambisafe/locale/{FormattedMessage}",
      props: {
        id: "withdrawal_fees_disclaimer"
      }
    }
  }
};

export const Buttons = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    alignRight: true
  },
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    children: [
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        children: {
          component: "@ambisafe/react-router-dom/{Link}",
          props: {
            to: "${match.url}"
          },
          children: {
            component: "@ambisafe/ui-button",
            props: {
              inverse: true,
              width: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                py: "xxs",
                px: "xs"
              },
              children: {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  noBottomGutter: true,
                  textAlign: "center",
                  textTransform: "uppercase"
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "cancel"
                  }
                }
              }
            }
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        children: {
          component: "@ambisafe/ui-button",
          props: {
            type: "submit",
            width: "100%"
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              py: "xxs",
              px: "xs"
            },
            children: {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                noBottomGutter: true,
                textAlign: "center",
                textTransform: "uppercase"
              },
              children: {
                component: "react-intl/{FormattedMessage}",
                props: {
                  id: "confirm"
                }
              }
            }
          }
        }
      }
    ]
  }
};

export const SubmitErrors = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${submitErrors}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pt: "xxs"
    },
    children: {
      component: "@ambisafe/ui-form/{SubmitError}"
    }
  }
};
