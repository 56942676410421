/* eslint-disable no-template-curly-in-string */
export const BankInfo = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.url}/bank-info"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "xl"
    },
    children: [
      {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          hide: {
            tablet: true
          }
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            mb: "sm"
          },
          children: {
            component: "@ambisafe/react-router-dom/{Link}",
            props: {
              to: "/account"
            },
            children: {
              component: "@ambisafe/ui-icons/{ArrowLeft}"
            }
          }
        }
      },
      {
        component: "@ambisafe/ui-text/{Heading2}",
        children: {
          component: "react-intl/{FormattedMessage}",
          props: {
            id: "bank_info"
          }
        }
      },
      {
        component: "@orderbook/fe-bank-info",
        children: [
          {
            component: "@ambisafe/ui-list",
            props: {
              variable: "${userBankInfos}"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                mb: "xxs"
              },
              children: {
                component: "@ambisafe/ui-layout/{Container}",
                props: {
                  boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.15)"
                },
                children: {
                  component: "@ambisafe/ui-background",
                  props: {
                    backgroundColor: "elements.cardBackground"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      p: "sm"
                    },
                    children: [
                      {
                        component: "@ambisafe/ui-layout/{GridRow}",
                        props: {
                          alignItems: "center"
                        },
                        children: [
                          {
                            component: "@ambisafe/ui-layout/{GridCol}",
                            children: {
                              component: "@ambisafe/ui-text/{Heading4}",
                              props: {
                                noBottomGutter: true
                              },
                              children: {
                                component: "@ambisafe/ui-text/{Normal}",
                                children: "${title}"
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridCol}",
                            props: {
                              alignRight: true
                            },
                            children: [
                              {
                                component: "@ambisafe/fe-statements/{IfEqualTo}",
                                props: {
                                  if: "${id}",
                                  to: "${selectedBankInfoId}"
                                },
                                children: {
                                  component: "@ambisafe/react-router-dom/{Link}",
                                  props: {
                                    to: "${match.url}"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-icons/{Collapse}"
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/fe-statements/{IfNotEqualTo}",
                                props: {
                                  if: "${id}",
                                  to: "${selectedBankInfoId}"
                                },
                                children: {
                                  component: "@ambisafe/react-router-dom/{Link}",
                                  props: {
                                    to: "${match.url}?selectedBankInfoId=${id}"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-icons/{Expand}"
                                  }
                                }
                              }
                            ]
                          }
                        ]
                      },
                      {
                        component: "@ambisafe/fe-statements/{IfEqualTo}",
                        props: {
                          if: "${id}",
                          to: "${selectedBankInfoId}"
                        },
                        children: [
                          {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              py: "xxs"
                            },
                            children: {
                              component: "@ambisafe/ui-divider",
                              props: {
                                opacity: 0.3
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-form/{Form}",
                            props: {
                              onSubmit: "${handleUserBankInfoUpdate}",
                              initialValues: "${initialValues}"
                            },
                            children: [
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  px: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{GridRow}",
                                  children: [
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      props: {
                                        span: 12
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{Spacing}",
                                        props: {
                                          mb: "xs"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-form/{Field}",
                                          props: {
                                            name: "title",
                                            type: "text",
                                            label: "form.title",
                                            placeholder: "",
                                            validate: ["required"]
                                          }
                                        }
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      props: {
                                        span: 12
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{Spacing}",
                                        props: {
                                          mb: "xs"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-form/{Field}",
                                          props: {
                                            name: "default",
                                            type: "checkbox",
                                            label: "form.default",
                                            validate: []
                                          }
                                        }
                                      }
                                    }
                                  ]
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  px: "xs"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{Spacing}",
                                    props: {
                                      mb: "sm"
                                    },
                                    children: {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true
                                      },
                                      children: {
                                        component: "@ambisafe/locale/{FormattedMessage}",
                                        props: {
                                          id: "beneficiary_bank_details"
                                        }
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      props: {
                                        span: 12
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{Spacing}",
                                        props: {
                                          mb: "xs"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-form/{Field}",
                                          props: {
                                            name: "beneficiaryBankName",
                                            type: "text",
                                            label: "form.bankName",
                                            placeholder: "",
                                            validate: ["required"]
                                          }
                                        }
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      props: {
                                        span: 12
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{Spacing}",
                                        props: {
                                          mb: "xs"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-form/{Field}",
                                          props: {
                                            name: "beneficiaryBankAddress",
                                            type: "text",
                                            label: "form.bankAddress",
                                            placeholder: "",
                                            validate: ["required"]
                                          }
                                        }
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    children: [
                                      {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        props: {
                                          span: 6
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{Spacing}",
                                          props: {
                                            mb: "xs"
                                          },
                                          children: {
                                            component: "@ambisafe/ui-form/{Field}",
                                            props: {
                                              name: "beneficiaryCountry",
                                              type: "select",
                                              options: "${countries}",
                                              label: "form.country",
                                              placeholder: "",
                                              validate: ["required"]
                                            }
                                          }
                                        }
                                      },
                                      {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        props: {
                                          span: 6
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{Spacing}",
                                          props: {
                                            mb: "xs"
                                          },
                                          children: {
                                            component: "@ambisafe/ui-form/{Field}",
                                            props: {
                                              name: "beneficiarySwiftBicCode",
                                              type: "text",
                                              label: "form.swiftBicCode",
                                              placeholder: "",
                                              validate: ["required"]
                                            }
                                          }
                                        }
                                      }
                                    ]
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      props: {
                                        span: 12
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{Spacing}",
                                        props: {
                                          mb: "xs"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-form/{Field}",
                                          props: {
                                            name: "beneficiaryIBAN",
                                            type: "text",
                                            label: "form.IBAN",
                                            placeholder: "",
                                            validate: ["required"]
                                          }
                                        }
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    children: [
                                      {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        props: {
                                          span: 6
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{Spacing}",
                                          props: {
                                            mb: "xs"
                                          },
                                          children: {
                                            component: "@ambisafe/ui-form/{Field}",
                                            props: {
                                              name: "beneficiaryClearingCode",
                                              type: "text",
                                              label: "form.clearingCode",
                                              placeholder: "",
                                              validate: ["required"]
                                            }
                                          }
                                        }
                                      },
                                      {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        props: {
                                          span: 6
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{Spacing}",
                                          props: {
                                            mb: "xs"
                                          },
                                          children: {
                                            component: "@ambisafe/ui-form/{Field}",
                                            props: {
                                              name: "beneficiaryClearingCodeDescription",
                                              type: "text",
                                              label: "form.clearingCodeDescription",
                                              placeholder: "",
                                              validate: ["required"]
                                            }
                                          }
                                        }
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  pt: "xxs",
                                  pb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-divider",
                                  props: {
                                    opacity: 0.3
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  px: "xs"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{Spacing}",
                                    props: {
                                      mb: "sm"
                                    },
                                    children: {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true
                                      },
                                      children: {
                                        component: "@ambisafe/locale/{FormattedMessage}",
                                        props: {
                                          id: "intermediary_bank_details"
                                        }
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      props: {
                                        span: 12
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{Spacing}",
                                        props: {
                                          mb: "xs"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-form/{Field}",
                                          props: {
                                            name: "intermediaryBankName",
                                            type: "text",
                                            label: "form.bankName",
                                            placeholder: "",
                                            validate: []
                                          }
                                        }
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      props: {
                                        span: 12
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{Spacing}",
                                        props: {
                                          mb: "xs"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-form/{Field}",
                                          props: {
                                            name: "intermediaryBankAddress",
                                            type: "text",
                                            label: "form.bankAddress",
                                            placeholder: "",
                                            validate: []
                                          }
                                        }
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    children: [
                                      {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        props: {
                                          span: 6
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{Spacing}",
                                          props: {
                                            mb: "xs"
                                          },
                                          children: {
                                            component: "@ambisafe/ui-form/{Field}",
                                            props: {
                                              name: "intermediaryCountry",
                                              type: "select",
                                              options: "${countries}",
                                              label: "form.country",
                                              placeholder: "",
                                              validate: []
                                            }
                                          }
                                        }
                                      },
                                      {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        props: {
                                          span: 6
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{Spacing}",
                                          props: {
                                            mb: "xs"
                                          },
                                          children: {
                                            component: "@ambisafe/ui-form/{Field}",
                                            props: {
                                              name: "intermediarySwiftBicCode",
                                              type: "text",
                                              label: "form.swiftBicCode",
                                              placeholder: "",
                                              validate: []
                                            }
                                          }
                                        }
                                      }
                                    ]
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    children: [
                                      {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        props: {
                                          span: 6
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{Spacing}",
                                          props: {
                                            mb: "xs"
                                          },
                                          children: {
                                            component: "@ambisafe/ui-form/{Field}",
                                            props: {
                                              name: "intermediaryClearingCode",
                                              type: "text",
                                              label: "form.clearingCode",
                                              placeholder: "",
                                              validate: []
                                            }
                                          }
                                        }
                                      },
                                      {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        props: {
                                          span: 6
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{Spacing}",
                                          props: {
                                            mb: "xs"
                                          },
                                          children: {
                                            component: "@ambisafe/ui-form/{Field}",
                                            props: {
                                              name: "intermediaryClearingCodeDescription",
                                              type: "text",
                                              label: "form.clearingCodeDescription",
                                              placeholder: "",
                                              validate: []
                                            }
                                          }
                                        }
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                component: "@ambisafe/fe-statements/{IfTrue}",
                                props: {
                                  if: "${submitErrors}"
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{Spacing}",
                                  props: {
                                    px: "xs",
                                    pb: "xxs"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-form/{SubmitError}"
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  px: "xs",
                                  mt: "sm"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    children: [
                                      {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        children: {
                                          component: "@ambisafe/ui-button",
                                          props: {
                                            type: "submit"
                                          },
                                          children: {
                                            component: "@ambisafe/ui-layout/{Spacing}",
                                            props: {
                                              py: "xxs",
                                              px: "sm"
                                            },
                                            children: {
                                              component: "@ambisafe/ui-text/{Paragraph}",
                                              props: {
                                                noBottomGutter: true,
                                                textAlign: "center",
                                                textTransform: "uppercase"
                                              },
                                              children: {
                                                component: "react-intl/{FormattedMessage}",
                                                props: {
                                                  id: "save"
                                                }
                                              }
                                            }
                                          }
                                        }
                                      },
                                      {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        children: {
                                          component: "@ambisafe/ui-button",
                                          props: {
                                            onClick: "${handleDeleteRequest}",
                                            inverse: true,
                                            colors: {
                                              default: {
                                                color: "text.contrast",
                                                borderColor: "error.main",
                                                backgroundColor: "error.main"
                                              },
                                              disabled: {
                                                color: "text.contrast",
                                                borderColor: "grey300",
                                                backgroundColor: "grey300"
                                              },
                                              hovered: {
                                                color: "text.contrast",
                                                borderColor: "error.dark",
                                                backgroundColor: "error.dark"
                                              },
                                              focused: {
                                                color: "text.contrast",
                                                borderColor: "error.dark",
                                                backgroundColor: "error.dark"
                                              }
                                            }
                                          },
                                          children: {
                                            component: "@ambisafe/ui-layout/{Spacing}",
                                            props: {
                                              py: "xxs",
                                              px: "sm"
                                            },
                                            children: {
                                              component: "@ambisafe/ui-text/{Paragraph}",
                                              props: {
                                                noBottomGutter: true,
                                                textAlign: "center",
                                                textTransform: "uppercase"
                                              },
                                              children: {
                                                component: "react-intl/{FormattedMessage}",
                                                props: {
                                                  id: "delete"
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    ]
                                  },
                                  {
                                    component: "@ambisafe/fe-statements/{IfTrue}",
                                    props: {
                                      if: "${deleteRequest}"
                                    },
                                    children: {
                                      component: "@ambisafe/ui-layout/{Spacing}",
                                      props: {
                                        mt: "xs"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridRow}",
                                        props: {
                                          alignItems: "center"
                                        },
                                        children: [
                                          {
                                            component: "@ambisafe/ui-layout/{GridCol}",
                                            children: {
                                              component: "@ambisafe/ui-text/{Paragraph}",
                                              props: {
                                                noBottomGutter: true
                                              },
                                              children: {
                                                component: "@ambisafe/locale/{FormattedMessage}",
                                                props: {
                                                  id: "are_you_sure_you_want_to_delete_this_bank_info"
                                                }
                                              }
                                            }
                                          },
                                          {
                                            component: "@ambisafe/ui-layout/{GridCol}",
                                            children: {
                                              component: "@ambisafe/ui-button",
                                              props: {
                                                onClick: "${confirmDeleteRequest}",
                                                inverse: true,
                                                colors: {
                                                  default: {
                                                    color: "text.contrast",
                                                    borderColor: "error.main",
                                                    backgroundColor: "error.main"
                                                  },
                                                  disabled: {
                                                    color: "text.contrast",
                                                    borderColor: "grey300",
                                                    backgroundColor: "grey300"
                                                  },
                                                  hovered: {
                                                    color: "text.contrast",
                                                    borderColor: "error.dark",
                                                    backgroundColor: "error.dark"
                                                  },
                                                  focused: {
                                                    color: "text.contrast",
                                                    borderColor: "error.dark",
                                                    backgroundColor: "error.dark"
                                                  }
                                                }
                                              },
                                              children: {
                                                component: "@ambisafe/ui-layout/{Spacing}",
                                                props: {
                                                  py: "xxs",
                                                  px: "sm"
                                                },
                                                children: {
                                                  component: "@ambisafe/ui-text/{Paragraph}",
                                                  props: {
                                                    noBottomGutter: true,
                                                    textAlign: "center",
                                                    textTransform: "uppercase"
                                                  },
                                                  children: {
                                                    component: "react-intl/{FormattedMessage}",
                                                    props: {
                                                      id: "yes"
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          },
                                          {
                                            component: "@ambisafe/ui-layout/{GridCol}",
                                            children: {
                                              component: "@ambisafe/ui-button",
                                              props: {
                                                inverse: true,
                                                onClick: "${rejectDeleteRequest}"
                                              },
                                              children: {
                                                component: "@ambisafe/ui-layout/{Spacing}",
                                                props: {
                                                  py: "xxs",
                                                  px: "sm"
                                                },
                                                children: {
                                                  component: "@ambisafe/ui-text/{Paragraph}",
                                                  props: {
                                                    noBottomGutter: true,
                                                    textAlign: "center",
                                                    textTransform: "uppercase"
                                                  },
                                                  children: {
                                                    component: "react-intl/{FormattedMessage}",
                                                    props: {
                                                      id: "no"
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        ]
                                      }
                                    }
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                }
              }
            }
          },
          {
            component: "@ambisafe/fe-statements/{IfTrue}",
            props: {
              if: "${newBankInfo}"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.15)"
              },
              children: {
                component: "@ambisafe/ui-background",
                props: {
                  backgroundColor: "elements.cardBackground"
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    p: "sm"
                  },
                  children: {
                    component: "@ambisafe/ui-form/{Form}",
                    props: {
                      onSubmit: "${handleUserBankInfoAdd}"
                    },
                    children: [
                      {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          px: "xs"
                        },
                        children: {
                          component: "@ambisafe/ui-layout/{GridRow}",
                          children: [
                            {
                              component: "@ambisafe/ui-layout/{GridCol}",
                              props: {
                                span: 12
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-form/{Field}",
                                  props: {
                                    name: "title",
                                    type: "text",
                                    label: "form.title",
                                    placeholder: "",
                                    validate: ["required"]
                                  }
                                }
                              }
                            },
                            {
                              component: "@ambisafe/ui-layout/{GridCol}",
                              props: {
                                span: 12
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-form/{Field}",
                                  props: {
                                    name: "default",
                                    type: "checkbox",
                                    label: "form.default",
                                    validate: []
                                  }
                                }
                              }
                            }
                          ]
                        }
                      },
                      {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          px: "xs"
                        },
                        children: [
                          {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              mb: "sm"
                            },
                            children: {
                              component: "@ambisafe/ui-text/{Heading5}",
                              props: {
                                noBottomGutter: true
                              },
                              children: {
                                component: "@ambisafe/locale/{FormattedMessage}",
                                props: {
                                  id: "beneficiary_bank_details"
                                }
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridRow}",
                            children: {
                              component: "@ambisafe/ui-layout/{GridCol}",
                              props: {
                                span: 12
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-form/{Field}",
                                  props: {
                                    name: "beneficiaryBankName",
                                    type: "text",
                                    label: "form.bankName",
                                    placeholder: "",
                                    validate: ["required"]
                                  }
                                }
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridRow}",
                            children: {
                              component: "@ambisafe/ui-layout/{GridCol}",
                              props: {
                                span: 12
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-form/{Field}",
                                  props: {
                                    name: "beneficiaryBankAddress",
                                    type: "text",
                                    label: "form.bankAddress",
                                    placeholder: "",
                                    validate: ["required"]
                                  }
                                }
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridRow}",
                            children: [
                              {
                                component: "@ambisafe/ui-layout/{GridCol}",
                                props: {
                                  span: 6
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{Spacing}",
                                  props: {
                                    mb: "xs"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-form/{Field}",
                                    props: {
                                      name: "beneficiaryCountry",
                                      type: "select",
                                      options: "${countries}",
                                      label: "form.country",
                                      placeholder: "",
                                      validate: ["required"]
                                    }
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{GridCol}",
                                props: {
                                  span: 6
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{Spacing}",
                                  props: {
                                    mb: "xs"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-form/{Field}",
                                    props: {
                                      name: "beneficiarySwiftBicCode",
                                      type: "text",
                                      label: "form.swiftBicCode",
                                      placeholder: "",
                                      validate: ["required"]
                                    }
                                  }
                                }
                              }
                            ]
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridRow}",
                            children: {
                              component: "@ambisafe/ui-layout/{GridCol}",
                              props: {
                                span: 12
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-form/{Field}",
                                  props: {
                                    name: "beneficiaryIBAN",
                                    type: "text",
                                    label: "form.IBAN",
                                    placeholder: "",
                                    validate: ["required"]
                                  }
                                }
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridRow}",
                            children: [
                              {
                                component: "@ambisafe/ui-layout/{GridCol}",
                                props: {
                                  span: 6
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{Spacing}",
                                  props: {
                                    mb: "xs"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-form/{Field}",
                                    props: {
                                      name: "beneficiaryClearingCode",
                                      type: "text",
                                      label: "form.clearingCode",
                                      placeholder: "",
                                      validate: []
                                    }
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{GridCol}",
                                props: {
                                  span: 6
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{Spacing}",
                                  props: {
                                    mb: "xs"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-form/{Field}",
                                    props: {
                                      name: "beneficiaryClearingCodeDescription",
                                      type: "text",
                                      label: "form.clearingCodeDescription",
                                      placeholder: "",
                                      validate: []
                                    }
                                  }
                                }
                              }
                            ]
                          }
                        ]
                      },
                      {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          pt: "xxs",
                          pb: "xs"
                        },
                        children: {
                          component: "@ambisafe/ui-divider",
                          props: {
                            opacity: 0.3
                          }
                        }
                      },
                      {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          px: "xs"
                        },
                        children: [
                          {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              mb: "sm"
                            },
                            children: {
                              component: "@ambisafe/ui-text/{Heading5}",
                              props: {
                                noBottomGutter: true
                              },
                              children: {
                                component: "@ambisafe/locale/{FormattedMessage}",
                                props: {
                                  id: "intermediary_bank_details"
                                }
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridRow}",
                            children: {
                              component: "@ambisafe/ui-layout/{GridCol}",
                              props: {
                                span: 12
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-form/{Field}",
                                  props: {
                                    name: "intermediaryBankName",
                                    type: "text",
                                    label: "form.bankName",
                                    placeholder: "",
                                    validate: []
                                  }
                                }
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridRow}",
                            children: {
                              component: "@ambisafe/ui-layout/{GridCol}",
                              props: {
                                span: 12
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-form/{Field}",
                                  props: {
                                    name: "intermediaryBankAddress",
                                    type: "text",
                                    label: "form.bankAddress",
                                    placeholder: "",
                                    validate: []
                                  }
                                }
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridRow}",
                            children: [
                              {
                                component: "@ambisafe/ui-layout/{GridCol}",
                                props: {
                                  span: 6
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{Spacing}",
                                  props: {
                                    mb: "xs"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-form/{Field}",
                                    props: {
                                      name: "intermediaryCountry",
                                      type: "select",
                                      options: "${countries}",
                                      label: "form.country",
                                      placeholder: "",
                                      validate: []
                                    }
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{GridCol}",
                                props: {
                                  span: 6
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{Spacing}",
                                  props: {
                                    mb: "xs"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-form/{Field}",
                                    props: {
                                      name: "intermediarySwiftBicCode",
                                      type: "text",
                                      label: "form.swiftBicCode",
                                      placeholder: "",
                                      validate: []
                                    }
                                  }
                                }
                              }
                            ]
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridRow}",
                            children: [
                              {
                                component: "@ambisafe/ui-layout/{GridCol}",
                                props: {
                                  span: 6
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{Spacing}",
                                  props: {
                                    mb: "xs"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-form/{Field}",
                                    props: {
                                      name: "intermediaryClearingCode",
                                      type: "text",
                                      label: "form.clearingCode",
                                      placeholder: "",
                                      validate: []
                                    }
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{GridCol}",
                                props: {
                                  span: 6
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{Spacing}",
                                  props: {
                                    mb: "xs"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-form/{Field}",
                                    props: {
                                      name: "intermediaryClearingCodeDescription",
                                      type: "text",
                                      label: "form.clearingCodeDescription",
                                      placeholder: "",
                                      validate: []
                                    }
                                  }
                                }
                              }
                            ]
                          }
                        ]
                      },
                      {
                        component: "@ambisafe/fe-statements/{IfTrue}",
                        props: {
                          if: "${submitErrors}"
                        },
                        children: {
                          component: "@ambisafe/ui-layout/{Spacing}",
                          props: {
                            px: "xs",
                            pb: "xxs"
                          },
                          children: {
                            component: "@ambisafe/ui-form/{SubmitError}"
                          }
                        }
                      },
                      {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          px: "xs",
                          mt: "sm"
                        },
                        children: [
                          {
                            component: "@ambisafe/ui-layout/{GridRow}",
                            children: [
                              {
                                component: "@ambisafe/ui-layout/{GridCol}",
                                children: {
                                  component: "@ambisafe/ui-button",
                                  props: {
                                    type: "submit"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-layout/{Spacing}",
                                    props: {
                                      py: "xxs",
                                      px: "sm"
                                    },
                                    children: {
                                      component: "@ambisafe/ui-text/{Paragraph}",
                                      props: {
                                        noBottomGutter: true,
                                        textAlign: "center",
                                        textTransform: "uppercase"
                                      },
                                      children: {
                                        component: "react-intl/{FormattedMessage}",
                                        props: {
                                          id: "save"
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            ]
                          },
                          {
                            component: "@ambisafe/fe-statements/{IfTrue}",
                            props: {
                              if: "${deleteRequest}"
                            },
                            children: {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                mt: "xs"
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  alignItems: "center"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-text/{Paragraph}",
                                      props: {
                                        noBottomGutter: true
                                      },
                                      children: {
                                        component: "@ambisafe/locale/{FormattedMessage}",
                                        props: {
                                          id: "are_you_sure_you_want_to_delete_this_bank_info"
                                        }
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-button",
                                      props: {
                                        onClick: "${confirmDeleteRequest}",
                                        inverse: true,
                                        colors: {
                                          default: {
                                            color: "text.contrast",
                                            borderColor: "error.main",
                                            backgroundColor: "error.main"
                                          },
                                          disabled: {
                                            color: "text.contrast",
                                            borderColor: "grey300",
                                            backgroundColor: "grey300"
                                          },
                                          hovered: {
                                            color: "text.contrast",
                                            borderColor: "error.dark",
                                            backgroundColor: "error.dark"
                                          },
                                          focused: {
                                            color: "text.contrast",
                                            borderColor: "error.dark",
                                            backgroundColor: "error.dark"
                                          }
                                        }
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{Spacing}",
                                        props: {
                                          py: "xxs",
                                          px: "sm"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-text/{Paragraph}",
                                          props: {
                                            noBottomGutter: true,
                                            textAlign: "center",
                                            textTransform: "uppercase"
                                          },
                                          children: {
                                            component: "react-intl/{FormattedMessage}",
                                            props: {
                                              id: "yes"
                                            }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-button",
                                      props: {
                                        inverse: true,
                                        onClick: "${rejectDeleteRequest}"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{Spacing}",
                                        props: {
                                          py: "xxs",
                                          px: "sm"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-text/{Paragraph}",
                                          props: {
                                            noBottomGutter: true,
                                            textAlign: "center",
                                            textTransform: "uppercase"
                                          },
                                          children: {
                                            component: "react-intl/{FormattedMessage}",
                                            props: {
                                              id: "no"
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            }
                          }
                        ]
                      }
                    ]
                  }
                }
              }
            }
          },
          {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              mt: "md"
            },
            children: {
              component: "@ambisafe/react-router-dom/{Link}",
              props: {
                to: "${match.url}?newBankInfo=true"
              },
              children: {
                component: "@ambisafe/ui-button",
                props: {
                  onClick: "${addNewBank}"
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    py: "xxs",
                    px: "xs"
                  },
                  children: {
                    component: "@ambisafe/ui-text/{Heading4}",
                    props: {
                      noBottomGutter: true
                    },
                    children: {
                      component: "@ambisafe/ui-text/{Normal}",
                      children: {
                        component: "@ambisafe/locale/{FormattedMessage}",
                        props: {
                          id: "add_new_bank"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        ]
      }
    ]
  }
};
