/* eslint-disable no-template-curly-in-string */
import { BalanceInfo } from "./BalanceInfo";
import { TradeDialog } from "./TradeDialog";

export const SecondaryMarket = {
  component: "@ambisafe/react-router-dom/{RedirectFalsyStatement}",
  props: {
    statement: "${user}",
    to: "/projects/${project.link}",
    projectPath: "/projects/${project.link}"
  },
  children: {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.path}/:operation"
    },
    children: {
      component: "@orderbook/fe-secondary-market",
      props: {
        projectLink: "${project.link}",
        user: "${user}",
        kycRequired: "${investBank.kycRequired}"
      },
      children: {
        component: "@ambisafe/ui-layout/{GridContainer}",
        children: [
          {
            component: "@ambisafe/ui-text/{Paragraph}",
            props: {
              textTransform: "uppercase",
              fontSize: "lg",
              textAlign: "center"
            },
            children: {
              component: "@ambisafe/ui-text/{Normal}",
              children: [
                {
                  component: "@ambisafe/react-router-dom/{Route}",
                  props: {
                    path: "${match.path}",
                    exact: true
                  },
                  children: [
                    {
                      component: "@ambisafe/locale/{FormattedMessage}",
                      props: {
                        id: "${match.params.operation}"
                      }
                    },
                    " ",
                    "${SM_projectAssetDisplaySymbol}"
                  ]
                },
                {
                  component: "@ambisafe/react-router-dom/{Route}",
                  props: {
                    path: "${match.path}/confirmation"
                  },
                  children: [
                    {
                      component: "@ambisafe/locale/{FormattedMessage}",
                      props: {
                        id: "${match.params.operation}"
                      }
                    },
                    " ",
                    "${SM_projectAssetDisplaySymbol}"
                  ]
                },
                {
                  component: "@ambisafe/react-router-dom/{Route}",
                  props: {
                    path: "${match.path}/congratulations"
                  },
                  children: [
                    {
                      component: "@ambisafe/locale/{FormattedMessage}",
                      props: {
                        id: "order_completed"
                      }
                    }
                  ]
                }
              ]
            }
          },
          BalanceInfo,
          {
            component: "@ambisafe/ui-divider",
          },
          TradeDialog
        ]
      }
    }
  }
};
