/* eslint-disable no-template-curly-in-string */
export const BackButton = {
  component: "@ambisafe/react-router-dom/{Link}",
  props: {
    to: "/projects/${project.link}/contribute/method"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pb: "xs"
    },
    children: {
      component: "@ambisafe/ui-button",
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          py: "xxs",
          px: "xs"
        },
        children: {
          component: "@ambisafe/ui-text/{Paragraph}",
          props: {
            noBottomGutter: true,
            textAlign: "center",
            textTransform: "uppercase"
          },
          children: {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "back"
            }
          }
        }
      }
    }
  }
};
