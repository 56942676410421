/* eslint-disable no-template-curly-in-string */
export const Fee = {
  component: "@ambisafe/ui-text/{Paragraph}",
  props: {
    noBottomGutter: true
  },
  children: [
    {
      component: "react-intl/{FormattedMessage}",
      props: {
        id: "including_fee"
      }
    },
    {
      component: "span",
      children: ": "
    },
    {
      component: "span",
      children: "${totalDepositFee}"
    },
    {
      component: "span",
      children: " "
    },
    {
      component: "span",
      children: "${primaryCounterAsset.displaySymbol}"
    }
  ]
};
