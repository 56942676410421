/* eslint-disable no-template-curly-in-string */
export const PhoneConfirmationContainer = (form) => ({
  component: "@ambisafe/ui-layout/{GridContainer}",
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    props: {
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      minHeight: "calc(100vh - 54px)"
    },
    children: {
      component: "@ambisafe/ui-layout/{GridCol}",
      props: {
        span: {
          mobile: 12,
          tablet: 6,
          tabletLandscape: 4
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          borderRadius: "30px",
          overflow: "hidden"
        },
        children: {
          component: "@ambisafe/ui-background",
          props: {
            backgroundColor: "white100"
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: {
                mobile: "md",
                tablet: "xl"
              }
            },
            children: [
              {
                component: "@ambisafe/ui-text/{Heading2}",
                props: {
                  color: "primary.main",
                  lineHeight: 1
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "confirm_your_phone"
                  }
                }
              },
              {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  pb: "xs"
                },
                children: {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  props: {},
                  children: {
                    component: "react-intl/{FormattedMessage}",
                    props: {
                      id: "phone_confirmation_description"
                    }
                  }
                }
              },
              form
            ]
          }
        }
      }
    }
  }
});
