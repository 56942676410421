/* eslint-disable no-template-curly-in-string */
export const DocumentList = {
  component: "@ambisafe/ui-list",
  props: {
    variable: "${verificationDocuments}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "xs"
    },
    children: [
      {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "success.main"
        },
        children: {
          component: "@ambisafe/ui-background",
          props: {
            backgroundColor: "success.light",
            width: "100%,"
          },
          children: {
            component: "@ambisafe/ui-layout/{Container}",
            props: {
              display: "flex",
              justifyContent: "space-between"
            },
            children: [
              {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  px: "xs",
                  py: "xxs"
                },
                children: {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  props: {
                    display: "flex",
                    justifyContent: "space-between"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-layout/{Spacing}",
                      props: {
                        px: "xs"
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Paragraph}",
                        props: {
                          noBottomGutter: true,
                          color: "success.main"
                        },
                        children: "${path}"
                      }
                    }
                  ]
                }
              },
              {
                component: "@ambisafe/fe-statements/{IfFalse}",
                props: {
                  if: "${hideRemoveLabel}"
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    mr: "sm",
                    mt: "xxxs"
                  },
                  children: {
                    component: "@ambisafe/ui-button",
                    props: {
                      type: "button",
                      onClick: "${removeDocument}, ${index}",
                      colors: {
                        default: {
                          color: "text.contrast",
                          borderColor: "transparent",
                          backgroundColor: "transparent"
                        },
                        disabled: {
                          color: "text.contrast",
                          borderColor: "transparent",
                          backgroundColor: "transparent"
                        },
                        hovered: {
                          color: "text.contrast",
                          borderColor: "transparent",
                          backgroundColor: "transparent"
                        },
                        focused: {
                          color: "text.contrast",
                          borderColor: "transparent",
                          backgroundColor: "transparent"
                        }
                      }
                    },
                    children: {
                      component: "@ambisafe/ui-icons/{Icon}",
                      props: {
                        color: "black600",
                        icon: "close",
                        height: "15px",
                        width: "15px"
                      }
                    }
                  }
                }
              }
            ]
          }
        }
      }
    ]
  }
};
