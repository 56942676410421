/* eslint-disable no-template-curly-in-string */
export const NoActionsMessage = {
  component: "@ambisafe/fe-statements/{IfFalse}",
  props: {
    if: "${actionsHistory.actions.length}"
  },
  children: {
    component: "@ambisafe/ui-text/{Paragraph}",
    children: {
      component: "@ambisafe/locale/{FormattedMessage}",
      props: {
        id: "no_actions_in_action_history"
      }
    }
  }
};
