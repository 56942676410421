/* eslint-disable no-template-curly-in-string */
export const SignDocuments = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${project.areThereDocumentsToSign}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mt: "xs"
    },
    children: {  
      component: "@ambisafe/react-router-dom/{Link}",
      props: {
        to: "${match.url}/documents",
        onClick: "${assignInvoiceContract}"
      },
      children: {
        component: "@ambisafe/ui-button",
        props: {
          width: "100%"
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            p: "xxs"
          },
          children: {
            component: "@ambisafe/ui-text/{Heading4}",
            props: {
              noBottomGutter: true,
              textAlign: "center"
            },
            children: {
              component: "@ambisafe/ui-text/{Normal}",
              children: {
                component: "@ambisafe/locale/{FormattedMessage}",
                props: {
                  id: "contribute"
                }
              }
            }
          }
        }
      }
    }
  }
};
