/* eslint-disable no-template-curly-in-string */
export const AmountToPay = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pb: "xxs"
  },
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    props: {
      justifyContent: "spaceetween"
    },
    children: [
      {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: 3
          },
          children: {
            component: "@ambisafe/ui-text/{Paragraph}",
            props: {
              noBottomGutter: true
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                mt: "10px"
              },
              children: {
              component: "react-intl/{FormattedMessage}",
              props: {
                id: "you_pay"
              }
            }
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          width: "230px"
        },
        children: {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "counterAmount",
              type: "number",
              placeholder: "0",
              min: 0,
              step: "${DC_steps.counter}",
              validate: "${DC_validationRules.counterAmount}",
              disabled: "${DC_enoughFunds}"
            }
          
        }
      },
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            mt: "10px"
          },
          children: {
            component: "@ambisafe/ui-text/{Paragraph}",
            props: {
              noBottomGutter: true
            },
            children: "${displaySymbol}"
          }
        }
      }
    ]
  }
};
