/* eslint-disable no-template-curly-in-string */
import { UserFeeDialog } from "../UserFeeDialog";

export const PaidActionDialog = {
  component: "@orderbook/fe-paid-action",
  props: {
    payableActionData: "${payableActionData}",
    onConfirm: "${payablePromise.resolve}",
    onCancel: "${payablePromise.reject}"
  },
  children: {
    component: "@ambisafe/fe-statements/{IfEqualTo}",
    props: {
      if: "${payableActionData.__typename}",
      to: "PayableActionResponse"
    },
    children: UserFeeDialog()
  }
};
