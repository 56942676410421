/* eslint-disable no-template-curly-in-string */
import { PrimaryMarket } from "./PrimaryMarket";
import { SecondaryMarket } from "./SecondaryMarket";

export const Links = {
  component: "@ambisafe/fe-statements-to-props/{IfEqualToSome}",
  props: {
    label: "shouldDisableInvest",
    if: "${project.status}",
    to: ["INITIAL_SALE_ENDED", "EXITED"]
  },
  children: {
    component: "@ambisafe/fe-statements-to-props/{IfEqualTo}",
    props: {
      label: "shouldDisableInvest",
      if: "${project.depositAddress}",
      to: undefined
    },
    children: [
      PrimaryMarket,
      SecondaryMarket
    ]
  }
}

