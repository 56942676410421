/* eslint-disable no-template-curly-in-string */
export const PrimaryMarketLink = {
  component: "@ambisafe/ui-button",
  props: {
    onClick: "${handleInvestClick}",
    disabled: "${shouldDisableInvest}",
    width: "100%"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      p: "xxs"
    },
    children: {
      component: "@ambisafe/ui-text/{Heading4}",
      props: {
        noBottomGutter: true,
        textAlign: "center"
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: {
          component: "@ambisafe/locale/{FormattedMessage}",
          props: {
            id: "contribute"
          }
        }
      }
    }
  }
};
