/* eslint-disable no-template-curly-in-string */
export const Processing = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mt: "sm"
  },
  children: {
    component: "@ambisafe/fe-statements/{IfTrue}",
    props: {
      if: "${processingPurchase}"
    },
    children: [
      {
        component: "@orderbook/fe-asset-invoice-status",
        props: {
          assetDisplaySymbol: "${displaySymbol}",
          project: "${project}"
        },
        children: [
          {
            component: "@ambisafe/ui-stepper",
            props: {
              vertical: true,
              activeStep: "${activeStep}",
              steps: "${stepsLabels}"
            }
          }
        ]
      },
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          mt: "xs"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              children: {
                component: "@ambisafe/react-router-dom/{Link}",
                props: {
                  to: "${location.pathname}"
                },
                children: {
                  component: "@ambisafe/ui-button",
                  props: {
                    inverse: true,
                    width: "100%"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      py: "xxs",
                      px: "xs"
                    },
                    children: {
                      component: "@ambisafe/ui-text/{Paragraph}",
                      props: {
                        noBottomGutter: true,
                        textAlign: "center",
                        textTransform: "uppercase"
                      },
                      children: {
                        component: "react-intl/{FormattedMessage}",
                        props: {
                          id: "back"
                        }
                      }
                    }
                  }
                }
              }
            }
          ]
        }
      }
    ]
  }
};
