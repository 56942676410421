/* eslint-disable no-template-curly-in-string */
import { HumanHolder } from "./HumanHolder";
import { CompanyHolder } from "./CompanyHolder";
import { CorporateAddressFields, Divider, Field, FormErrors } from "../FieldSets";
import { DocumentList } from "../DocumentList";

export const CorporateKYC = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "/account/verification/corporate",
    exact: true
  },
  children: {
    component: "@ambisafe/react-router-dom/{RedirectStatement}",
    props: {
      statement: "${isPersonalKYC}",
      to: "/account/verification"
    },
    children: {
      component: "@ambisafe/ui-form/{Form}",
      props: {
        onSubmit: "${updateCorporateIdentitySubmit}",
        initialValues: "${corporateIdentityInitialValues}"
      },
      children: [
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            mb: "md"
          },
          children: [
            {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                inline: true,
                noBottomGutter: true,
                textTransform: "uppercase"
              },
              children: [
                {
                  component: "@ambisafe/locale/{FormattedMessage}",
                  props: {
                    id: "kyc.status"
                  }
                },
                {
                  component: "span",
                  children: ":"
                }
              ]
            },
            {
              component: "span",
              children: " "
            },
            {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                inline: true,
                noBottomGutter: true,
                textTransform: "uppercase",
                color: "${kycStatusColor}"
              },
              children: {
                component: "@ambisafe/locale/{FormattedMessage}",
                props: {
                  id: "kyc.${kycStatus}"
                }
              }
            }
          ]
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          children: {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [
              Field({
                component: "@ambisafe/ui-form/{Field}",
                props: {
                  name: "companyName",
                  type: "text",
                  label: "form.company_name",
                  placeholder: "",
                  validate: ["required", "requiredLatinOnly", ["minLength", 2], ["maxLength", 250]]
                }
              }),
              Field([
                {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  props: {
                    fontSize: "sm"
                  },
                  children: {
                    component: "@ambisafe/locale/{FormattedMessage}",
                    props: {
                      id: "form.company_incorporated"
                    }
                  }
                },
                {
                  component: "@ambisafe/ui-form/{Field}",
                  props: {
                    name: "companyIncorporated",
                    index: "${index}",
                    type: "checkbox",
                    label: "",
                    placeholder: ""
                  }
                }
              ])
            ]
          }
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          children: {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [
              Field({
                component: "@ambisafe/ui-form/{Field}",
                props: {
                  name: "entityType",
                  type: "select",
                  label: "form.entity_type",
                  placeholder: "",
                  validate: ["required"],
                  options: "${entityTypesList}"
                }
              }),
              Field({
                component: "@ambisafe/ui-form/{Field}",
                props: {
                  name: "ownershipStructureLayers",
                  type: "select",
                  label: "form.ownership_structure_layers",
                  placeholder: "",
                  validate: ["required"],
                  options: "${ownershipStructureLayersList}"
                }
              }),
              Field(
                {
                  component: "@ambisafe/ui-form/{ConditionField}",
                  props: {
                    condition: {
                      when: "entityType",
                      is: "UNKNOWN"
                    },
                    name: "entityTypeDescription",
                    type: "text",
                    label: "form.entity_type_description",
                    placeholder: "",
                    validate: ["required"]
                  }
                },
                12
              )
            ]
          }
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          children: {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [
              Field({
                component: "@ambisafe/ui-form/{Field}",
                props: {
                  name: "countryOfIncorporation",
                  type: "select",
                  label: "form.country_of_incorporation",
                  placeholder: "",
                  validate: ["required"],
                  options: "${countries}"
                }
              }),
              Field({
                component: "@ambisafe/ui-form/{Field}",
                props: {
                  name: "dateOfIncorporation",
                  type: "date",
                  label: "form.date_of_incorporation",
                  validate: ["required"],
                  placeholder: ""
                }
              })
            ]
          }
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          children: {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [
              Field({
                component: "@ambisafe/ui-form/{Field}",
                props: {
                  name: "countryOfOperations",
                  type: "select",
                  label: "form.country_of_operations",
                  placeholder: "",
                  validate: ["required"],
                  options: "${countries}"
                }
              }),
              Field({
                component: "@ambisafe/ui-form/{Field}",
                props: {
                  name: "businessActivity",
                  type: "select",
                  label: "form.business_activity",
                  placeholder: "",
                  validate: ["required"],
                  options: "${businessActivitiesList}"
                }
              })
            ]
          }
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          children: {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [
              Field({
                component: "@ambisafe/ui-form/{Field}",
                props: {
                  name: "companyIdentificationNumber",
                  type: "text",
                  label: "form.company_identification_number",
                  placeholder: "",
                  validate: ["required", ["minLength", 2]]
                }
              }),
              Field({
                component: "@ambisafe/ui-form/{Field}",
                props: {
                  name: "taxId",
                  type: "text",
                  label: "form.tax_id",
                  // mask: "999-99-9999",
                  // maskChar: "#",
                  // placeholder: "###-##-####",
                  placeholder: "",
                  validate: [
                    ["requiredIfValuesEqualTo", "US", "countryOfResidence", "form.tax_id_required_for_us_user"],
                    ["minLength", 2],
                    ["maxLength", 18]
                  ]
                }
              })
            ]
          }
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pt: "xs"
          },
          children: {
            component: "@ambisafe/ui-text/{Heading4}",
            props: {
              noBottomGutter: true
            },
            children: {
              component: "@ambisafe/locale/{FormattedMessage}",
              props: {
                id: "form.address"
              }
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pt: "xxs",
            pb: "xs"
          },
          children: {
            component: "@ambisafe/ui-divider",
            props: {
              noGutter: true,
              opacity: 0.3
            }
          }
        },
        CorporateAddressFields,
        {
          component: "@ambisafe/fe-statements/{IfEqualToSome}",
          props: {
            if: "${kycStatus}",
            to: ["EMPTY", "FAILED"]
          },
          children: [
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                pt: "xs"
              },
              children: {
                component: "@ambisafe/ui-text/{Heading4}",
                props: {
                  noBottomGutter: true
                },
                children: {
                  component: "@ambisafe/locale/{FormattedMessage}",
                  props: {
                    id: "form.identification_documents"
                  }
                }
              }
            },
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                pt: "xxs",
                pb: "xs"
              },
              children: {
                component: "@ambisafe/ui-divider",
                props: {
                  noGutter: true,
                  opacity: 0.3
                }
              }
            },
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              children: {
                component: "@ambisafe/ui-layout/{GridRow}",
                children: [
                  Field({
                    component: "@ambisafe/ui-form/{Field}",
                    props: {
                      name: "documentType",
                      type: "select",
                      label: "form.document_type",
                      placeholder: "",
                      validate: ["required"],
                      options: "${corporateDocumentTypesList}"
                    }
                  })
                ]
              }
            },
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                py: "xs"
              },
              children: [
                {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  children: {
                    component: "@ambisafe/ui-text/{Medium}",
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "form.corporate_documents_info"
                      }
                    }
                  }
                }
              ]
            },
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              children: {
                component: "@ambisafe/ui-layout/{GridRow}",
                children: [
                  {
                    component: "@ambisafe/ui-layout/{GridCol}",
                    props: {
                      span: {
                        mobile: 12
                      }
                    },
                    children: {
                      component: "@ambisafe/ui-layout/{Spacing}",
                      props: {
                        pb: "xs"
                      },
                      children: {
                        component: "@ambisafe/ui-form/{Field}",
                        props: {
                          name: "documents",
                          type: "file",
                          maxSize: 52428800,
                          accept: ".png, .jpg, .jpeg, .pdf, .doc, .docx",
                          placeholder: "",
                          validate: ["required"]
                        }
                      }
                    }
                  }
                ]
              }
            },
          ]
        },
        Divider,
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pb: "xxs"
          },
          children: {
            component: "@ambisafe/ui-button",
            type: "button",
            props: {
              onClick: "${formDispatch}, ${addCompanyHolder}",
              type: "button",
              width: {
                mobile: "100%",
                tablet: "270px"
              }
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                py: "xxs",
                px: "xs"
              },
              children: {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  noBottomGutter: true,
                  textAlign: "center",
                  textTransform: "uppercase"
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "ADD_COMPANY_HOLDER"
                  }
                }
              }
            }
          }
        },
        {
          component: "@ambisafe/ui-list",
          props: {
            variable: "${companyHoldersForms}"
          },
          children: CompanyHolder
        },
        Divider,
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pb: "xxs"
          },
          children: {
            component: "@ambisafe/ui-button",
            props: {
              type: "button",
              onClick: "${formDispatch}, ${addIndividualHolder}",
              width: {
                mobile: "100%",
                tablet: "270px"
              }
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                py: "xxs",
                px: "xxs"
              },
              children: {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  noBottomGutter: true,
                  textAlign: "center",
                  textTransform: "uppercase"
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "ADD_INDIVIDUAL_HOLDER"
                  }
                }
              }
            }
          }
        },
        {
          component: "@ambisafe/ui-list",
          props: {
            variable: "${individualHoldersForms}"
          },
          children: HumanHolder
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            mt: "xxs",
            mb: "sm"
          },
          children: [
            {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                inline: true,
                noBottomGutter: true,
                textTransform: "uppercase"
              },
              children: [
                {
                  component: "@ambisafe/locale/{FormattedMessage}",
                  props: {
                    id: "kyc.status"
                  }
                },
                {
                  component: "span",
                  children: ":"
                }
              ]
            },
            {
              component: "span",
              children: " "
            },
            {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                inline: true,
                noBottomGutter: true,
                textTransform: "uppercase",
                color: "${kycStatusColor}"
              },
              children: {
                component: "@ambisafe/locale/{FormattedMessage}",
                props: {
                  id: "kyc.${kycStatus}"
                }
              }
            }
          ]
        },
        FormErrors,
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pb: "xxs"
          },
          children: {
            component: "@ambisafe/ui-button",
            props: {
              type: "submit",
              loading: "${loading}",
              width: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                py: "xxs",
                px: "xs"
              },
              children: {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  noBottomGutter: true,
                  textAlign: "center",
                  textTransform: "uppercase"
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "save"
                  }
                }
              }
            }
          }
        }
      ]
    }
  }
};
