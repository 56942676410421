/* eslint-disable no-template-curly-in-string */

export const HardCap = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "50%",
    alignItems: "left"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true,
        opacity: 0.6
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: "Offering Max"
      }
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true
      },
      children: {
        component: "@ambisafe/ui-text/{Light}",
        children: [
          {
            component: "span",
            children: "$"
          },
          {
            component: "react-intl/{FormattedNumber}",
            props: {
              value: "${hardCap}"
            }
          }
        ]
      }
    }
  ]
};
