/* eslint-disable no-template-curly-in-string */
export const ProjectTeam = {
  component: "@ambisafe/ui-layout/{Spacing}",
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
            noBottomGutter: true,
            textTransform: "capitalize",
            opacity: 0.6
          },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: "Executive Management Team"
      }
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        pb: "sm"
      }
    },
    {
      component: "@ambisafe/ui-list",
      props: {
        variable: "${project.teamMembers}"
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          pb: "sm"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              children: {
                component: "@ambisafe/ui-background",
                props: {
                  width: "160px",
                  height: "160px",
                  round: true,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundImage: "url('${image}')"
                }
              }
            },
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                flex: 1
              },
              children: [
                {
                  component: "@ambisafe/ui-text/{Heading4}",
                  props: {
                    noBottomGutter: true
                  },
                  children: {
                    component: "@ambisafe/ui-text/{Normal}",
                    children: "${fullName}"
                  }
                },
                {
                  component: "@ambisafe/ui-text/{Heading5}",
                  children: {
                    component: "@ambisafe/ui-text/{Normal}",
                    children: "${title}"
                  }
                },
                {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  children: "${description}"
                }
              ]
            }
          ]
        }
      }
    }
  ]
};
