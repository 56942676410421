/* eslint-disable no-template-curly-in-string */

import { CoverImage } from "./CoverImage";
import { ProjectInfo } from "./ProjectInfo";

export const CurrentProject = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    span: {
      mobile: 12,
      tabletLandscape: 4
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "sm"
    },
    children: {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        borderRadius: "10px",
        overflow: "hidden",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.05)"
      },
      children: {
        component: "@ambisafe/react-router-dom/{Link}",
        props: {
          to: "/projects/${link}"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          props: {
            width: "100%",
            height: "100%",
            flexDirection: "column"
          },
          children: [
            CoverImage,
            ProjectInfo
          ]
        }
      }
    }
  }
};
