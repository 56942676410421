/* eslint-disable no-template-curly-in-string */
//import { TokensInfo } from "./TokensInfo";
import { Countdown } from "./Countdown";
import { SecondaryMarketHeader } from "../../../SecondaryMarketHeader";

export const ActiveProjectInfo = {
  component: "@ambisafe/fe-statements/{IfgtElse}",
  props: {
    if: "${project.currentDate}",
    else: "${project.startDate}"
  },
  children: [
    SecondaryMarketHeader,
    {
      component: "@ambisafe/fe-statements/{IfFalse}",
      props: {
        if: "${project.projectPair}"
      },
      children: [Countdown]
    },
  ]
};
