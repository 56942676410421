/* eslint-disable no-template-curly-in-string */
export const SecondaryMarketHeader = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${project.projectPair.isActive}"
  },
  children: {
    component: "@ambisafe/ui-text/{Heading4}",
    props: {
      opacity: 0.6,
      textAlign: "center"
    },
    children: {
      component: "@ambisafe/ui-text/{Normal}",
      children: ["TRADE ", "${project.projectAsset.displaySymbol}"]
    }
  }
};
