export const TradeComingSoon = {
  component: "@ambisafe/ui-background",
  props: {
    backgroundColor: "#2e3138"
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        width: "100%",
        margin: "-45px 0 0 0"
      },
      children: {
        component: "@ambisafe/ui-image",
        props: {
          src: "/images/trading.desktop.png",
          width: "100%",
          filter: "blur(2px)"
        }
      }
    },
    {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        position: "fixed",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%"
      },
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        props: {
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          minHeight: "calc(100vh - 54px)"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 12
            }
          },
          children: {
            component: "@ambisafe/ui-text/{Heading1}",
            props: {
              textAlign: "center",
              color: "text.contrast",
              noBottomGutter: true
            },
            children: {
              component: "@ambisafe/locale/{FormattedMessage}",
              props: {
                id: "coming_soon"
              }
            }
          }
        }
      }
    }
  ]
};
