/* eslint-disable no-template-curly-in-string */

import { AssetIcon } from "./AssetIcon";
import { AssetSymbol } from "./AssetSymbol";
import { OperationAmount } from "./OperationAmount";
import { CounterAmount } from "./CounterAmount";
import { CounterAmountInUSD } from "./CounterAmountInUSD";
import { TransactionHash } from "./TransactionHash";
import { TimeInfo } from "./TimeInfo";
import { OperationType } from "./OperationType";
import { SignedDocument, Document} from "./SignedDocument";

export const ActionHistory = {
  component: "@ambisafe/ui-list",
  props: {
    variable: "${actionsHistory.actions}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "xxxs"
    },
    children: {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.15)"
      },
      children: {
        component: "@ambisafe/ui-background",
        props: {
          backgroundColor: "elements.cardBackground"
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            p: "xs"
          },
          children: {
            component: "@ambisafe/ui-layout/{GridRow}",
            props: {
              alignItems: "center"
            },
            children: [
              AssetIcon,
              {
                component: "@ambisafe/ui-layout/{GridCol}",
                children: {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  children: [
                    {
                      component: "@ambisafe/ui-layout/{GridCol}",
                      props: {
                        minWidth: "100px"
                      },
                      children: [AssetSymbol, OperationAmount]
                    },
                    {
                      component: "@ambisafe/ui-layout/{GridCol}",
                      props: {
                        minWidth: "100px"
                      },
                      children: [CounterAmount, CounterAmountInUSD]
                    }
                  ]
                }
              },
              {
                component: "@ambisafe/ui-layout/{GridCol}",
                props: {
                  width: {
                    mobile: "100%",
                    tablet: "auto"
                  }
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    my: {
                      mobile: "md",
                      tablet: "xxxs"
                    }
                  },
                  children: [TransactionHash, SignedDocument, TimeInfo]
                }
              },
              OperationType,
              Document
            ]
          }
        }
      }
    }
  }
};
