/* eslint-disable no-template-curly-in-string */
import theme from "./theme";
//import createRootWrapper from "../shared/rootWrapper";

import { Header } from "./Header";
import { Landing } from "./Landing";
import { Footer } from "./Footer";

import { Auth } from "./Auth";
import { Account } from "./Account";
import { Recovery } from "../shared/Recovery";

import { ProjectDetails } from "./Projects/ProjectDetails";

import { MobilePanel } from "./MobilePanel";

import { Routes } from "./routes";

import { ListingRequest, RestrictedLocation, SessionExpired, TradeComingSoon } from "../shared/Pages";

export default {
  theme: theme,
  component: {
    component: "@ambisafe/ui-theme/{ThemeProvider}",
    props: {
      theme: "${theme}"
    },
    children: {
      component: "@ambisafe/locale/{IntlProvider}",
      props: {
        rootRoutes: Routes,
        messages: "${messages}",
        locale: "${locale}"
      },
      children: {
        component: "@ambisafe/react-router-dom/{BrowserRouter}",
        props: {
          basename: "/${locale}"
        },
        children: [
          {
            component: "@ambisafe/getgist",
            props: {
              appID: "hk4wn4m4"
            }
          },
          {
            component: "@ambisafe/helmet",
            props: { title: "Ohanae Platform" }
          },
          {
            component: "@orderbook/fe-user-session-expiration",
            children: {
              component: "@ambisafe/ui-background",
              props: {
                backgroundColor: "white600"
              },
              children: {
                component: "@orderbook/fe-location-restriction",
                children: {
                  component: "@ambisafe/ui-layout/{Container}",
                  props: {
                    minHeight: "100vh"
                  },
                  children: [
                    {
                      component: "@ambisafe/react-router-dom/{RedirectStatement}",
                      props: {
                        statement: false,
                        to: "/restricted-location"
                      },
                      children: [
                        Header,
                        {
                          component: "@ambisafe/ui-layout/{Container}",
                          props: {
                            minHeight: "100vh"
                          },
                          children: [
                            {
                              component: "@ambisafe/ui-layout/{Container}",
                              props: {
                                height: "54px"
                              }
                            },
                            {
                              component: "@ambisafe/notification-center/{ToastContainer}",
                            },
                            {
                              component: "@ambisafe/react-router-dom/{Route}",
                              props: {
                                exact: true,
                                path: "/"
                              },
                              children: Landing
                            },
                            {
                              component: "@orderbook/fe-invest-bank",
                              children: {
                                component: "@ambisafe/react-router-dom/{Route}",
                                props: {
                                  path: "/projects/:projectLink"
                                },
                                children: ProjectDetails
                              }
                            },
                            {
                              component: "@ambisafe/react-router-dom/{Route}",
                              props: {
                                path: "/auth"
                              },
                              children: Auth
                            },
                            {
                              component: "@ambisafe/react-router-dom/{Route}",
                              props: {
                                path: "/account"
                              },
                              children: Account
                            },
                            {
                              component: "@ambisafe/react-router-dom/{Route}",
                              props: {
                                path: "/trade"
                              },
                              children: TradeComingSoon
                            },
                            {
                              component: "@ambisafe/react-router-dom/{Route}",
                              props: {
                                path: "/session-expired"
                              },
                              children: SessionExpired
                            },
                            {
                              component: "@ambisafe/react-router-dom/{Route}",
                              props: {
                                path: "/listing-request"
                              },
                              children: ListingRequest({})
                            },
                            {
                              component: "@ambisafe/react-router-dom/{Route}",
                              props: {
                                path: "/recovery"
                              },
                              children: Recovery
                            }
                          ]
                        },
                        Footer,
                        {
                          component: "@ambisafe/ui-layout/{Container}",
                          props: {
                            height: "54px",
                            hide: {
                              mobileLandscape: true
                            }
                          }
                        },
                        MobilePanel
                      ]
                    },
                    {
                      component: "@ambisafe/react-router-dom/{Route}",
                      props: {
                        path: "/restricted-location"
                      },
                      children: RestrictedLocation
                    }
                  ]
                }
              }
            }
          }
        ]
      }
    }
  }
};
