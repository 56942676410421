/* eslint-disable no-template-curly-in-string */
export const MessageContainer = children => ({
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    width: "100%",
    p: {
      mobile: "xxs",
      tablet: "xxs"
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Container}",
    props: {
      display: "flex",
      maxWidth: "100%",
      justifyContent: "left",
      alignItems: "center"
    },
    children: {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        textAlign: "left",
        display: "border-box",
        fontSize: {
          mobile: "0.85em",
          tabletLandscape: "1em",
          wide: "1em"
        }
      },
      children
    }
  }
});
