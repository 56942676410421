/* eslint-disable no-template-curly-in-string */
import { PersonalKYC } from "./Personal";
import { CorporateKYC } from "./Corporate";

export const KYCVerification = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "/account/verification"
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        hide: {
          tablet: true
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          mb: "sm"
        },
        children: {
          component: "@ambisafe/react-router-dom/{Link}",
          props: {
            to: "/account"
          },
          children: {
            component: "@ambisafe/ui-icons/{ArrowLeft}"
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        mb: "xl"
      },
      children: [
        {
          component: "@ambisafe/ui-text/{Heading2}",
          children: {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "identity_verification"
            }
          }
        },
        {
          component: "@ambisafe/ui-text/{Paragraph}",
          children: {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "identity_verification_description"
            }
          }
        }
      ]
    },
    {
      component: "@ambisafe/ui-background",
      props: {
        backgroundColor: "white100"
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          p: "md"
        },
        children: [
          {
            component: "@ambisafe/fe-statements/{IfEqualTo}",
            props: {
              if: "${user.kycLevel}",
              to: 0
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                pb: "md"
              },
              children: {
                component: "@ambisafe/ui-menu",
                props: {
                  states: {
                    default: {
                      color: "text.primary",
                      backgroundColor: "transparent",
                      opacity: 0.4
                    },
                    hovered: {
                      color: "primary.main",
                      backgroundColor: "transparent",
                      opacity: 1,
                      borderBottom: "2px solid transparent"
                    },
                    focused: {
                      color: "primary.main",
                      backgroundColor: "transparent",
                      opacity: 1,
                      borderBottom: "2px solid transparent"
                    },
                    disabled: {
                      color: "text.disabled",
                      backgroundColor: "transparent",
                      opacity: 0.4,
                      borderBottom: "2px solid transparent"
                    }
                  }
                },
                children: {
                  component: "@ambisafe/ui-layout/{Container}",
                  props: {
                    display: "flex",
                    justifyContent: "space-around"
                  },
                  children: [
                    {
                      component: "@ambisafe/react-router-dom/{NavLink}",
                      props: {
                        exact: true,
                        to: "${match.url}"
                      },
                      children: {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          pr: "xxs"
                        },
                        children: {
                          component: "@ambisafe/ui-text/{Heading4}",
                          props: {
                            noBottomGutter: true,
                            color: "inherit"
                          },
                          children: {
                            component: "@ambisafe/locale/{FormattedMessage}",
                            props: {
                              id: "identity_verification_personal"
                            }
                          }
                        }
                      }
                    },
                    {
                      component: "@ambisafe/react-router-dom/{NavLink}",
                      props: {
                        exact: true,
                        to: "${match.url}/corporate"
                      },
                      children: {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          pl: "xxs"
                        },
                        children: {
                          component: "@ambisafe/ui-text/{Heading4}",
                          props: {
                            noBottomGutter: true,
                            color: "inherit"
                          },
                          children: {
                            component: "@ambisafe/locale/{FormattedMessage}",
                            props: {
                              id: "identity_verification_corporate"
                            }
                          }
                        }
                      }
                    }
                  ]
                }
              }
            }
          },
          {
            component: "@orderbook/fe-identity-verification",
            children: {
              component: "@ambisafe/react-router-dom/{WithRouter}",
              children: [PersonalKYC, CorporateKYC]
            }
          }
        ]
      }
    }
  ]
};
