/* eslint-disable no-template-curly-in-string */
export const UpcomingProject = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    span: {
      mobile: 12,
      tabletLandscape: 4
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "sm"
    },
    children: {  
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        borderRadius: "10px",
        overflow: "hidden",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.05)"
      },
      children: {
        component: "@ambisafe/react-router-dom/{Link}",
        props: {
          to: "/projects/${link}"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          props: {
            width: "100%",
            height: "100%",
            flexDirection: "column"
          },
          children: [
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              children: {
                component: "@ambisafe/ui-background",
                props: {
                  backgroundImage: "url('${coverImage}')",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  width: "100%",
                  height: "200px"
                },
                children: [
                  {
                    component: "@ambisafe/ui-layout/{GridRow}",
                    props: {
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center"
                    },
                    children: {
                      component: "@ambisafe/ui-image",
                      props: {
                        src: "${logoImage}",
                        height: "60%",
                        width: "auto"
                      }
                    }
                  }
                ]
              }
            },
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                flex: 1
              },
              children: {
                component: "@ambisafe/ui-background",
                props: {
                  backgroundColor: "white100",
                  height: "100%"
                },
                children: {
                  component: "@ambisafe/ui-layout/{Container}",
                  children: [
                    {
                      component: "@ambisafe/ui-layout/{Spacing}",
                      props: {
                        px: "xs",
                        py: "sm"
                      },
                      children: [
                        {
                          component: "@ambisafe/ui-layout/{Spacing}",
                          children: {
                            component: "@ambisafe/ui-text/{Heading4}",
                            props: {
                              noBottomGutter: true
                            },
                            children: {
                              component: "@ambisafe/ui-text/{Medium}",
                              children: "${title}"
                            }
                          }
                        },
                        {
                          component: "@ambisafe/ui-divider"
                        },
                        {
                          component: "@ambisafe/ui-layout/{Spacing}",
                          props: {
                            pb: "xs"
                          },
                          children: [
                            {
                              component: "@ambisafe/ui-layout/{GridRow}",
                              props: {
                                justifyContent: "space-between"
                              },
                              children: [
                                {
                                  component: "@ambisafe/ui-layout/{GridCol}",
                                  props: {
                                    width: "50%",
                                    alignItems: "left"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true,
                                        opacity: 0.6
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Normal}",
                                        children: "Offering"
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Light}",
                                        children:  "${offeringType}"
                                      }
                                    }
                                  ]
                                },
                                {
                                  component: "@ambisafe/ui-layout/{GridCol}",
                                  props: {
                                    width: "50%",
                                    alignItems: "left"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true,
                                        opacity: 0.6
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Normal}",
                                        children: "Offering Size"
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Light}",
                                        children: [
                                          {
                                            component: "span",
                                            children: "$"
                                          },
                                          {
                                            component: "react-intl/{FormattedNumber}",
                                            props: {
                                              value: "${offeringSize}"
                                            }
                                          }
                                        ]
                                      }
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        {
                          component: "@ambisafe/ui-layout/{Spacing}",
                          props: {
                            pb: "xs"
                          },
                          children: [
                            {
                              component: "@ambisafe/ui-layout/{GridRow}",
                              props: {
                                justifyContent: "space-between"
                              },
                              children: [
                                {
                                  component: "@ambisafe/ui-layout/{GridCol}",
                                  props: {
                                    width: "50%",
                                    alignItems: "left"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true,
                                        opacity: 0.6
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Normal}",
                                        children: "Open Date"
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Light}",
                                        children: {
                                          component: "react-intl/{FormattedDate}",
                                          props: {
                                            year: "numeric",
                                            month: "short",
                                            day: "2-digit",
                                            value: "${startDate}"
                                          }
                                        }
                                      }
                                    }
                                  ]
                                },
                                {
                                  component: "@ambisafe/ui-layout/{GridCol}",
                                  props: {
                                    width: "50%",
                                    alignItems: "left"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true,
                                        opacity: 0.6
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Normal}",
                                        children: "Closing Date"
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Light}",
                                        children: {
                                          component: "react-intl/{FormattedDate}",
                                          props: {
                                            year: "numeric",
                                            month: "short",
                                            day: "2-digit",
                                            value: "${endDate}"
                                          }
                                        }
                                      }
                                    }
                                  ]  
                                }
                              ]
                            }
                          ]
                        },
                        {
                          component: "@ambisafe/ui-layout/{Spacing}",
                          children: [
                            {
                              component: "@ambisafe/ui-layout/{GridRow}",
                              props: {
                                justifyContent: "space-between"
                              },
                              children: [
                                {
                                  component: "@ambisafe/ui-layout/{GridCol}",
                                  props: {
                                    width: "50%",
                                    alignItems: "left"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true,
                                        textTransform: "capitalize",
                                        opacity: 0.6
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Normal}",
                                        children: "Min Investment"
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Light}",
                                        children: [
                                          {
                                            component: "span",
                                            children: "$"
                                          },
                                          {
                                            component: "react-intl/{FormattedNumber}",
                                            props: {
                                              value: "${minInvestmentInUSD}"
                                            }
                                          }
                                        ]
                                      }
                                    }
                                  ]
                                },
                                {
                                  component: "@ambisafe/ui-layout/{GridCol}",
                                  props: {
                                    width: "50%",
                                    alignItems: "left"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true,
                                        opacity: 0.6
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Normal}",
                                        children: "Offering Max"
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-text/{Heading5}",
                                      props: {
                                        noBottomGutter: true
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Light}",
                                        children: [
                                          {
                                            component: "span",
                                            children: "$"
                                          },
                                          {
                                            component: "react-intl/{FormattedNumber}",
                                            props: {
                                              value: "${hardCap}"
                                            }
                                          }
                                        ]
                                      }
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    }
  }
};
