/* eslint-disable no-template-curly-in-string */

import { Crypto } from "./Crypto";
import { Fiat } from "./Fiat";

export const Deposit = {
  component: "@ambisafe/fe-statements/{IfEqualTo}",
  props: {
    if: "${assetId}",
    to: "${selectedForDepositAssetId}"
  },
  children: [
    Crypto,
    Fiat
  ]
};
