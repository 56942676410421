export const Divider = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    margin: "2rem 0",
    hide: {
      mobile: true,
      tabletLandscape: false
    }
  },
  children: {
    component: "@ambisafe/ui-divider",
    props: {
      vertical: true
    }
  }
};
