/* eslint-disable no-template-curly-in-string */
const SuccessIcon = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "top"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pb: {
        mobile: "xxs",
        tablet: "xxs"
      }
    },
    children: {
      component: "@ambisafe/ui-icons/{CheckCircleOutline}",
      props: {
        width: "5em",
        height: "5em"
      }
    }
  }
};

export const SuccessMessage = {
  component: "@ambisafe/ui-layout/{GridContainer}",
  props: {
    display: "grid",
    justiryContent: "center",
    gap: "1rem",
    width: {
      mobile: "auto",
      tablet: "80ch"
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      p: {
        mobile: "sm",
        tablet: "xl"
      }
    },
    children: {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "inherit"
      },
      children: [
        SuccessIcon,
        {
          component: "@ambisafe/ui-text/{Paragraph}",
          props: {
            textAlign: "center",
            display: "border-box",
            fontSize: {
              mobile: "0.85em",
              tabletLandscape: "1em",
              wide: "1em"
            }
          },
          children: {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "paypal.order_approved_successfully"
            }
          }
        }
      ]
    }
  }
};
