import React, { ReactElement } from "react";
import ReactDOM from "react-dom";

import { Builder, ApolloClient } from "@ambisafe/fe-core";
import Config from "@orderbook/fe-config";

import * as serviceWorker from "./serviceWorker";
import { getHttpEndpoint, getWsEndpoint } from "./utils";
import { ConfigProps } from "./types";

// import localConfig from "./dev-configs/orderbook";
import localConfig from "./dev-configs/ohanae";

const httpEndpoint = getHttpEndpoint();
const wsEndpoint = getWsEndpoint();

ReactDOM.render(
  <ApolloClient httpUrl={`${httpEndpoint}/graphql`} wsUrl={`${wsEndpoint}/graphql`}>
    <Config httpUrl={httpEndpoint}>
      {({ config, messages, locale, languages }: ConfigProps): ReactElement => (
        <>
          <Builder
            theme={process.env.NODE_ENV === "development" ? localConfig.theme : config.theme}
            component={process.env.NODE_ENV === "development" ? localConfig.component : config.component}
            asyncModulesMap={global.asyncModules}
            locale={locale}
            messages={messages}
            languages={languages}
            getIntlLocale={(intlLocale: string): Promise<void> => import(`react-intl/locale-data/${intlLocale}.js`)}
          />
        </>
      )}
    </Config>
  </ApolloClient>,
  document.getElementById("root")
);

document.addEventListener("DOMContentLoaded", () => {
  // @ts-ignore
  global.asyncModules["@ambisafe/ui-form"]();
});

serviceWorker.unregister();
