/* eslint-disable no-template-curly-in-string */
export const ActionButtons = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    alignRight: true,
    display: "flex",
    alignItems: "flex-end"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mt: "xs"
    },
    children: {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          children: {
            component: "@ambisafe/react-router-dom/{Link}",
            props: {
              to: "${match.url}?withdraw=${assetId}"
            },
            children: {
              component: "@ambisafe/ui-button",
              props: {
                inverse: true,
                width: "100%"
              },
              children: {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  py: "xxs",
                  px: "xs"
                },
                children: {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  props: {
                    noBottomGutter: true,
                    textAlign: "center",
                    textTransform: "uppercase"
                  },
                  children: {
                    component: "react-intl/{FormattedMessage}",
                    props: {
                      id: "cancel"
                    }
                  }
                }
              }
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          children: {
            component: "@ambisafe/ui-button",
            props: {
              type: "submit",
              width: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                py: "xxs",
                px: "xs"
              },
              children: {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  noBottomGutter: true,
                  textAlign: "center",
                  textTransform: "uppercase"
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "confirm"
                  }
                }
              }
            }
          }
        }
      ]
    }
  }
};
