/* eslint-disable no-template-curly-in-string */
export const Login = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mt: "xs"
  },
  children: {   
    component: "@ambisafe/react-router-dom/{Link}",
    props: {
      to: "/auth/login?redirectTo=/projects/${project.link}"
    },
    children: {
      component: "@ambisafe/ui-button",
      props: {
        width: "100%"
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          p: "xxs"
        },
        children: {
          component: "@ambisafe/ui-text/{Heading4}",
          props: {
            noBottomGutter: true,
            textAlign: "center"
          },
          children: {
            component: "@ambisafe/ui-text/{Normal}",
            children: {
              component: "react-intl/{FormattedMessage}",
              props: {
                id: "login"
              }
            }
          }
        }
      }
    }
  }
};
