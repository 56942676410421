import { QrCode } from "./QrCode";
import { InvoiceAddress } from "./InvoiceAddress";

const TemporaryAddressAlert = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    py: "xxs"
  },
  children: {
    component: "@ambisafe/ui-text/{Paragraph}",
    props: {
      textAlign: {
        mobile: "center",
        mobileLandscape: "left"
      },
      textTransform: "uppercase",
      whiteSpace: "pre-wrap",
      noBottomGutter: true,
      fontSize: {
        mobile: "0.675rem",
        mobileLandscape: "0.775rem"
      }
    },
    children: [
      {
        component: "span",
        children: "❗️ "
      },
      {
        component: "@ambisafe/ui-text/{Normal}",
        children: {
          component: "@ambisafe/locale/{FormattedMessage}",
          props: {
            id: "temporary_address_alert"
          }
        }
      }
    ]
  }
};

export const InvoiceContainer = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    p: {
      mobile: "xxs",
      tablet: "xl"
    },
    height: "inherit"
  },
  children: {
    component: "@ambisafe/ui-layout/{Container}",
    props: {
      display: "grid",
      justifyContent: "center",
      width: "100%",
      height: "inherit",
      overflow: "hidden"
    },
    children: [InvoiceAddress, TemporaryAddressAlert, QrCode]
  }
};
