/* eslint-disable no-template-curly-in-string */
export const AmountField = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pb: "16px"
  },
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    children: [
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        children: {
          component: "@ambisafe/ui-layout/{Container}",
          props: {
            display: "flex",
            height: "100%",
            alignItems: "center"
          },
          children: {
            component: "@ambisafe/ui-text/{Heading5}",
            props: {
              opacity: 0.6,
              inline: true,
              noBottomGutter: true
            },
            children: {
              component: "@ambisafe/ui-text/{Normal}",
              children: "${SM_projectAssetDisplaySymbol}"
            }
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          flex: 1
        },
        children: {
          component: "@ambisafe/ui-form/{Field}",
          props: {
            type: "number",
            name: "amount",
            placeholder: "Amount",
            onChange: "${SM_setAmountHandler}",
            min: "${SM_amountMin}",
            max: "${SM_amountMax}",
            step: "${SM_amountStep}",
            validate: "${SM_validationRules}",
          }
        }
      }
    ]
  }
};
