/* eslint-disable no-template-curly-in-string */
const Link = ({ to, onClick = () => {}, type }) => {
  const linkTypesMap = {
    DEPOSIT: {
      icon: "@ambisafe/ui-icons/{Deposit}",
      messageId: "deposit"
    },
    RECEIVE: {
      icon: "@ambisafe/ui-icons/{Deposit}",
      messageId: "Receive"
    },
    SEND: {
      icon: "@ambisafe/ui-icons/{Withdraw}",
      messageId: "Send"
    },
    WITHDRAW: {
      icon: "@ambisafe/ui-icons/{Withdraw}",
      messageId: "withdraw"
    }
  };

  return {
    component: "@ambisafe/ui-layout/{GridCol}",
    props: {
      width: type === "SEND" ? "73px" : "auto",
      display: "flex",
      justifyContent: "center"
    },
    children: {
      component: "@ambisafe/react-router-dom/{Link}",
      props: {
        to,
        onClick
      },
      children: [
        {
          component: "@ambisafe/ui-text/{Paragraph}",
          props: {
            noBottomGutter: true,
            textAlign: "center"
          },
          children: {
            component: linkTypesMap[type].icon
          }
        },
        {
          component: "@ambisafe/ui-text/{Paragraph}",
          props: {
            noBottomGutter: true,
            fontSize: "sm",
            color: "inherit",
            textAlign: "center"
          },
          children: {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: linkTypesMap[type].messageId
            }
          }
        }
      ]
    }
  };
};

export const DepositLink = Link({
  to: "${match.url}?deposit=${assetId}",
  onClick: "${handleDepositClick}",
  type: "DEPOSIT"
});

export const ReceiveLink = Link({
  to: "${match.url}?receive=${assetId}",
  type: "RECEIVE"
});

export const SendLink = Link({
  to: "${match.url}?send=${assetId}",
  type: "SEND"
});

export const WithdrawLink = Link({
  to: "${match.url}?withdraw=${assetId}",
  onClick: "${handleWithdrawClick}",
  type: "WITHDRAW"
});
