/* eslint-disable no-template-curly-in-string */
import { FiatSendForm } from './NonTwoFA/FiatSendForm';
import { TwoFASendForm } from './TwoFA/TwoFASendForm';

export const Send = {
  component: "@ambisafe/fe-statements/{IfEqualTo}",
  props: {
    if: "${assetId}",
    to: "${selectedForSendAssetId}"
  },
  children: [
    {
      component: "@ambisafe/fe-statements/{IfFalse}",
      props: {
        if: "${selectedForWithdrawTwoFAType}"
      },
      children: FiatSendForm,
    },
    {
      component: "@ambisafe/fe-statements/{IfEqualTo}",
      props: {
        if: "${selectedForWithdrawTwoFAType}",
        to: "EMAIL"
      },
      children: TwoFASendForm
    }
  ]
};
