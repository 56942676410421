/* eslint-disable no-template-curly-in-string */

import { ExchangeRateInfo } from "./ExchangeRateInfo";
import { AmountField } from "./AmountField";
import { Buttons } from "./Buttons";

export const Form = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.path}",
    exact: true,
  },
  children: {
    component: "@ambisafe/ui-form/{Form}",
    props: {
      onSubmit: "${SM_buildRequestHandler}",
      initialValues: {
        amount: "${SM_operationAmount}"
      }
    },
    children: [
      ExchangeRateInfo,
      AmountField,
      {
        component: "@ambisafe/fe-statements/{IfTrue}",
        props: {
          if: "${submitErrors}"
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pt: "xxs"
          },
          children: {
            component: "@ambisafe/ui-form/{SubmitError}"
          }
        }
      },
      Buttons
    ]
  }
};
