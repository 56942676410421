/* eslint-disable no-template-curly-in-string */
export const Confirmation = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.path}/confirmation",
    prevURL: "${match.url}"
  },
  children: {
    component: "@ambisafe/ui-form/{Form}",
    props: {
      onSubmit: "${SM_submitRequestHandler}",
    },
    children: [
      {
        component: "@ambisafe/ui-text/{Heading5}",
        props: {
          opacity: 0.6,
        },
        children: {
          component: "@ambisafe/ui-text/{Normal}",
          children: "${SM_exchangeRateMessage}"
        }
      },
      {
        component: "@ambisafe/fe-statements/{IfTrue}",
        props: {
          if: "${submitErrors}"
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pb: "xxs"
          },
          children: {
            component: "@ambisafe/ui-form/{SubmitError}"
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{GridRow}",
        children: {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: 12,
            display: "flex"
          },
          children: [
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                pr: "15px",
                width: "100%"
              },
              children: {
                component: "@ambisafe/react-router-dom/{Link}",
                props: {
                  to: "${prevURL}",
                },
                children: {
                  component: "@ambisafe/ui-button",
                  props: {
                    width: "100%"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      p: "xxs"
                    },
                    children: {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true,
                        textAlign: "center"
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: {
                          component: "@ambisafe/locale/{FormattedMessage}",
                          props: {
                            id: "Back"
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                pl: "15px",
                width: "100%"
              },
              children: {
                component: "@ambisafe/ui-button",
                props: {
                  width: "100%",
                  type: "submit"
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    p: "xxs"
                  },
                  children: {
                    component: "@ambisafe/ui-text/{Heading4}",
                    props: {
                      noBottomGutter: true,
                      textAlign: "center"
                    },
                    children: {
                      component: "@ambisafe/ui-text/{Normal}",
                      children: {
                        component: "@ambisafe/locale/{FormattedMessage}",
                        props: {
                          id: "Confirm"
                        }
                      }
                    }
                  }
                }
              }
            }
          ]
        }
      }
    ]
  }
};
