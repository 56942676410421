/* eslint-disable no-template-curly-in-string */
export const MakeDepositMessage = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mt: "xxl",
    pb: "md"
  },
  children: {
    component: "@ambisafe/ui-text/{Paragraph}",
    props: {
      noBottomGutter: true
    },
    children: {
      component: "@ambisafe/ui-text/{Normal}",
      children: [
        {
        // This text is temporary in its place should be this
        // component: "react-intl/{FormattedMessage}",
        // props: {
        //   id: "please_make_a_deposit"
        // }
          component: "span",
          children: {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: "project.pay_through_qr_code_description"
            }
          }
        },
        {
          component: "span",
          children: ":"
        }
      ]
    }
  }
};
