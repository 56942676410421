/* eslint-disable no-template-curly-in-string */
import {
  Buttons,
  Divider,
  Disclaimer,
  TotalValues,
  AmountField,
  SubmitErrors,
  RecepientAddressField,
} from "./components";

export const FiatSendForm = {
  component: "@ambisafe/fe-statements/{IfEqualToSome}",
  props: {
    if: "${type}",
    to: ["FIAT", "CRYPTOFIAT"]
  },
  children: [
    Divider,
    {
      component: "@ambisafe/ui-form/{Form}",
      props: {
        onSubmit: "${handleWithdrawalSubmit}",
        disabled: "${selectedForWithdrawServerCode}"
      },
      children: [
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            RecepientAddressField,
            AmountField,
            TotalValues
          ]
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Disclaimer,
            Buttons
          ]
        },
        SubmitErrors
      ]
    }
  ]
};
