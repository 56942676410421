/* eslint-disable no-template-curly-in-string */
export const ArrowRight = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    hide: {
      tablet: true
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "sm"
    },
    children: {
      component: "@ambisafe/react-router-dom/{Link}",
      props: {
        to: "/account"
      },
      children: {
        component: "@ambisafe/ui-icons/{ArrowLeft}"
      }
    }
  }
};
