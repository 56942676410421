/* eslint-disable no-template-curly-in-string */
import { PaymentMethod } from "./PaymentMethod";
import { Payment } from "./Payment";

const NotAllDocsAreSigned = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    exact: true,
    path: "${match.url}/:displaySymbol",
  },
  children: {
    component: "@ambisafe/fe-statements/{IfFalse}",
    props: {
      if: "${isThereAllOtpCodeConfirmationDocsAreSigned}",
    },
    children: {
      component: "@orderbook/fe-project-sign-documents",
      props: {
        projectId: "${project.id}",
        projectLink: "${project.link}",
        documentsToSign: "${otpCodeConfirmationDocumentsToSign}",
        serverCode: "${selectedForBuyServerCode}",
        onSuccesfullSign: "${setAllDocumentsToSigned}",
      },
      children: {
        component: "@orderbook/shared/{SignOtpCodeConfirmationDocumentsModal}",
        props: {
          documentsToSign: "${documentsToSign}",
          initialValues: "${documentsToSignInitialValues}",
          signDocumentsError: "${error}",

          url: "${match.url}",
          search: "${location.search}",

          onClearError: "${clearError}",
          onSign: "${signDocuments}",
        },
      },
    },
  },
};

export const PrimaryMarket = {
  component: "@ambisafe/react-router-dom/{RedirectFalsyStatement}",
  props: {
    statement: "${user}",
    to: "/projects/${project.link}"
  },
    children: {
      component: "@ambisafe/react-router-dom/{RedirectStatement}",
      props: {
        statement: "${project.areThereDocumentsToSign}",
        to: "/projects/${project.link}/documents"
      },
      children: {
        component: "@ambisafe/react-router-dom/{Switch}",
        children: [
          PaymentMethod,
          Payment,
          NotAllDocsAreSigned,
        ]
      }
    }
};
