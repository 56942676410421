/* eslint-disable no-template-curly-in-string */
const EmailCode = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: {
      mobile: "100%"
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "xs"
    },
    children: {
      component: "@ambisafe/ui-form/{Field}",
      props: {
        name: "code",
        label: "form.email_code",
        placeholder: "",
        validate: ["required", ["length", 8]],
      }
    }
  }
};

const CancelButton = {
  component: "@ambisafe/ui-layout/{GridCol}",
  children: {
    component: "@ambisafe/react-router-dom/{Link}",
    props: {
      to: "${match.url}?withdraw=${assetId}"
    },
    children: {
      component: "@ambisafe/ui-button",
      props: {
        width: "100%"
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          py: "xxs",
          px: "xs"
        },
        children: {
          component:
            "@ambisafe/ui-text/{Paragraph}",
          props: {
            noBottomGutter: true,
            textAlign: "center",
            textTransform: "uppercase"
          },
          children: {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "cancel"
            }
          }
        }
      }
    }
  }
};

const ConfirmButton = {
  component: "@ambisafe/ui-layout/{GridCol}",
  children: {
    component: "@ambisafe/ui-button",
    props: {
      type: "submit",
      width: "100%"
    },
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        py: "xxs",
        px: "xs"
      },
      children: {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          noBottomGutter: true,
          textAlign: "center",
          textTransform: "uppercase"
        },
        children: {
          component: "react-intl/{FormattedMessage}",
          props: {
            id: "confirm"
          }
        }
      }
    }
  }
};

const SubmitErrors = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${submitErrors}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pt: "xxs"
    },
    children: {
      component: "@ambisafe/ui-form/{SubmitError}"
    }
  }
};

const AllDocsAreSigned = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${allOtpCodeConfirmationDocsAreSigned}",
  },
  children: [
    {
      component: "@ambisafe/ui-divider",
      props: {
        opacity: 0.2
      }
    },
    {
      component: "@ambisafe/ui-form/{Form}",
      props: {
        onSubmit: "${handleBuyTwoFASubmit}"
      },
      children: [
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            EmailCode,
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                alignRight: true
              },
              children: {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  mt: "xs"
                },
                children: {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  children: [
                    CancelButton,
                    ConfirmButton
                  ]
                }
              }
            }
          ]
        },
        SubmitErrors
      ]
    }
  ]
};

const NotAllDocsAreSigned = {
  component: "@ambisafe/fe-statements/{IfFalse}",
  props: {
    if: "${allOtpCodeConfirmationDocsAreSigned}",
  },
  children: {
    component: "@orderbook/shared/{SignOtpCodeConfirmationDocumentsModal}",
    props: {
      documentsToSign: "${otpCodeConfirmationDocumentsToSign}",
      initialUrl: "${ggg}",
      onClose: "${f=>f}",
      onSign: "${f=>f}",
    },
    // children: ,
  }
};

export const EmailCodeForm = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${selectedForBuyTwoFAType}"
  },
  children: {
    component: "@ambisafe/fe-statements/{IfEqualTo}",
    props: {
      if: "${selectedForBuyTwoFAType}",
      to: "EMAIL"
    },
    children: [
      // AllDocsAreSigned,
      {
        component: "@ambisafe/ui-divider",
        props: {
          opacity: 0.2
        }
      },
      {
        component: "@ambisafe/ui-form/{Form}",
        props: {
          onSubmit: "${handleBuyTwoFASubmit}"
        },
        children: [
          {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [
              EmailCode,
              {
                component: "@ambisafe/ui-layout/{GridCol}",
                props: {
                  alignRight: true
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    mt: "xs"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{GridRow}",
                    children: [
                      CancelButton,
                      ConfirmButton
                    ]
                  }
                }
              }
            ]
          },
          SubmitErrors
        ]
      },
      // NotAllDocsAreSigned,
    ],
  }
};
