/* eslint-disable no-template-curly-in-string */
export const Steps = {
  component: "@orderbook/fe-asset-invoice-status",
  props: {
    assetDisplaySymbol: "${displaySymbol}",
    project: "${project}"
  },
  children: [
    {
      component: "@ambisafe/ui-stepper",
      props: {
        vertical: true,
        activeStep: "${activeStep}",
        steps: "${stepsLabels}"
      }
    }
  ]
};
