/* eslint-disable no-template-curly-in-string */
export const UpcomingProjectInfo = {
  component: "@ambisafe/fe-statements/{IfgtElse}",
  props: {
    if: "${project.startDate}",
    else: "${project.currentDate}"
  },
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    props: {
      justifyContent: "center"
    },
    children: {
      component: "@ambisafe/ui-layout/{GridCol}",
      children: [
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pb: "xs"
          },
          children: {
            component: "@ambisafe/ui-countdown",
            props: {
              date: "${project.startDate}"
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          children: {
            component: "@ambisafe/ui-text/{Heading5}",
            props: {
              textAlign: "center",
              textTransform: "uppercase",
              noBottomGutter: true,
              opacity: 0.6
            },
            children: {
              component: "@ambisafe/ui-text/{Normal}",
              children: "Until Close of Offering"
            }
          }
        }
      ]
    }
  }
};
