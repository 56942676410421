/* eslint-disable no-template-curly-in-string */
export const Heading = {
  component: "@ambisafe/ui-text/{Heading2}",
  children: {
    component: "react-intl/{FormattedMessage}",
    props: {
      id: "assets"
    }
  }
};
