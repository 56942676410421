/* eslint-disable no-template-curly-in-string */
export const Congratulations = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.url}/congratulations",
    displaySymbol: "${match.params.displaySymbol}"
  },
  children: {
    component: "@orderbook/fe-asset-invoice-status",
    props: {
      assetDisplaySymbol: "${displaySymbol}",
      project: "${project}"
    },
    children: [
      {
        component: "@ambisafe/ui-text/{Heading3}",
        props: {
          color: "primary.main",
          textAlign: "center"
        },
        children: [
          {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "congratulations"
            }
          },
          {
            component: "span",
            children: "!"
          }
        ]
      },
      {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          textAlign: "center"
        },
        children: {
          component: "@ambisafe/ui-icons/{Congratulations}"
        }
      },
      {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          color: "primary.main",
          textAlign: "center"
        },
        children: [
          {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "you_contributed"
            }
          },
          {
            component: "span",
            children: ": "
          },
          {
            component: "span",
            children: "${assetInvoiceStatus.counterAmount}"
          },
          {
            component: "span",
            children: " "
          },
          {
            component: "span",
            children: "${displaySymbol}"
          }
        ]
      },
      {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          color: "primary.main",
          textAlign: "center"
        },
        children: [
          {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "you_will_receive"
            }
          },
          {
            component: "span",
            children: ": "
          },
          {
            component: "span",
            children: "${assetInvoiceStatus.baseAmount}"
          },
          {
            component: "span",
            children: " "
          },
          {
            component: "span",
            children: "${project.projectAsset.displaySymbol}"
          }
        ]
      },
      {
        component: "@orderbook/fe-say-thank-you",
        props: {
          projectId: "${project.id}",
          projectLink: "${project.link}",
          assetId: "${assetInvoiceStatus.assetId}"
        },
        children: {
          component: "@ambisafe/ui-button",
          props: {
            width: "100%",
            onClick: "${sayThankYou}"
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: "xxs"
            },
            children: {
              component: "@ambisafe/ui-text/{Heading4}",
              props: {
                noBottomGutter: true,
                textAlign: "center"
              },
              children: {
                component: "@ambisafe/ui-text/{Normal}",
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "confirm"
                  }
                }
              }
            }
          }
        }
      }
    ]
  }
};
