/* eslint-disable no-template-curly-in-string */
import { CurrentProjects, UpcomingProjects, SecondaryProjects } from "../Projects";

export const Landing = [
  {
    component: "@ambisafe/ui-layout/{GridContainer}",
    children: {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        fluid: false
      },
      children: [
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            py: "xxl"
          },
          children: {
            component: "@orderbook/fe-invest-bank",
            children: [
              {
                component: "@ambisafe/ui-layout/{Container}",
                children: {
                  component: "@ambisafe/fe-statements/{IfEvery}",
                  props: {
                    if: "${investBank.currentProjects.length} ${investBank.upcomingProjects.length} ${investBank.secondaryProjects.length}",
                    to: "0 0 0"
                  },
                  children: {
                    component: "@ambisafe/ui-text/{Heading4}",
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "no_available_projects"
                      }
                    }
                  }
                }
              },
              {
                component: "@ambisafe/ui-layout/{Container}",
                children: [
                  CurrentProjects,
                  UpcomingProjects,
                  SecondaryProjects
                ]
              }
            ]
          }
        }
      ]
    }
  }
];
