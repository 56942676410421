import * as Ambisafe from 'ambisafe-client-javascript';
import { keccak, ecsign, toBuffer, addHexPrefix } from 'ethereumjs-util';
import { useEffect } from 'react';

const useBreakpointCallback = (breakpoint, callback) => {
    useEffect(() => {
        var x = window.matchMedia(breakpoint);
        callback(x);
        x.addEventListener("change", callback);
        return () => x.removeEventListener("change", callback);
    }, []);
};

const useScrollListener = (cb, condition) => {
    const scrollHandler = () => {
        if (condition) {
            cb();
        }
    };
    useEffect(() => {
        document.addEventListener("scroll", scrollHandler);
        return () => {
            document.removeEventListener("scroll", scrollHandler);
        };
    }, [scrollHandler]);
};

const hashPassword = (password) => {
    return keccak(password).toString("hex");
};
const hashPasswordOld = (password) => {
    return Ambisafe.deriveKey(password, Ambisafe.SHA1(password), 2000);
};
const isGooglebot = () => {
    return Boolean(navigator.userAgent.match(/Googlebot/g));
};
const isDevEnv = () => {
    return process.env.NODE_ENV !== "production";
};
const isOB = () => {
    return Boolean(window === null || window === void 0 ? void 0 : window.location.host.match(/orderbook/gi));
};
const isDevHost = () => {
    return Boolean(window === null || window === void 0 ? void 0 : window.location.host.match(/(develop|staging)/gi));
};
const sign = (hash, privateKey, withOpHash) => {
    if (privateKey === undefined) {
        throw Error("To sign document private key is required");
    }
    const signature = ecsign(toBuffer(addHexPrefix(hash)), toBuffer(addHexPrefix(privateKey)));
    return {
        opHash: withOpHash ? hash : undefined,
        v: signature.v,
        r: `0x${signature.r.toString("hex")}`,
        s: `0x${signature.s.toString("hex")}`,
    };
};
const googleTagScript = () => {
    if (!isOB() || isDevHost())
        return;
    !(function (f, b, e, v, n, t, s) {
        if (f.fbq)
            return;
        n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
        };
        if (!f._fbq)
            f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
    fbq("init", "1432685993818593");
    fbq("track", "Lead");
};

export { googleTagScript, hashPassword, hashPasswordOld, isDevEnv, isDevHost, isGooglebot, isOB, sign, useBreakpointCallback, useScrollListener };
