/* eslint-disable no-template-curly-in-string */
import { PayPalSuccesfullyApprovedOrderDialog } from "../ApproveOrderDialog";

export const AmountHeading = {
  component: "@ambisafe/ui-text/{Heading6}",
  props: {
    mb: "xs",
    fontFamily: "Roboto, sans-serif"
  },
  children: {
    component: "@ambisafe/ui-text/{Medium}",
    children: {
      component: "react-intl/{FormattedMessage}",
      props: {
        id: "form.enter_fiat_deposit_amount"
      }
    }
  }
};

export const AmountField = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mb: "xxs"
  },
  children: {
    component: "@ambisafe/ui-form/{Field}",
    props: {
      name: "amount",
      type: "text",
      placeholder: "",
      validate: ["required"]
    }
  }
};

export const FormErrors = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${submitErrors}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pb: "xxs"
    },
    children: {
      component: "@ambisafe/ui-form/{SubmitError}"
    }
  }
};

export const PaymentMethodHeading = {
  component: "@ambisafe/ui-text/{Heading6}",
  props: {
    mb: "xs",
    fontFamily: "Roboto, sans-serif"
  },
  children: {
    component: "@ambisafe/ui-text/{Medium}",
    children: {
      component: "react-intl/{FormattedMessage}",
      props: {
        id: "form.fiat_payment_method"
      }
    }
  }
};

export const WireButton = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pb: "xxs"
  },
  children: {
    component: "@ambisafe/ui-button",
    props: {
      type: "submit",
      width: "100%",
      onClick: "${handleWireClick}"
    },
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        py: "10px",
        px: "xs"
      },
      children: {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          fontFamily: "Roboto, sans-rerif",
          fontSize: "sm",
          noBottomGutter: true,
          textAlign: "center"
        },
        children: {
          component: "@ambisafe/ui-text/{Medium}",
          children: {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "wire-transfer"
            }
          }
        }
      }
    }
  }
};

export const HiddenSubmitButton = {
  component: "@ambisafe/ui-button",
  props: {
    elementRef: "${hiddenSubmitButtonRef}",
    type: "submit",
    hidden: true
  },
  children: "Submit"
};

export const PayPal = {
  component: "@orderbook/fe-paypal/{ScriptProvider}",
  props: {
    scriptProviderOptions: {
      components: "buttons",
      currency: "USD",
      vault: true,
      intent: "capture",
      sandboxClientId: "AUwRg9iIMA_4BMjH5yJcXo_R-cT3_kHSTwv8zy3csCZII2joGgLqJu3Xo-PVPDJSIO4Nlu64tuTdqkOe",
      clientId: "AbXn_vNpxed4gqa5jXdScYl1IHRAEwldN0-JzVWM3RdzaqSm0h0AR4FRnt7KMb50XKXDOXX-3z6Ej14I"
    },
    amount: "${values.amount}"
  },
  children: [
    {
      component: "@orderbook/fe-paypal/{Button}",
      props: {
        style: {
          shape: "rect",
          height: 32
        },
        disabled: "${isDisabledPaypal}",
        fundingSource: "card",
        onClick: "${handlePayPalClick}",
        onCancel: "${handlePayPalCancel}",
        onApprove: "${handlePayPalApprove}",
        onError: "${handlePayPalError}",
        createOrder: "${handlePayPalCreateOrder}"
      }
    },
    {
      component: "@ambisafe/fe-statements/{IfTrue}",
      props: {
        if: "${orderApprovedSuccesfully}"
      },
      children: PayPalSuccesfullyApprovedOrderDialog
    }
  ]
};
