/* eslint-disable no-template-curly-in-string */
export const Discount = {
  component: "@ambisafe/ui-text/{Paragraph}",
  props: {},
  children: {
    component: "@ambisafe/fe-statements/{IfNotEqualTo}",
    props: {
      if: "${discount}",
      to: "0"
    },
    children: [
      {
        component: "react-intl/{FormattedMessage}",
        props: {
          id: "with_discount"
        }
      },
      {
        component: "span",
        children: ": "
      },
      {
        component: "span",
        children: "${discount}"
      },
      {
        component: "span",
        children: "%"
      }
    ]
  }
};
