/* eslint-disable no-template-curly-in-string */
import { ActionButtons } from './ActionButtons';

export const TwoFASendForm = [
  {
    component: "@ambisafe/ui-divider",
    props: {
      opacity: 0.2
    }
  },
  {
    component: "@ambisafe/ui-form/{Form}",
    props: {
      onSubmit: "${handleWithdrawalTwoFASubmit}"
    },
    children: [
      {
        component: "@ambisafe/ui-layout/{GridRow}",
        children: [
          {
            component: "@ambisafe/ui-layout/{GridCol}",
            children: [
              {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  whiteSpace: "pre"
                },
                children: [
                  {
                    component: "@ambisafe/locale/{FormattedMessage}",
                    props: {
                      id: "you_are_going_to_withdraw"
                    }
                  },
                  ":"
                ]
              },
              {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  fontSize: "lg",
                  color: "primary.main",
                  inline: true,
                  whiteSpace: "pre"
                },
                children: [
                  "${amountToWithdraw}", " ", "${displaySymbol}"
                ]
              },
              {
                component: "@ambisafe/ui-layout/{GridRow}",
                props: {
                  gutter: 0
                },
                children: [
                  {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      whiteSpace: "pre"
                    },
                    children: [
                      {
                        component: "@ambisafe/locale/{FormattedMessage}",
                        props: {
                          id: "fee"
                        }
                      },
                      ":  "
                    ]
                  },
                  {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      fontSize: "md",
                      color: "primary.main",
                      inline: true,
                      whiteSpace: "pre"
                    },
                    children: [
                      "${withdrawFee}", " ", "${displaySymbol}"
                    ]
                  }
                ]
              },
              {
                component: "@ambisafe/ui-layout/{GridRow}",
                props: {
                  gutter: 0
                },
                children: [
                  {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      whiteSpace: "pre"
                    },
                    children: [
                      {
                        component: "@ambisafe/locale/{FormattedMessage}",
                        props: {
                          id: "total"
                        }
                      },
                      ":  "
                    ]
                  },
                  {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      fontSize: "md",
                      color: "primary.main",
                      inline: true,
                      whiteSpace: "pre"
                    },
                    children: [
                      "${totalWithdrawAmount}", " ", "${displaySymbol}"
                    ]
                  }
                ]
              }
            ]
          },
          {
            component: "@ambisafe/ui-layout/{GridCol}",
            props: {
              flex: 1,
              maxWidth: "100%"
            },
            children: [
              {
                component: "@ambisafe/fe-statements/{IfEqualToSome}",
                props: {
                  if: "${type}",
                  to: ["CRYPTO", "PROJECT", "BTC"]
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    ml: "xs",
                    mr: "xs",
                    mb: "xxs",
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{GridRow}",
                    props: {
                      alignItems: "center"
                    },
                    children: [
                      {
                        component: '@material-ui/icons/{Warning}'
                      },
                      {
                        component: "@ambisafe/ui-text/{Paragraph}",
                        props: {
                          whiteSpace: "pre",
                          inline: true,
                          noBottomGutter: true
                        },
                        children: [
                          " ",
                          {
                            component: "@ambisafe/locale/{FormattedMessage}",
                            props: {
                              id: "recipient_address"
                            }
                          },
                          ". ",
                          {
                            component: "@ambisafe/locale/{FormattedHTMLMessage}",
                            props: {
                              id: "portfolio.make_sure_you_are_using",
                              values: {
                                providerDescription: "${providerDescription}"
                              }
                            }
                          }
                        ]
                      }
                    ]
                  }
                }
              },
              {
                component: "@ambisafe/fe-statements/{IfEqualToSome}",
                props: {
                  if: "${type}",
                  to: ["FIAT", "CRYPTOFIAT"]
                },
                children: {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  props: {
                    whiteSpace: "pre"
                  },
                  children: [
                    {
                      component: "@ambisafe/locale/{FormattedMessage}",
                      props: {
                        id: "bank"
                      }
                    },
                    {
                      component: "span",
                      children: ": "
                    }
                  ]
                }
              },
              {
                component: "@ambisafe/fe-statements/{IfEqualToSome}",
                props: {
                  if: "${type}",
                  to: ["CRYPTO", "PROJECT", "BTC"]
                },
                children: [
                  {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      fontSize: "lg",
                      color: "primary.main"
                    },
                    children: {
                      component: "@ambisafe/ui-truncate",
                      props: {
                        lines: 1
                      },
                      children: "${withdrawToAddress}"
                    }
                  },
                  {
                    component: "@ambisafe/ui-layout/{GridCol}",
                    props: {
                      gutter: 0,
                      width: {
                        mobile: "100%",
                        mobileLandscape: "auto"
                      }
                    },
                    children: {
                      component: "@ambisafe/ui-layout/{Spacing}",
                      children: {
                        component: "@ambisafe/ui-form/{Field}",
                        props: {
                          name: "code",
                          type: "password",
                          label: "form.email_code",
                          placeholder: "",
                          validate: ["required", ["length", 8]]
                        }
                      }
                    }
                  }
                ]
              },
              {
                component: "@ambisafe/fe-statements/{IfEqualToSome}",
                props: {
                  if: "${type}",
                  to: ["FIAT", "CRYPTOFIAT"]
                },
                children: {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  props: {
                    fontSize: "lg",
                    color: "primary.main"
                  },
                  children: {
                    component: "@ambisafe/ui-truncate",
                    props: {
                      lines: 1
                    },
                    children: "${selectedBankInfo.title}"
                  }
                }
              }
            ]
          },
          ActionButtons
        ]
      },
      {
        component: "@ambisafe/fe-statements/{IfTrue}",
        props: {
          if: "${submitErrors}"
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pt: "xxs"
          },
          children: {
            component: "@ambisafe/ui-form/{SubmitError}"
          }
        }
      }
    ]
  }
];
