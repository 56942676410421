/* eslint-disable no-template-curly-in-string */
export const ProjectHeader = {
  component: "@ambisafe/ui-background",
  props: {
    backgroundImage: "url('${project.coverImage}')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  children: {
    component: "@ambisafe/ui-layout/{Container}",
    children: {
      component: "@ambisafe/ui-layout/{GridContainer}",
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        props: {
          alignItems: "flex-end",
          height: "600px"
        },
        children: [
          {
            component: "@ambisafe/ui-layout/{GridCol}",
            props: {
              span: {
                mobile: 12,
                tablet: 7
              }
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                position: "relative",
                borderRadius: "5px",
                overflow: "hidden",
                display: "inline-flex",
                margin: {
                  mobile: "0 0 50px 0",
                  tablet: "0 0 38px 30px"
                }
              },
              children: {
                component: "@ambisafe/ui-text/{Heading1}",
                props: {
                  noBottomGutter: true,
                  color: "black600"
                },
                children: {
                  component: "@ambisafe/ui-background",
                  props: {
                    backgroundColor: "white100"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      py: "xxs",
                      px: "xs"
                    },
                    children: {
                      component: "@ambisafe/ui-text/{Medium}",
                      children: "${project.title}"
                    }
                  }
                }
              }
            }
          }
        ]
      }
    }
  }
};
