/* eslint-disable no-template-curly-in-string */

export const Fiat = {
  component: "@ambisafe/fe-statements/{IfEqualToSome}",
  props: {
    if: "${type}",
    to: ["FIAT", "CRYPTOFIAT"]
  },
  children: [
    {
      component: "@ambisafe/ui-divider",
      props: {
        opacity: 0.2
      }
    },
    {
      component: "@ambisafe/fe-statements/{IfTrue}",
      props: {
        if: "${bankInfos.length}"
      },
      children: {
        component: "@ambisafe/ui-form/{Form}",
        props: {
          onSubmit: "${handleFIATWithdrawalSubmit}",
          initialValues: "${fiatWithdrawalInitialValues}",
          disabled: "${selectedForWithdrawServerCode}"
        },
        children: [
          {
            component: "@ambisafe/ui-layout/{GridRow}",
            props: {},
            children: [
              {
                component: "@ambisafe/ui-layout/{GridCol}",
                props: {
                  span: {
                    mobile: 12,
                    tablet: 12,
                    tabletLandscape: 7,
                    laptop: 6
                  }
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    mb: "xs"
                  },
                  children: {
                    component: "@ambisafe/ui-form/{Field}",
                    props: {
                      name: "bankInfoId",
                      type: "select",
                      options: "${bankInfos}",
                      label: "form.bank",
                      placeholder: "",
                      validate: ["required"]
                    }
                  }
                }
              },
              {
                component: "@ambisafe/ui-layout/{GridCol}",
                props: {
                  span: {
                    mobile: 12,
                    tablet: 6,
                    tabletLandscape: 2,
                    laptop: 3
                  }
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    mb: "xs"
                  },
                  children: {
                    component: "@ambisafe/ui-form/{Field}",
                    props: {
                      name: "amount",
                      type: "text",
                      label: "form.amount",
                      placeholder: "",
                      validate: ["required"],
                      onChange: "${handleWithdrawAmountChange}"
                    }
                  }
                }
              },
              {
                component: "@ambisafe/ui-layout/{GridCol}",
                props: {
                  span: {
                    mobile: 12,
                    tablet: 6,
                    tabletLandscape: 3,
                    laptop: 3
                  }
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    mb: "xs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Paragraph}",
                      props: {
                        fontSize: "sm"
                      },
                      children: [
                        {
                          component: "@ambisafe/locale/{FormattedMessage}",
                          props: {
                            id: "total"
                          }
                        },
                        {
                          component: "span",
                          children: " ("
                        },
                        {
                          component: "@ambisafe/locale/{FormattedMessage}",
                          props: {
                            id: "incl_fee"
                          }
                        },
                        {
                          component: "span",
                          children: " "
                        },
                        {
                          component: "span",
                          children: "${withdrawFee}"
                        },
                        {
                          component: "span",
                          children: "${displaySymbol}"
                        },
                        {
                          component: "span",
                          children: ")"
                        }
                      ]
                    },
                    {
                      component: "@ambisafe/ui-text/{Paragraph}",
                      props: {
                        noBottomGutter: true,
                        lineHeight: 1,
                        fontSize: "lg"
                      },
                      children: "${totalWithdrawAmount}"
                    },
                    {
                      component: "@ambisafe/ui-text/{Paragraph}",
                      props: {
                        noBottomGutter: true,
                        color: "text.secondary",
                        lineHeight: 1,
                        fontSize: "sm"
                      },
                      children: [
                        {
                          component: "span",
                          children: "≈"
                        },
                        {
                          component: "@ambisafe/locale/{FormattedNumber}",
                          props: {
                            value: "${totalWithdrawPriceInUSD}"
                          }
                        },
                        {
                          component: "span",
                          children: " "
                        },
                        {
                          component: "@ambisafe/locale/{FormattedMessage}",
                          props: {
                            id: "usd"
                          }
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [
              {
                component: "@ambisafe/ui-layout/{GridCol}",
                props: {
                  flex: {
                    mobile: "auto",
                    tablet: 1
                  },
                  width: {
                    mobile: "100%",
                    tablet: "auto"
                  }
                },
                children: {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  props: {
                    color: "text.secondary"
                  },
                  children: {
                    component: "@ambisafe/locale/{FormattedMessage}",
                    props: {
                      id: "withdrawal_fees_disclaimer"
                    }
                  }
                }
              },
              {
                component: "@ambisafe/ui-layout/{GridCol}",
                props: {
                  alignRight: true
                },
                children: {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  children: [
                    {
                      component: "@ambisafe/ui-layout/{GridCol}",
                      children: {
                        component: "@ambisafe/react-router-dom/{Link}",
                        props: {
                          to: "${match.url}"
                        },
                        children: {
                          component: "@ambisafe/ui-button",
                          props: {
                            inverse: true,
                            width: "100%"
                          },
                          children: {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              py: "xxs",
                              px: "xs"
                            },
                            children: {
                              component: "@ambisafe/ui-text/{Paragraph}",
                              props: {
                                noBottomGutter: true,
                                textAlign: "center",
                                textTransform: "uppercase"
                              },
                              children: {
                                component: "react-intl/{FormattedMessage}",
                                props: {
                                  id: "cancel"
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    {
                      component: "@ambisafe/ui-layout/{GridCol}",
                      children: {
                        component: "@ambisafe/ui-button",
                        props: {
                          type: "submit",
                          width: "100%"
                        },
                        children: {
                          component: "@ambisafe/ui-layout/{Spacing}",
                          props: {
                            py: "xxs",
                            px: "xs"
                          },
                          children: {
                            component: "@ambisafe/ui-text/{Paragraph}",
                            props: {
                              noBottomGutter: true,
                              textAlign: "center",
                              textTransform: "uppercase"
                            },
                            children: {
                              component: "react-intl/{FormattedMessage}",
                              props: {
                                id: "confirm"
                              }
                            }
                          }
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          {
            component: "@ambisafe/fe-statements/{IfTrue}",
            props: {
              if: "${submitErrors}"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                pt: "xxs"
              },
              children: {
                component: "@ambisafe/ui-form/{SubmitError}"
              }
            }
          }
        ]
      }
    },
    {
      component: "@ambisafe/fe-statements/{IfFalse}",
      props: {
        if: "${bankInfos.length}"
      },
      children: {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          noBottomGutter: true
        },
        children: [
          {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: "no_bank_infos_found_add_one"
            }
          },
          {
            component: "span",
            children: " "
          },
          {
            component: "@ambisafe/react-router-dom/{Link}",
            props: {
              to: "/account/bank-info?newBankInfo=true"
            },
            children: {
              component: "@ambisafe/locale/{FormattedMessage}",
              props: {
                id: "add_one_bank_info_link"
              }
            }
          }
        ]
      }
    }
  ]
};
