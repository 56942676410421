/* eslint-disable no-template-curly-in-string */
export const LocaleSwitcher = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    width: "125px",
    margin: "0"
  },
  children: {
    component: "@orderbook/fe-locale-switcher",
    props: {
      languages: "${languages}"
    },
    children: {
      component: "@ambisafe/ui-form/{Input}",
      props: {
        input: {
          type: "select",
          value: "${locale}",
          onChange: "${switchLocaleTo}"
        },
        options: "${languagesOptions}",
        borderRadius: "0px",
        isClearable: false,
        isSearchable: false,
        colors: {
          default: {
            color: "text.primary",
            placeholderColor: "text.primary",
            borderColor: "transparent",
            backgroundColor: "elements.headerBackground"
          },
          disabled: {
            color: "text.primary",
            placeholderColor: "text.primary",
            borderColor: "transparent",
            backgroundColor: "elements.headerBackground"
          },
          hovered: {
            color: "text.primary",
            placeholderColor: "text.primary",
            borderColor: "transparent",
            backgroundColor: "elements.headerBackground"
          },
          focused: {
            color: "text.primary",
            placeholderColor: "text.primary",
            borderColor: "transparent",
            backgroundColor: "elements.headerBackground"
          }
        }
      }
    }
  }
};
