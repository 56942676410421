/* eslint-disable no-template-curly-in-string */
import { AddressFields, Field } from "../FieldSets";
import { DocumentList } from "../DocumentList";
import { Status } from "./Status";
import { PersonalData } from "./PersonalData";

export const PersonalKYC = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "/account/verification",
    exact: true
  },
  children: {
    component: "@ambisafe/react-router-dom/{RedirectStatement}",
    props: {
      statement: "${isCorporateKYC}",
      to: "${match.url}/corporate"
    },
    children: {
      component: "@ambisafe/ui-form/{Form}",
      props: {
        onSubmit: "${updateIdentitySubmit}",
        initialValues: "${identityInitialValues}"
      },
      children: [
        Status,
        ...PersonalData,
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pt: "xs"
          },
          children: {
            component: "@ambisafe/ui-text/{Heading4}",
            props: {
              noBottomGutter: true,
              color: "black600"
            },
            children: {
              component: "@ambisafe/locale/{FormattedMessage}",
              props: {
                id: "form.address"
              }
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pt: "xxs",
            pb: "xs"
          },
          children: {
            component: "@ambisafe/ui-divider",
            props: {
              noGutter: true,
              opacity: 0.3
            }
          }
        },
        AddressFields,
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pt: "xs"
          },
          children: {
            component: "@ambisafe/ui-text/{Heading4}",
            props: {
              noBottomGutter: true,
              color: "black600"
            },
            children: {
              component: "@ambisafe/locale/{FormattedMessage}",
              props: {
                id: "form.identification_documents"
              }
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pt: "xxs",
            pb: "xs"
          },
          children: {
            component: "@ambisafe/ui-divider",
            props: {
              noGutter: true,
              opacity: 0.3
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "countryOfIssuance",
                type: "select",
                label: "form.country_of_issuance",
                placeholder: "",
                validate: ["required"],
                options: "${countries}",
                onChange: "${onCountryOfIssuanceChange}",
              },
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "identificationNumber",
                type: "text",
                label: "form.identification_number",
                placeholder: "",
                validate: ["required"],
              },
            }),
          ]
        },
        {
          component: "@ambisafe/fe-statements/{IfEqualToSome}",
          props: {
            if: "${kycStatus}",
            to: ["EMPTY", "FAILED"]
          },
          children: [
            {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                py: "md"
              },
              children: [
                {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  children: {
                    component: "@ambisafe/ui-text/{Medium}",
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "upload_documents_description"
                      }
                    }
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Container}",
                  props: {
                    borderColor: "warning.main",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderRadius: "5px"
                  },
                  children: {
                    component: "@ambisafe/ui-background",
                    props: {
                      backgroundColor: "warning.light"
                    },
                    children: {
                      component: "@ambisafe/ui-layout/{Spacing}",
                      props: {
                        pr: "xs",
                        py: "xs"
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Ul}",
                        children: [
                          {
                            component: "@ambisafe/ui-text/{Li}",
                            children: {
                              component: "react-intl/{FormattedMessage}",
                              props: {
                                id: "document_requirements.0"
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-text/{Li}",
                            children: {
                              component: "react-intl/{FormattedMessage}",
                              props: {
                                id: "document_requirements.1"
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-text/{Li}",
                            children: {
                              component: "react-intl/{FormattedMessage}",
                              props: {
                                id: "document_requirements.2"
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-text/{Li}",
                            children: {
                              component: "react-intl/{FormattedMessage}",
                              props: {
                                id: "document_requirements.3"
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-text/{Li}",
                            children: {
                              component: "react-intl/{FormattedMessage}",
                              props: {
                                id: "document_requirements.4"
                              }
                            }
                          }
                        ]
                      }
                    }
                  }
                }
              ]
            },
            {
              component: "@ambisafe/ui-layout/{GridRow}",
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  props: {
                    span: {
                      mobile: 12,
                      tablet: 6
                    }
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      pb: "xs"
                    },
                    children: {
                      component: "@ambisafe/ui-form/{Field}",
                      props: {
                        name: "documentType",
                        type: "select",
                        label: "form.document_type",
                        placeholder: "",
                        validate: ["required"],
                        options: "${personalDocumentTypesList}"
                      }
                    }
                  }
                }
              ]
            },
            {
              component: "@ambisafe/ui-layout/{GridRow}",
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  props: {
                    span: {
                      mobile: 12
                    }
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      pb: "xs"
                    },
                    children: {
                      component: "@ambisafe/ui-form/{Field}",
                      props: {
                        name: "documents",
                        type: "file",
                        maxSize: 52428800,
                        accept: ".png, .jpg, .jpeg, .pdf, .doc, .docx",
                        placeholder: "",
                        validate: ["required"]
                      }
                    }
                  }
                }
              ]
            },
          ]
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            mt: "xxs",
            mb: "sm"
          },
          children: [
            {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                inline: true,
                noBottomGutter: true,
                textTransform: "uppercase"
              },
              children: [
                {
                  component: "@ambisafe/locale/{FormattedMessage}",
                  props: {
                    id: "kyc.status"
                  }
                },
                {
                  component: "span",
                  children: ":"
                }
              ]
            },
            {
              component: "span",
              children: " "
            },
            {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                inline: true,
                noBottomGutter: true,
                textTransform: "uppercase",
                color: "${kycStatusColor}"
              },
              children: {
                component: "@ambisafe/locale/{FormattedMessage}",
                props: {
                  id: "kyc.${kycStatus}"
                }
              }
            }
          ]
        },
        {
          component: "@ambisafe/fe-statements/{IfTrue}",
          props: {
            if: "${submitErrors}"
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "xxs"
            },
            children: {
              component: "@ambisafe/ui-form/{SubmitError}"
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pb: "xxs"
          },
          children: {
            component: "@ambisafe/ui-button",
            props: {
              type: "submit",
              loading: "${loading}",
              width: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                py: "xxs",
                px: "xs"
              },
              children: {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  noBottomGutter: true,
                  textAlign: "center",
                  textTransform: "uppercase"
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "save_address"
                  }
                }
              }
            }
          }
        }
      ]
    }
  }
};
