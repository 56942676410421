/* eslint-disable no-template-curly-in-string */
import { Explanation } from "./Explanation";
import { SignForm } from "./SignForm";
import { Document } from "./Document";
import { SecondaryMarketHeader } from "../SecondaryMarketHeader";

export const SignDocuments = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.url}/documents"
  },
  children: {
    component: "@ambisafe/react-router-dom/{RedirectFalsyStatement}",
    props: {
      statement: "${project.areThereDocumentsToSign}",
      to: "/projects/${project.link}"
    },
    children: {
      component: "@orderbook/fe-project-sign-documents",
      props: {
        projectId: "${project.id}",
        projectLink: "${project.link}",
        documentsToSign: "${project.documentsToSign}",
      },
      children: [
        SecondaryMarketHeader,
        Explanation,
        SignForm,
        Document()
      ]
    }
  }
};
