/* eslint-disable no-template-curly-in-string */

export const StartDate = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "50%",
    alignItems: "left"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true,
        opacity: 0.6
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: "Open Date"
      }
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true
      },
      children: {
        component: "@ambisafe/ui-text/{Light}",
        children: {
          component: "react-intl/{FormattedDate}",
          props: {
            year: "numeric",
            month: "short",
            day: "2-digit",
            value: "${startDate}"
          }
        }
      }
    }
  ]
};
