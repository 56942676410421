/* eslint-disable no-template-curly-in-string */
import { FollowUs } from "./FollowUs";
import { Legend } from "./Legend";


const EmptySpace = height => ({
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    height: height,
    span: {
      mobile: 12,
      tablet: 1,
      laptop: 2
    }
  }
});

export const Footer = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    width: "100%",
    boxShadow: "0 -3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
  },
  children: {
    component: "@ambisafe/ui-background",
    props: {
      backgroundColor: "elements.footerBackground",
      height: "100%"
    },
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        py: "lg"
      },
      children: {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          fluid: false,
          maxWidth: "1232px",
          width: {
            mobile: "85%"
          }
        },
        children: [
          {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [Legend, EmptySpace("50px"), FollowUs]
          }
        ]
      }
    }
  }
};
