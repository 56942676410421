/* eslint-disable no-template-curly-in-string */
export const ProcessingFiatAsset = {
  component: "@ambisafe/fe-statements/{IfEqualToSome}",
  props: {
    if: "${type}",
    to: ["FIAT", "CRYPTOFIAT"]
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        mt: "xxl"
      },
      children: {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          noBottomGutter: true
        },
        children: {
          component: "react-intl/{FormattedMessage}",
          props: {
            id: "wire_transfer_description"
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        mt: "sm"
      },
      children: {
        component:
          "@orderbook/fe-project-show-fiat-deposit-document",
        props: {
          projectId: "${project.id}",
          assetId: "${assetId}"
        },
        children: [
          {
            component: "@ambisafe/fe-statements/{IfTrue}",
            props: {
              if: "${fiatDepositDocument}"
            },
            children: {
              component: "@ambisafe/ui-fullscreen-modal",
              props: {
                onClose: "${onDocumentClose}"
              },
              children: {
                component: "@ambisafe/ui-show-base64-file",
                props: {
                  fileBase64: "${fiatDepositDocument}"
                }
              }
            }
          },
          {
            component: "@ambisafe/ui-form/{Form}",
            props: {
              onSubmit: "${handleFiatDepositSubmit}"
            },
            children: [
              {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  mb: "xs"
                },
                children: {
                  component: "@ambisafe/ui-form/{Field}",
                  props: {
                    name: "amount",
                    type: "text",
                    label: "form.enter_fiat_deposit_amount",
                    placeholder: "",
                    validate: ["required"]
                  }
                }
              },
              {
                component: "@ambisafe/fe-statements/{IfTrue}",
                props: {
                  if: "${submitErrors}"
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pb: "xxs"
                  },
                  children: {
                    component: "@ambisafe/ui-form/{SubmitError}"
                  }
                }
              },
              {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  pb: "xxs"
                },
                children: {
                  component: "@ambisafe/ui-button",
                  props: {
                    type: "submit",
                    width: "100%"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      py: "xxs",
                      px: "xs"
                    },
                    children: {
                      component: "@ambisafe/ui-text/{Paragraph}",
                      props: {
                        noBottomGutter: true,
                        textAlign: "center",
                        textTransform: "uppercase"
                      },
                      children: {
                        component:
                          "react-intl/{FormattedMessage}",
                        props: {
                          id: "get_fiat_deposit_payment_details"
                        }
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              mt: "xs"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                borderColor: "warning.main",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "5px"
              },
              children: {
                component: "@ambisafe/ui-background",
                props: {
                  backgroundColor: "warning.light"
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    p: "xs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Paragraph}",
                      props: {
                        color: "warning.main"
                      },
                      children: {
                        component:
                          "@ambisafe/locale/{FormattedMessage}",
                        props: {
                          id: "deposit_fiat_transaction_id_disclaimer"
                        }
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        color: "warning.main",
                        noBottomGutter: true,
                        textAlign: "center"
                      },
                      children: [
                        {
                          component: "@ambisafe/ui-text/{Normal}",
                          children: {
                            component:
                              "@ambisafe/locale/{FormattedMessage}",
                            props: {
                              id: "your_reference_id"
                            }
                          }
                        },
                        {
                          component: "span",
                          children: ": "
                        },
                        {
                          component: "br"
                        },
                        {
                          component: "span",
                          children: "${project.invoiceContract.wireTransferCode}"
                        }
                      ]
                    }
                  ]
                }
              }
            }
          }
        ]
      }
    }
  ]
};
