/* eslint-disable no-template-curly-in-string */

export const Balance = {
  component: "@ambisafe/ui-layout/{GridCol}",
  children: [
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        noBottomGutter: true,
        fontSize: "lg"
      },
      children: {
        component: "@ambisafe/locale/{FormattedNumber}",
        props: {
          value: "${balance}"
        }
      }
    },
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        noBottomGutter: true,
        color: "text.secondary",
        fontSize: "sm"
      },
      children: [
        {
          component: "span",
          children: "≈"
        },
        {
          component: "@ambisafe/locale/{FormattedNumber}",
          props: {
            value: "${totalPriceInUSD}"
          }
        },
        {
          component: "span",
          children: " "
        },
        {
          component: "@ambisafe/locale/{FormattedMessage}",
          props: {
            id: "usd"
          }
        }
      ]
    }
  ]
};
