export const Routes = [
  {
    account: [
      {
        verification: ["corporate", ""]
      },
      "investor-accreditation",
      "assets",
      {
        settings: ["change-phone", ""]
      },
      "bank-info",
      {
        "actions-history": [
          {
            "view": [
              {
                ":type": [":documentId"],
              }
            ],
          },
          "",
        ],
      },
      ""
    ]
  },
  {
    auth: ["login", "register", "phone-confirmation", "phone-number", "email-confirmation"]
  },
  {
    projects: [
      {
        ":projectLink": [
          {
            contribute: [
              {
                method: [
                  {
                    ":displaySymbol": [
                      {
                        "view": [":documentId"],
                      },
                      "congratulations",
                      "",
                    ]
                  },
                  ""
                ]
              }
            ]
          },
          {
            documents: [
              {
                view: [":documentId"]
              },
              ""
            ]
          },
          {
            secondary: [
              {
                ":operation": ["confirmation", "congratulations", ""]
              }
            ]
          },
          ""
        ]
      }
    ]
  },
  {
    recovery: [
      "request",
      "discard",
      "change-password",
      "phone-code",
      "confirm-new-password",
      "success",
      "confirm-new-phone",
      {
        "request-success": [":email"]
      }
    ]
  },
  "trade",
  "listing-request",
  "session-expired",
  "restricted-location"
];
