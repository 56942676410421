/* eslint-disable no-template-curly-in-string */

export const TwoFA = {
  component: "@ambisafe/fe-statements/{IfEqualTo}",
  props: {
    if: "${selectedForWithdrawTwoFAType}",
    to: "EMAIL"
  },
  children: [
    {
      component: "@ambisafe/ui-divider",
      props: {
        opacity: 0.2
      }
    },
    {
      component: "@ambisafe/ui-form/{Form}",
      props: {
        onSubmit: "${handleWithdrawalTwoFASubmit}"
      },
      children: [
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              children: [
                {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  children: [
                    {
                      component: "@ambisafe/locale/{FormattedMessage}",
                      props: {
                        id: "you_are_going_to_withdraw"
                      }
                    },
                    {
                      component: "span",
                      children: ": "
                    }
                  ]
                },
                {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  props: {
                    fontSize: "lg",
                    color: "primary.main",
                    inline: true
                  },
                  children: [
                    {
                      component: "span",
                      children: "${amountToWithdraw}"
                    },
                    {
                      component: "span",
                      children: " "
                    },
                    {
                      component: "span",
                      children: "${displaySymbol}"
                    }
                  ]
                }
              ]
            },
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                flex: 1,
                maxWidth: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-text/{Paragraph}",
                  props: {},
                  children: [
                    {
                      component: "@ambisafe/fe-statements/{IfEqualToSome}",
                      props: {
                        if: "${type}",
                        to: ["CRYPTO", "PROJECT", "BTC", "CREDIT"]
                      },
                      children: {
                        component: "@ambisafe/locale/{FormattedMessage}",
                        props: {
                          id: "recipient_address"
                        }
                      }
                    },
                    {
                      component: "@ambisafe/fe-statements/{IfEqualToSome}",
                      props: {
                        if: "${type}",
                        to: ["FIAT", "CRYPTOFIAT"]
                      },
                      children: {
                        component: "@ambisafe/locale/{FormattedMessage}",
                        props: {
                          id: "bank"
                        }
                      }
                    },
                    {
                      component: "span",
                      children: ": "
                    }
                  ]
                },
                {
                  component: "@ambisafe/fe-statements/{IfEqualToSome}",
                  props: {
                    if: "${type}",
                    to: ["CRYPTO", "PROJECT", "BTC"]
                  },
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      fontSize: "lg",
                      color: "primary.main"
                    },
                    children: {
                      component: "@ambisafe/ui-truncate",
                      props: {
                        lines: 1
                      },
                      children: "${withdrawToAddress}"
                    }
                  }
                },
                {
                  component: "@ambisafe/fe-statements/{IfEqualToSome}",
                  props: {
                    if: "${type}",
                    to: ["FIAT", "CRYPTOFIAT"]
                  },
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      fontSize: "lg",
                      color: "primary.main"
                    },
                    children: {
                      component: "@ambisafe/ui-truncate",
                      props: {
                        lines: 1
                      },
                      children: "${selectedBankInfo.title}"
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          props: {},
          children: [
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                width: {
                  mobile: "100%",
                  mobileLandscape: "auto"
                }
              },
              children: {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  mb: "xs"
                },
                children: {
                  component: "@ambisafe/ui-form/{Field}",
                  props: {
                    name: "code",
                    type: "password",
                    label: "form.email_code",
                    placeholder: "",
                    validate: ["required", ["length", 8]]
                  }
                }
              }
            },
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                alignRight: true
              },
              children: {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  mt: "xs"
                },
                children: {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  children: [
                    {
                      component: "@ambisafe/ui-layout/{GridCol}",
                      children: {
                        component: "@ambisafe/react-router-dom/{Link}",
                        props: {
                          to: "${match.url}?withdraw=${assetId}"
                        },
                        children: {
                          component: "@ambisafe/ui-button",
                          props: {
                            inverse: true,
                            width: "100%"
                          },
                          children: {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              py: "xxs",
                              px: "xs"
                            },
                            children: {
                              component: "@ambisafe/ui-text/{Paragraph}",
                              props: {
                                noBottomGutter: true,
                                textAlign: "center",
                                textTransform: "uppercase"
                              },
                              children: {
                                component: "react-intl/{FormattedMessage}",
                                props: {
                                  id: "cancel"
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    {
                      component: "@ambisafe/ui-layout/{GridCol}",
                      children: {
                        component: "@ambisafe/ui-button",
                        props: {
                          type: "submit",
                          width: "100%"
                        },
                        children: {
                          component: "@ambisafe/ui-layout/{Spacing}",
                          props: {
                            py: "xxs",
                            px: "xs"
                          },
                          children: {
                            component: "@ambisafe/ui-text/{Paragraph}",
                            props: {
                              noBottomGutter: true,
                              textAlign: "center",
                              textTransform: "uppercase"
                            },
                            children: {
                              component: "react-intl/{FormattedMessage}",
                              props: {
                                id: "confirm"
                              }
                            }
                          }
                        }
                      }
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          component: "@ambisafe/fe-statements/{IfTrue}",
          props: {
            if: "${submitErrors}"
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pt: "xxs"
            },
            children: {
              component: "@ambisafe/ui-form/{SubmitError}"
            }
          }
        }
      ]
    }
  ]
};
