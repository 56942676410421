/* eslint-disable no-template-curly-in-string */
import { ProjectHeader } from "./ProjectHeader";
import { ProjectAbout, ProjectDocuments, ProjectInfo, ProjectVideo, ProjectLearnMore } from "./components";
import { PurchasePanel } from "./PurchasePanel";
export const ProjectDetails = {
  component: "@orderbook/fe-project",
  props: {
    projectLink: "${match.params.projectLink}",
    kycRequired: "${investBank.kycRequired}",
    investBank: "${investBank}"
  },
  children: [
    ProjectHeader,
    {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        fluid: false
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          py: "xxl"
        },
        children: [
          {
            component: "@ambisafe/ui-layout/{GridContainer}",
            children: {
              component: "@ambisafe/ui-layout/{GridRow}",
              props: {
                flexDirection: {
                  mobile: "column",
                  tablet: "row"
                }
              },
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  props: {
                    span: {
                      mobile: 12,
                      tablet: 7
                    }
                  },
                  children: [ProjectVideo, ProjectAbout]
                },
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  props: {
                    span: {
                      mobile: 12,
                      tablet: 5
                    }
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-layout/{Spacing}",
                      children: [ProjectInfo]
                    },
                    PurchasePanel
                    // {
                    //   component: "@ambisafe/fe-statements/{IfTrue}",
                    //   props: {
                    //     if: "${project.projectPair}"
                    //   },
                    //   children: [PurchasePanel]
                    // },
                    // {
                    //   component: "@ambisafe/fe-statements/{IfFalse}",
                    //   props: {
                    //     if: "${project.projectPair}"
                    //   },
                    //   children: {
                    //       component: "@ambisafe/fe-statements/{IfgtElse}",
                    //       props: {
                    //         if: "${project.startDate}",
                    //         else: "${project.currentDate}"
                    //       },
                    //       children: [PurchasePanel]
                    //     }
                    // },
                    // {
                    //   component: "@ambisafe/fe-statements/{IfFalse}",
                    //   props: {
                    //     if: "${project.projectPair}"
                    //   },
                    //   children: {
                    //     component: "@ambisafe/fe-statements/{IfgtElse}",
                    //     props: {
                    //       if: "${project.currentDate}",
                    //       else: "${project.startDate}"
                    //     },
                    //     children: {
                    //       component: "@ambisafe/fe-statements/{IfgtElse}",
                    //       props: {
                    //         if: "${project.endDate}",
                    //         else: "${project.currentDate}"
                    //       },
                    //       children: [PurchasePanel]
                    //     }
                    //   }
                    // }
                  ]
                }
              ]
            }
          },
          ProjectLearnMore,
          ProjectDocuments
        ]
      }
    }
  ]
};
