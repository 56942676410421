/* eslint-disable no-template-curly-in-string */
export const MobileMenu = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.url}",
    exact: true,
  },
  children: {
    component: "@ambisafe/ui-layout/{GridCol}",
    props: {
      hide: {
        tablet: true,
      },
      width: {
        mobile: "100%",
        tablet: "auto",
      },
    },
    children: {
      component: "@ambisafe/ui-menu",
      props: {
        states: {
          default: {
            color: "text.primary",
            backgroundColor: "transparent",
            opacity: 0.6,
            borderRight: "2px solid transparent",
          },
          hovered: {
            color: "text.primary",
            backgroundColor: "transparent",
            opacity: 1,
            borderRight: "2px solid #1c1e22",
          },
          focused: {
            color: "text.primary",
            backgroundColor: "transparent",
            opacity: 1,
            borderRight: "2px solid #1c1e22",
          },
          disabled: {
            color: "text.disabled",
            backgroundColor: "transparent",
            opacity: 0.4,
            borderRight: "2px solid transparent",
          },
        },
      },
      children: [
        {
          component: "@ambisafe/react-router-dom/{NavLink}",
          props: {
            to: "${match.url}/verification",
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: "xxs",
            },
            children: {
              component: "@ambisafe/ui-layout/{GridRow}",
              props: {
                justifyContent: "space-between",
                alignItems: "center",
              },
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      noBottomGutter: true,
                      fontSize: "lg",
                      color: "inherit",
                    },
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "identity_verification",
                      },
                    },
                  },
                },
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-icons/{AngleRight}",
                  },
                },
              ],
            },
          },
        },
        {
          component: "@ambisafe/ui-divider",
          props: {
            opacity: 0.3,
          },
        },
        {
          component: "@ambisafe/react-router-dom/{NavLink}",
          props: {
            to: "${match.url}/investor-accreditation",
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: "xxs",
            },
            children: {
              component: "@ambisafe/ui-layout/{GridRow}",
              props: {
                justifyContent: "space-between",
                alignItems: "center",
              },
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      noBottomGutter: true,
                      fontSize: "lg",
                      color: "inherit",
                    },
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "investor_accreditation",
                      },
                    },
                  },
                },
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-icons/{AngleRight}",
                  },
                },
              ],
            },
          },
        },
        {
          component: "@ambisafe/ui-divider",
          props: {
            opacity: 0.3,
          },
        },
        {
          component: "@ambisafe/react-router-dom/{NavLink}",
          props: {
            to: "${match.url}/assets",
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: "xxs",
            },
            children: {
              component: "@ambisafe/ui-layout/{GridRow}",
              props: {
                justifyContent: "space-between",
                alignItems: "center",
              },
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      noBottomGutter: true,
                      fontSize: "lg",
                      color: "inherit",
                    },
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "assets",
                      },
                    },
                  },
                },
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-icons/{AngleRight}",
                  },
                },
              ],
            },
          },
        },
        {
          component: "@ambisafe/ui-divider",
          props: {
            opacity: 0.3,
          },
        },
        {
          component: "@ambisafe/react-router-dom/{NavLink}",
          props: {
            to: "${match.url}/actions-history",
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: "xxs",
            },
            children: {
              component: "@ambisafe/ui-layout/{GridRow}",
              props: {
                justifyContent: "space-between",
                alignItems: "center",
              },
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      noBottomGutter: true,
                      fontSize: "lg",
                      color: "inherit",
                    },
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "actionsHistory",
                      },
                    },
                  },
                },
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-icons/{AngleRight}",
                  },
                },
              ],
            },
          },
        },
        {
          component: "@ambisafe/ui-divider",
          props: {
            opacity: 0.3,
          },
        },
        {
          component: "@ambisafe/react-router-dom/{NavLink}",
          props: {
            to: "${match.url}/bank-info",
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: "xxs",
            },
            children: {
              component: "@ambisafe/ui-layout/{GridRow}",
              props: {
                justifyContent: "space-between",
                alignItems: "center",
              },
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      noBottomGutter: true,
                      fontSize: "lg",
                      color: "inherit",
                    },
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "bank_info",
                      },
                    },
                  },
                },
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-icons/{AngleRight}",
                  },
                },
              ],
            },
          },
        },
        // {
        //   component: "@ambisafe/ui-divider",
        //   props: {
        //     opacity: 0.3
        //   }
        // },
        // {
        //   component: "@ambisafe/react-router-dom/{NavLink}",
        //   props: {
        //     to: "${match.url}/settings"
        //   },
        //   children: {
        //     component: "@ambisafe/ui-layout/{Spacing}",
        //     props: {
        //       p: "xs"
        //     },
        //     children: {
        //       component: "@ambisafe/ui-text/{Paragraph}",
        //       props: {
        //         noBottomGutter: true,
        //         textAlign: "right",
        //         color: "inherit"
        //       },
        //       children: {
        //         component: "react-intl/{FormattedMessage}",
        //         props: {
        //           id: "settings"
        //         }
        //       }
        //     }
        //   }
        // },
        {
          component: "@ambisafe/ui-divider",
          props: {
            opacity: 0.3,
          },
        },
        {
          component: "@ambisafe/react-router-dom/{NavLink}",
          props: {
            exact: true,
            to: "/",
            onClick: "${logout}",
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: "xxs",
            },
            children: {
              component: "@ambisafe/ui-layout/{GridRow}",
              props: {
                justifyContent: "space-between",
                alignItems: "center",
              },
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      noBottomGutter: true,
                      fontSize: "lg",
                      color: "inherit",
                    },
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "logout",
                      },
                    },
                  },
                },
              ],
            },
          },
        },
      ],
    },
  },
};
