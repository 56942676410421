/* eslint-disable no-template-curly-in-string */
export const Logo = {
  component: "@ambisafe/ui-layout/{GridCol}",
  children: {
    component: "@ambisafe/react-router-dom/{Link}",
    props: {
      to: "/"
    },
    children: {
      component: "@ambisafe/ui-image",
      props: {
        src: "https://www.ohanae.com/images/icons/ohanae-logo.png",
        width: "140px"
      }
    }
  }
};
