/* eslint-disable no-template-curly-in-string */

export const Document = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${fiatDepositDocument}"
  },
  children: {
    component: "@ambisafe/ui-fullscreen-modal",
    props: {
      onClose: "${onDocumentClose}"
    },
    children: {
      component: "@ambisafe/ui-show-base64-file",
      props: {
        fileBase64: "${fiatDepositDocument}"
      }
    }
  }
};
