/* eslint-disable no-template-curly-in-string */

export const OperationAmount = {
  component: "@ambisafe/ui-text/{Heading5}",
  props: {
    lineHeight: 1,
    noBottomGutter: true
  },
  children: "${baseAmount}"
};
