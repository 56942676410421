/* eslint-disable no-template-curly-in-string */
import { MessageContainer } from "./MessageContainer";
import { ErrorMessage } from "./ResultMessage";

const FormattedMessage = (id, values) => ({
  component: "react-intl/{FormattedMessage}",
  props: {
    id,
    values
  }
});

const WarningIcon = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "top"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pb: {
        mobile: "xxs",
        tablet: "xxs"
      }
    },
    children: {
      component: "@ambisafe/ui-icons/{Warning}",
      props: {
        width: "5em",
        height: "5em"
      }
    }
  }
};

const Button = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    width: "50%",
    p: "xxs"
  },
  children: {
    component: "@ambisafe/ui-button",
    props: {
      width: "100%",
      type: "button",
      disabled: "${paidAction.loading}",
      onClick: "${paidAction.confirmToPayHandler}"
    },
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        p: "xs"
      },
      children: {
        component: "react-intl/{FormattedMessage}",
        props: {
          id: "paid_action.confirm_to_pay"
        }
      }
    }
  }
};

export const ConfirmToPayMessage = {
  component: "@ambisafe/ui-layout/{GridContainer}",
  props: {
    display: "grid",
    gap: "1rem",
    justifyContent: "left",
    width: {
      mobile: "50ch",
      tablet: "80ch"
    }
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        p: {
          mobile: "xs",
          tablet: "xl"
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "inherit"
        },
        children: [
          WarningIcon,
          MessageContainer(
            FormattedMessage("paid_acion.action_description", {
              providerId: "${payableActionData.providerDescription}",
              payableActions: "${payableActionData.actionName}"
            })
          ),
          MessageContainer(
            FormattedMessage("paid_action.fee_for_action", {
              amount: "${payableActionData.amount}",
              assetSymbol: "${payableActionData.assetSymbol}"
            })
          ),
          Button,
          ErrorMessage
        ]
      }
    }
  ]
};
