/* eslint-disable no-template-curly-in-string */

export const OperationType = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    alignRight: true,
    width: {
      mobile: "100%",
      tabletLandscape: "auto"
    }
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        lineHeight: 1,
        noBottomGutter: true
      },
      children: {
        component: "@ambisafe/locale/{FormattedMessage}",
        props: {
          id: "history.${type}"
        }
      }
    }
  ]
};
