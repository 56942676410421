/* eslint-disable no-template-curly-in-string */

export const Field = (children, width = 6) => ({
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    span: {
      mobile: 12,
      tablet: width
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pb: "xs"
    },
    children: children
  }
});

export const Divider = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    py: "sm"
  },
  children: {
    component: "@ambisafe/ui-divider",
    props: {
      noGutter: true,
      opacity: 0.3
    }
  }
};

export const FormErrors = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${submitErrors}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pb: "xxs"
    },
    children: {
      component: "@ambisafe/ui-form/{SubmitError}"
    }
  }
};

export const AddressFields = {
  component: "@ambisafe/ui-layout/{Spacing}",
  children: [
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "building",
              type: "text",
              label: "form.building",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "street",
              type: "text",
              label: "form.street",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "apartment",
              type: "text",
              label: "form.apartment",
              placeholder: ""
            }
          },
          4
        )
      ]
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "city",
              type: "text",
              label: "form.city",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "state",
              type: "text",
              label: "form.state",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "postalCode",
              type: "text",
              label: "form.postcode",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        )
      ]
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "countryOfResidence",
            type: "select",
            label: "form.country_of_residence",
            placeholder: "",
            validate: ["required"],
            options: "${countries}"
          }
        }),
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "taxId",
            type: "number",
            label: "form.tax_id",
            // mask: "999-99-9999",
            // maskChar: "#",
            // placeholder: "###-##-####",
            placeholder: "",
            validate: [
              ["requiredIfValuesEqualTo", "US", "countryOfResidence", "form.tax_id_required_for_us_user"],
              ["minLength", 2],
              ["maxLength", 18]
            ]
          }
        })
      ]
    },
    
  ]
};

export const CorporateAddressFields = {
  component: "@ambisafe/ui-layout/{Spacing}",
  children: [
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "building",
              type: "text",
              label: "form.building",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "street",
              type: "text",
              label: "form.street",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "apartment",
              type: "text",
              label: "form.apartment",
              placeholder: ""
            }
          },
          4
        )
      ]
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "city",
              type: "text",
              label: "form.city",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "state",
              type: "text",
              label: "form.state",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "postalCode",
              type: "text",
              label: "form.postcode",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        )
      ]
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "country",
            type: "select",
            label: "form.country_of_residence",
            placeholder: "",
            validate: ["required"],
            options: "${countries}"
          }
        })
      ]
    }
  ]
};
