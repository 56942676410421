/* eslint-disable no-template-curly-in-string */
export const ExchangeRateInfo = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pb: "16px"
  },
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    children: {
      component: "@ambisafe/ui-layout/{GridCol}",
      props: {
        span: 12,
        display: "flex"
      },
      children: [
        {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            pr: "15px"
          },
          children: {
            component: "@ambisafe/ui-text/{Heading5}",
            props: {
              opacity: 0.6,
              inline: true,
              noBottomGutter: true
            },
            children: {
              component: "@ambisafe/ui-text/{Normal}",
              children: "${SM_exchangeRateMessage}"
            }
          }
        }
      ]
    }
  }
};
