/* eslint-disable no-template-curly-in-string */
import { BuyButton as BuyButtonComponent } from "./BuyButton";
import { EmailCodeForm } from "./EmailCodeForm";

export const EmailCode = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mt: "sm"
  },
  children: EmailCodeForm
};

export const BuyButton = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mt: "sm"
  },
  children: BuyButtonComponent
};
