/* eslint-disable no-template-curly-in-string */
export const AssetIcon = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "64px"
  },
  children: {
    component: "@ambisafe/ui-image",
    props: {
      src: "${project.asset.icon}",
      width: "32px"
    }
  }
};
