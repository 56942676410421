/* eslint-disable no-template-curly-in-string */
export const CoverImage = {
  component: "@ambisafe/ui-layout/{GridCol}",
  children: {
    component: "@ambisafe/ui-background",
    props: {
      backgroundImage: "url('${coverImage}')",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100%",
      height: "200px"
    },
    children: [
      {
        component: "@ambisafe/ui-layout/{GridRow}",
        props: {
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center"
        },
        children: {
          component: "@ambisafe/ui-image",
          props: {
            src: "${projectAsset.icon}",
            height: "60%",
            width: "auto"
          }
        }
      }
    ]
  }
};
