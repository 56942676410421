/* eslint-disable no-template-curly-in-string */
import { Login } from "./Login";
import { EmailConfirmation } from "./EmailConfirmation";
import { PhoneNumber } from "./PhoneNumber";
import { PhoneConfirmation } from "./PhoneConfirmation";

export const Auth = [
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/login"
    },
    children: Login
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/email-confirmation"
    },
    children: EmailConfirmation
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/phone-number"
    },
    children: PhoneNumber
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/phone-confirmation"
    },
    children: PhoneConfirmation
  }
];
