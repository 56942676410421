/* eslint-disable no-template-curly-in-string */

import { Crypto } from "./Crypto";
import { Fiat } from "./Fiat";

export const NonTwoFA = {
  component: "@ambisafe/fe-statements/{IfFalse}",
  props: {
    if: "${selectedForWithdrawTwoFAType}"
  },
  children: [
    Crypto,
    Fiat
  ]
};
