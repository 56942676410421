export const SessionExpired = {
  component: "@orderbook/fe-login",
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    props: {
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      minHeight: "calc(100vh - 54px)"
    },
    children: {
      component: "@ambisafe/ui-layout/{GridCol}",
      props: {
        span: {
          mobile: 12,
          tablet: 6,
          tabletLandscape: 4
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          borderRadius: "30px",
          overflow: "hidden"
        },
        children: {
          component: "@ambisafe/ui-background",
          props: {
            backgroundColor: "elements.cardBackground"
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: "xl"
            },
            children: [
              {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  textAlign: "center"
                },
                children: {
                  component: "@ambisafe/ui-icons/{Clock}"
                }
              },
              {
                component: "@ambisafe/ui-text/{Heading2}",
                props: {
                  color: "primary.main",
                  lineHeight: 1,
                  textAlign: "center"
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "session_expired_title"
                  }
                }
              },
              {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  textAlign: "center",
                  lineHeight: 1,
                  noBottomGutter: true
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "session_expired_description"
                  }
                }
              },
              {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  mt: "xxl"
                },
                children: {
                  component: "@ambisafe/react-router-dom/{Link}",
                  props: {
                    to: "/auth/login"
                  },
                  children: {
                    component: "@ambisafe/ui-button",
                    props: {
                      width: "100%",
                      inverse: true
                    },
                    children: {
                      component: "@ambisafe/ui-layout/{Spacing}",
                      props: {
                        py: "xxs",
                        px: "xs"
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Paragraph}",
                        props: {
                          noBottomGutter: true,
                          textAlign: "center",
                          textTransform: "uppercase"
                        },
                        children: {
                          component: "react-intl/{FormattedMessage}",
                          props: {
                            id: "login"
                          }
                        }
                      }
                    }
                  }
                }
              }
            ]
          }
        }
      }
    }
  }
};
