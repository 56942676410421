/* eslint-disable no-template-curly-in-string */
export const Fee = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    minWidth: "65px"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: {
          component: "@ambisafe/locale/{FormattedMessage}",
          props: {
            id: "fee"
          }
        }
      }
    },
    {
      component: "@ambisafe/fe-statements/{IfgtElse}",
      props: {
        if: "${depositFee}",
        else: "0"
      },
      children: {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          noBottomGutter: true,
          fontSize: "lg"
        },
        children: [
          {
            component: "span",
            children: "${depositFee}"
          },
          {
            component: "span",
            children: " "
          },
          {
            component: "span",
            children: "${displaySymbol}"
          }
        ]
      }
    }
  ]
};
