/* eslint-disable no-template-curly-in-string */
import { MakeDepositMessage } from "./MakeDepositAddress";
import { InvoiceAddress } from "./InvoiceAddress";
import { QrCode } from "./QrCode";
import { Steps } from "./Steps";

export const ProcessingCryptoAsset = {
  component: "@ambisafe/fe-statements/{IfEqualToSome}",
  props: {
    if: "${type}",
    to: ["BTC", "CRYPTO"]
  },
  children: [
    MakeDepositMessage,
    InvoiceAddress,
    QrCode,
    Steps
  ]
};
