/* eslint-disable no-template-curly-in-string */
import { getMessage } from "./messages";

export const UserFeeDialog = props => ({
  component: "@orderbook/fe-dialog",
  props: {
    ...props,
    initialUrl: "${initialUrl}",
    onClose: "${onModalClose}",
    disableScroll: true
  },
  children: {
    component: "@ambisafe/ui-layout/{Container}",
    props: {
      height: "fit-content",
      fluid: false,
      maxWidth: {
        mobile: "90%",
        tablet: "80%",
        laptop: "70%"
      },
      borderRadius: "25px",
      overflow: "hidden",
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.05)"
    },
    children: {
      component: "@ambisafe/ui-background",
      props: {
        backgroundColor: "white100",
        height: "100%"
      },
      children: getMessage()
    }
  }
});
