/* eslint-disable no-template-curly-in-string */
import { Divider, Field } from "../FieldSets";

const AddressFields = {
  component: "@ambisafe/ui-layout/{Spacing}",
  children: [
    Divider,
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "crpCompanyHolders[fieldIndex].country",
            type: "select",
            label: "form.country",
            placeholder: "",
            validate: ["required"],
            options: "${countries}"
          }
        }),
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "crpCompanyHolders[fieldIndex].city",
            type: "text",
            label: "form.city",
            placeholder: "",
            validate: ["required"]
          }
        })
      ]
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "crpCompanyHolders[fieldIndex].state",
            type: "text",
            label: "form.state",
            placeholder: "",
            validate: ["required"]
          }
        }),
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "crpCompanyHolders[fieldIndex].street",
            type: "text",
            label: "form.street",
            placeholder: "",
            validate: ["required"]
          }
        })
      ]
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "crpCompanyHolders[fieldIndex].building",
              type: "text",
              label: "form.building",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "crpCompanyHolders[fieldIndex].apartment",
              type: "text",
              label: "form.apartment",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "crpCompanyHolders[fieldIndex].postalCode",
              type: "text",
              label: "form.postcode",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        )
      ]
    },
    Divider
  ]
};

export const CompanyHolder = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    borderWidth: "20px",
    borderColor: "transparent",
    boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.15)",
    px: "sm"
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        position: "relative"
      },
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        prop: {
          py: "xxs"
        },
        children: [
          {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              mb: "xl"
            },
            children: ""
          },
          {
            component: "@ambisafe/ui-layout/{Container}",
            props: {
              position: "absolute",
              top: "0px",
              left: "100px",
              height: "80px",
              borderColor: "gray",
              boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.15)"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                py: "xs"
              },
              children: {
                component: "@ambisafe/ui-text/{Heading3}",
                children: {
                  component: "@ambisafe/ui-text/{Bold}",
                  children: "${displayIndex}"
                }
              }
            }
          },
          {
            component: "@ambisafe/ui-layout/{Container}",
            props: {
              type: "button",
              position: "absolute",
              top: "10px",
              right: "30px"
            },
            children: {
              component: "@ambisafe/ui-button",
              props: {
                onClick: "${formDispatch}, ${removeCompanyHolder}, ${index}",
                colors: {
                  default: {
                    color: "text.contrast",
                    borderColor: "transparent",
                    backgroundColor: "transparent"
                  },
                  disabled: {
                    color: "text.contrast",
                    borderColor: "transparent",
                    backgroundColor: "transparent"
                  },
                  hovered: {
                    color: "text.contrast",
                    borderColor: "transparent",
                    backgroundColor: "transparent"
                  },
                  focused: {
                    color: "text.contrast",
                    borderColor: "transparent",
                    backgroundColor: "transparent"
                  }
                }
              },
              children: {
                component: "@ambisafe/ui-icons/{Close}",
                props: {
                  height: "35px",
                  width: "35px"
                }
              }
            }
          }
        ]
      }
    },
    {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        height: "50px"
      }
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      children: [
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].companyName",
                index: "${index}",
                type: "text",
                label: "form.company_name",
                placeholder: "",
                validate: ["required", "requiredLatinOnly", ["minLength", 2], ["maxLength", 250]]
              }
            }),
            Field([
              {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  fontSize: "sm"
                },
                children: {
                  component: "@ambisafe/locale/{FormattedMessage}",
                  props: {
                    id: "form.company_incorporated"
                  }
                }
              },
              {
                component: "@ambisafe/ui-form/{Field}",
                props: {
                  name: "crpCompanyHolders[fieldIndex].companyIncorporated",
                  index: "${index}",
                  type: "checkbox",
                  label: "",
                  placeholder: ""
                }
              }
            ])
          ]
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].entityType",
                index: "${index}",
                type: "select",
                label: "form.entity_type",
                placeholder: "",
                validate: ["required"],
                options: "${entityTypesList}"
              }
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].ownershipStructureLayers",
                index: "${index}",
                type: "select",
                label: "form.ownership_structure_layers",
                placeholder: "",
                validate: ["required"],
                options: "${ownershipStructureLayersList}"
              }
            }),
            Field(
              {
                component: "@ambisafe/ui-form/{ConditionField}",
                props: {
                  condition: {
                    when: "crpCompanyHolders[fieldIndex].entityType",
                    is: "UNKNOWN"
                  },
                  name: "crpCompanyHolders[fieldIndex].entityTypeDescription",
                  index: "${index}",
                  type: "text",
                  label: "form.entity_type_description",
                  placeholder: "",
                  validate: ["required"]
                }
              },
              12
            )
          ]
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].countryOfIncorporation",
                index: "${index}",
                type: "select",
                label: "form.country_of_incorporation",
                placeholder: "",
                validate: ["required"],
                options: "${countries}"
              }
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].dateOfIncorporation",
                index: "${index}",
                type: "date",
                label: "form.date_of_incorporation",
                validate: ["required"],
                placeholder: ""
              }
            })
          ]
        }
      ]
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        children: [
          Field({
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "crpCompanyHolders[fieldIndex].countryOfOperations",
              type: "select",
              label: "form.country_of_operations",
              placeholder: "",
              validate: ["required"],
              options: "${countries}"
            }
          })
        ]
      }
    },
    AddressFields,
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      children: [
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].companyIdentificationNumber",
                index: "${index}",
                type: "text",
                label: "form.company_identification_number",
                validate: ["required"],
                placeholder: ""
              }
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].email",
                index: "${index}",
                type: "email",
                label: "form.email",
                placeholder: "",
                validate: ["isEmail", ["minLength", 2], ["maxLength", 250]]
              }
            })
          ]
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].role",
                index: "${index}",
                type: "select",
                label: "form.role",
                placeholder: "",
                validate: ["required", ["minLength", 2]],
                options: "${roleTypesList}"
              }
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].status",
                index: "${index}",
                type: "select",
                label: "form.status",
                placeholder: "",
                validate: ["required"],
                options: "${statusTypesList}"
              }
            })
          ]
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].dateAppointed",
                index: "${index}",
                type: "date",
                label: "form.date_appointed",
                validate: ["required"],
                placeholder: ""
              }
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpCompanyHolders[fieldIndex].dateResigned",
                index: "${index}",
                type: "date",
                label: "form.date_resigned",
                placeholder: ""
              }
            })
          ]
        }
      ]
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        py: "sm",
        mb: "sm"
      },
      children: ""
    }
  ]
};
