/* eslint-disable no-template-curly-in-string */
import { PaidActionDialog } from "./PaidActionDialog";

export default {
  component: "@orderbook/fe-recovery-change-password",
  children: {
    component: "@ambisafe/ui-layout/{GridContainer}",
    children: {
      component: "@ambisafe/ui-layout/{GridRow}",
      props: {
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        minHeight: "calc(100vh - 54px)"
      },
      children: {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          span: {
            mobile: 12,
            tablet: 6,
            tabletLandscape: 4
          }
        },
        children: {
          component: "@ambisafe/ui-layout/{Container}",
          props: {
            borderRadius: "30px",
            overflow: "hidden"
          },
          children: {
            component: "@ambisafe/ui-background",
            props: {
              backgroundColor: "white100"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                p: {
                  mobile: "md",
                  tablet: "xl"
                }
              },
              children: [
                PaidActionDialog,
                {
                  component: "@ambisafe/ui-text/{Heading2}",
                  props: {
                    noBottomGutter: true
                  },
                  children: {
                    component: "react-intl/{FormattedMessage}",
                    props: {
                      id: "please"
                    }
                  }
                },
                {
                  component: "@ambisafe/ui-text/{Heading2}",
                  props: {
                    color: "primary.main"
                  },
                  children: {
                    component: "react-intl/{FormattedMessage}",
                    props: {
                      id: "change_your_password"
                    }
                  }
                },
                {
                  component: "@ambisafe/ui-form/{Form}",
                  props: {
                    onSubmit: "${changePasswordSubmit}"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-layout/{GridRow}",
                      children: [
                        {
                          component: "@ambisafe/ui-layout/{GridCol}",
                          props: {
                            span: {
                              mobile: 12
                            }
                          },
                          children: {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              pb: "xs"
                            },
                            children: {
                              component: "@ambisafe/ui-form/{Field}",
                              props: {
                                name: "password",
                                type: "password",
                                label: "form.new_password",
                                placeholder: "*****",
                                validate: [
                                  "required",
                                  ["minLength", 8],
                                  "requiredSymbols",
                                  "noSpaces",
                                  "requiredDigit",
                                  "requiredLatinLowercase",
                                  "requiredLatinCapital"
                                ]
                              }
                            }
                          }
                        },
                        {
                          component: "@ambisafe/ui-layout/{GridCol}",
                          props: {
                            span: {
                              mobile: 12
                            }
                          },
                          children: {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              pb: "xs"
                            },
                            children: {
                              component: "@ambisafe/ui-form/{Field}",
                              props: {
                                name: "confirmPassword",
                                type: "password",
                                label: "form.confirm_password",
                                placeholder: "*****",
                                validate: [
                                  "required",
                                  ["isEqual", "password"],
                                  ["minLength", 8],
                                  "requiredSymbols",
                                  "noSpaces",
                                  "requiredDigit",
                                  "requiredLatinLowercase",
                                  "requiredLatinCapital"
                                ]
                              }
                            }
                          }
                        }
                      ]
                    },
                    {
                      component: "@ambisafe/fe-statements/{IfTrue}",
                      props: {
                        if: "${submitErrors}"
                      },
                      children: {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          pb: "xxs"
                        },
                        children: {
                          component: "@ambisafe/ui-form/{SubmitError}"
                        }
                      }
                    },
                    {
                      component: "@ambisafe/ui-button",
                      props: {
                        type: "submit",
                        width: "100%"
                      },
                      children: {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          py: "xxs",
                          px: "xs"
                        },
                        children: {
                          component: "@ambisafe/ui-text/{Paragraph}",
                          props: {
                            noBottomGutter: true,
                            textAlign: "center",
                            textTransform: "uppercase"
                          },
                          children: {
                            component: "react-intl/{FormattedMessage}",
                            props: {
                              id: "password_change_submit"
                            }
                          }
                        }
                      }
                    }
                  ]
                }
              ]
            }
          }
        }
      }
    }
  }
};
