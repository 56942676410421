/* eslint-disable no-template-curly-in-string */
import { PrimaryMarket } from "./PrimaryMarket";
import { SecondaryMarket } from "./SecondaryMarket";
import { UserFeeDialog } from "../../../../../shared/UserFeeDialog";

const PaidActionDialog = {
  component: "@orderbook/fe-paid-action",
  props: {
    payableActionData: "${project.depositAddress}",
    onConfirm: "${handlePayableAction}"
  },
  children: {
    component: "@ambisafe/fe-statements/{IfEqualTo}",
    props: {
      if: "${payableActionData.__typename}",
      to: "PayableActionResponse"
    },
    children: UserFeeDialog()
  }
};

const shouldShowPurchasePanel = children => ({
  component: "@ambisafe/fe-statements/{IfNotEqualTo}",
  props: {
    if: "${project.depositAddress.__typename}",
    to: "PayableActionResponse"
  },
  children
});

export const Purchase = {
  component: "@ambisafe/react-router-dom/{WithRouter}",
  children: {
    component: "@orderbook/fe-dialog/{ModalLocationHelper}",
    children: {
      component: "@ambisafe/react-router-dom/{Switch}",
      children: [
        {
          component: "@ambisafe/react-router-dom/{Route}",
          props: {
            path: "${match.url}/contribute/method"
          },
          children: [
            shouldShowPurchasePanel(PrimaryMarket),
            PaidActionDialog,
          ]
        },
        {
          component: "@ambisafe/react-router-dom/{Route}",
          props: {
            path: "${match.url}/secondary"
          },
          children: {
            component: "@orderbook/fe-dialog/{ModalLocationHelper}",
            children: [
              shouldShowPurchasePanel(SecondaryMarket),
              PaidActionDialog,
            ]
          }
        }
      ]
    }
  }
};
