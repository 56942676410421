/* eslint-disable no-template-curly-in-string */
export const PaymentMethod = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.url}",
    exact: true
  },
  children: {
    component: "@ambisafe/fe-statements-to-props/{IfEqualToSome}",
    props: {
      label: "shouldRedirect",
      if: "${project.status}",
      to: ["INITIAL_SALE_ENDED", "EXITED"]
    },
    children: [
      {
        component: "@ambisafe/react-router-dom/{RedirectStatement}",
        props: {
          statement: "${shouldRedirect}",
          to: "/projects/${project.link}"
        }
      },
      {
        component: "@ambisafe/ui-text/{Heading4}",
        props: {
          textAlign: "center"
        },
        children: {
          component: "@ambisafe/ui-text/{Normal}",
          children: [
            {
              component: "react-intl/{FormattedMessage}",
              props: {
                id: "select_payment_method"
              }
            },
            {
              component: "span",
              children: ":"
            }
          ]
        }
      },
      {
        component: "@ambisafe/ui-layout/{GridRow}",
        props: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        },
        children: {
          component: "@ambisafe/ui-list",
          props: {
            variable: "${project.assets}"
          },
          children: {
            component: "@ambisafe/ui-layout/{GridCol}",
            props: {
              span: {
                mobile: 11,
                tablet: 10,
                tabletLandscape: 9,
                laptop: 8
              }
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                mt: "xs"
              },
              children: {
                component: "@ambisafe/ui-layout/{Container}",
                props: {
                  maxWidth: "227px",
                  fluid: false
                },
                children: {
                  component: "@ambisafe/react-router-dom/{Link}",
                  props: {
                    to: "${match.url}/${displaySymbol}"
                  },
                  children: {
                    component: "@ambisafe/ui-button",
                    props: {
                      onClick: "${handleCounterAssetSelect}",
                      onClickData: "${assetId}",
                      width: "100%"
                    },
                    children: {
                      component: "@ambisafe/ui-layout/{Spacing}",
                      props: {
                        p: "xxs"
                      },
                      children: {
                        component: "@ambisafe/ui-layout/{GridRow}",
                        props: {
                          alignItems: "center",
                          justifyContent: "center"
                        },
                        children: [
                          {
                            component: "@ambisafe/ui-layout/{Container}",
                            props: {
                              margin: "0px"
                            },
                            children: {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                mr: "xs"
                              },
                              children: {
                                component: "@ambisafe/ui-image",
                                props: {
                                  src: "${icon}",
                                  width: "32px",
                                  height: "32px"
                                }
                              }
                            }
                          },
                          {
                            component: "@ambisafe/ui-layout/{Container}",
                            props: {
                              margin: "0px"
                            },
                            children: {
                              component: "@ambisafe/ui-text/{Heading4}",
                              props: {
                                color: "text.contrast",
                                textAlign: "center",
                                noBottomGutter: true,
                                textTransform: "uppercase"
                              },
                              children: {
                                component: "@ambisafe/ui-text/{Normal}",
                                children: "${title}"
                              }
                            }
                          }
                        ]
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    ]
  }
};
