export const ListingRequest = props => ({
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    fluid: false
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      py: "lg"
    },
    children: {
      component: "@ambisafe/ui-layout/{GridContainer}",
      children: [
        {
          component: "@ambisafe/ui-text/{Heading2}",
          props: {
            textAlign: "center"
          },
          children: {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: "listing_request_title"
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          props: {
            flexDirection: {
              mobile: "column",
              tablet: "row"
            }
          },
          children: [
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                span: {
                  mobile: 12,
                  tablet: 6
                }
              },
              children: {
                component: "@ambisafe/ui-layout/{Spacing}",
                props: {
                  height: "100%",
                  mb: "md"
                },
                children: {
                  component: "@ambisafe/ui-layout/{Container}",
                  props: {
                    borderRadius: "10px",
                    overflow: "hidden",
                    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.05)",
                    height: "100%"
                  },
                  children: {
                    component: "@ambisafe/ui-background",
                    props: {
                      backgroundColor: "elements.cardBackground",
                      height: "100%"
                    },
                    children: {
                      component: "@ambisafe/ui-layout/{Spacing}",
                      props: {
                        height: "100%",
                        p: "lg"
                      },
                      children: {
                        component: "@ambisafe/ui-layout/{GridRow}",
                        props: {
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100%"
                        },
                        children: [
                          {
                            component: "@ambisafe/ui-layout/{GridCol}",
                            children: [
                              {
                                component: "@ambisafe/ui-text/{Paragraph}",
                                props: {
                                  textAlign: "center",
                                  noBottomGutter: true
                                },
                                children: {
                                  component: "@ambisafe/ui-image",
                                  props: {
                                    src: "/images/ico-participate.svg"
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-text/{Heading3}",
                                props: {
                                  textAlign: "center"
                                },
                                children: {
                                  component: "@ambisafe/ui-text/{Normal}",
                                  children: {
                                    component: "@ambisafe/locale/{FormattedMessage}",
                                    props: {
                                      id: "listing_primary_market_title"
                                    }
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-text/{Paragraph}",
                                props: {
                                  color: "text.secondary",
                                  textAlign: "center"
                                },
                                children: {
                                  component: "@ambisafe/locale/{FormattedMessage}",
                                  props: {
                                    id: "listing_primary_market_subtitle"
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-text/{Paragraph}",
                                props: {
                                  textAlign: "center"
                                },
                                children: {
                                  component: "@ambisafe/locale/{FormattedMessage}",
                                  props: {
                                    id: "listing_primary_market_title_description"
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  my: "lg"
                                },
                                children: {
                                  component: "@ambisafe/ui-divider",
                                  props: {
                                    opacity: 0.3
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-text/{Heading4}",
                                children: {
                                  component: "@ambisafe/ui-text/{Normal}",
                                  children: {
                                    component: "@ambisafe/locale/{FormattedMessage}",
                                    props: {
                                      id: "listing_primary_market_body_title"
                                    }
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "sm"
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{GridRow}",
                                  props: {
                                    flexWrap: "nowrap"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-image",
                                        props: {
                                          src: "/images/listing/custom.svg"
                                        }
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridRow}",
                                        props: {
                                          height: "100%",
                                          alignItems: "center"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{GridCol}",
                                          children: {
                                            component: "@ambisafe/ui-text/{Paragraph}",
                                            props: {
                                              noBottomGutter: true
                                            },
                                            children: {
                                              component: "@ambisafe/locale/{FormattedMessage}",
                                              props: {
                                                id: "listing_primary_item_1"
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  ]
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "sm"
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{GridRow}",
                                  props: {
                                    flexWrap: "nowrap"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-image",
                                        props: {
                                          src: "/images/listing/contribute.svg"
                                        }
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridRow}",
                                        props: {
                                          height: "100%",
                                          alignItems: "center"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{GridCol}",
                                          children: {
                                            component: "@ambisafe/ui-text/{Paragraph}",
                                            props: {
                                              noBottomGutter: true
                                            },
                                            children: {
                                              component: "@ambisafe/locale/{FormattedMessage}",
                                              props: {
                                                id: "listing_primary_item_2"
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  ]
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "sm"
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{GridRow}",
                                  props: {
                                    flexWrap: "nowrap"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-image",
                                        props: {
                                          src: "/images/listing/customize.svg"
                                        }
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridRow}",
                                        props: {
                                          height: "100%",
                                          alignItems: "center"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{GridCol}",
                                          children: {
                                            component: "@ambisafe/ui-text/{Paragraph}",
                                            props: {
                                              noBottomGutter: true
                                            },
                                            children: {
                                              component: "@ambisafe/locale/{FormattedMessage}",
                                              props: {
                                                id: "listing_primary_item_3"
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  ]
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "sm"
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{GridRow}",
                                  props: {
                                    flexWrap: "nowrap"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-image",
                                        props: {
                                          src: "/images/listing/shorten-route.svg"
                                        }
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridRow}",
                                        props: {
                                          height: "100%",
                                          alignItems: "center"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{GridCol}",
                                          children: {
                                            component: "@ambisafe/ui-text/{Paragraph}",
                                            props: {
                                              noBottomGutter: true
                                            },
                                            children: {
                                              component: "@ambisafe/locale/{FormattedMessage}",
                                              props: {
                                                id: "listing_primary_item_4"
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  ]
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "sm"
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{GridRow}",
                                  props: {
                                    flexWrap: "nowrap"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-image",
                                        props: {
                                          src: "/images/listing/notification.svg"
                                        }
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridRow}",
                                        props: {
                                          height: "100%",
                                          alignItems: "center"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{GridCol}",
                                          children: {
                                            component: "@ambisafe/ui-text/{Paragraph}",
                                            props: {
                                              noBottomGutter: true
                                            },
                                            children: {
                                              component: "@ambisafe/locale/{FormattedMessage}",
                                              props: {
                                                id: "listing_primary_item_5"
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  ]
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{GridRow}",
                                  props: {
                                    flexWrap: "nowrap"
                                  },
                                  children: [
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-image",
                                        props: {
                                          src: "/images/listing/star.svg"
                                        }
                                      }
                                    },
                                    {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridRow}",
                                        props: {
                                          height: "100%",
                                          alignItems: "center"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-layout/{GridCol}",
                                          children: {
                                            component: "@ambisafe/ui-text/{Paragraph}",
                                            props: {
                                              noBottomGutter: true
                                            },
                                            children: {
                                              component: "@ambisafe/locale/{FormattedMessage}",
                                              props: {
                                                id: "listing_primary_item_6"
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridCol}",
                            children: [
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  my: "lg"
                                },
                                children: {
                                  component: "@ambisafe/ui-divider",
                                  props: {
                                    opacity: 0.3
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-text/{Heading4}",
                                props: {
                                  textAlign: "center"
                                },
                                children: {
                                  component: "@ambisafe/ui-text/{Normal}",
                                  children: {
                                    component: "@ambisafe/locale/{FormattedMessage}",
                                    props: {
                                      id: "listing_primary_footer_description"
                                    }
                                  }
                                }
                              },
                              {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  mt: "md"
                                },
                                children: {
                                  component: "@ambisafe/react-router-dom/{Link}",
                                props: props.linkAttribute
                                  ? { to: "#", customDataAttr: props.linkAttribute }
                                  : { to: "https://goo.gl/forms/302pSDeDNkmhveFP2" },
                                  children: {
                                    component: "@ambisafe/ui-button",
                                    props: {
                                      width: "100%"
                                    },
                                    children: {
                                      component: "@ambisafe/ui-layout/{Spacing}",
                                      props: {
                                        py: "xs",
                                        px: "xs"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Heading4}",
                                        props: {
                                          noBottomGutter: true,
                                          textAlign: "center"
                                        },
                                        children: {
                                          component: "@ambisafe/ui-text/{Normal}",
                                          children: {
                                            component: "@ambisafe/locale/{FormattedMessage}",
                                            props: {
                                              id: "listing_primary_button"
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            ]
                          }
                        ]
                      }
                    }
                  }
                }
              }
            },
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                span: {
                  mobile: 12,
                  tablet: 6
                }
              },
              children: {
                component: "@ambisafe/ui-layout/{Container}",
                props: {
                  borderRadius: "10px",
                  overflow: "hidden",
                  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.05)",
                  height: "100%"
                },
                children: {
                  component: "@ambisafe/ui-background",
                  props: {
                    backgroundColor: "elements.cardBackground",
                    height: "100%"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      p: "lg",
                      height: "100%"
                    },
                    children: {
                      component: "@ambisafe/ui-layout/{GridRow}",
                      props: {
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%"
                      },
                      children: [
                        {
                          component: "@ambisafe/ui-layout/{GridCol}",
                          children: [
                            {
                              component: "@ambisafe/ui-text/{Paragraph}",
                              props: {
                                textAlign: "center",
                                noBottomGutter: true
                              },
                              children: {
                                component: "@ambisafe/ui-image",
                                props: {
                                  src: "/images/trading.svg"
                                }
                              }
                            },
                            {
                              component: "@ambisafe/ui-text/{Heading3}",
                              props: {
                                textAlign: "center"
                              },
                              children: {
                                component: "@ambisafe/ui-text/{Normal}",
                                children: {
                                  component: "@ambisafe/locale/{FormattedMessage}",
                                  props: {
                                    id: "listing_secondary_market_title"
                                  }
                                }
                              }
                            },
                            {
                              component: "@ambisafe/ui-text/{Paragraph}",
                              props: {
                                color: "text.secondary",
                                textAlign: "center"
                              },
                              children: {
                                component: "@ambisafe/locale/{FormattedMessage}",
                                props: {
                                  id: "listing_secondary_market_subtitle"
                                }
                              }
                            },
                            {
                              component: "@ambisafe/ui-text/{Paragraph}",
                              props: {
                                textAlign: "center"
                              },
                              children: {
                                component: "@ambisafe/locale/{FormattedMessage}",
                                props: {
                                  id: "listing_secondary_market_title_description"
                                }
                              }
                            },
                            {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                my: "lg"
                              },
                              children: {
                                component: "@ambisafe/ui-divider",
                                props: {
                                  opacity: 0.3
                                }
                              }
                            },
                            {
                              component: "@ambisafe/ui-text/{Heading4}",
                              children: {
                                component: "@ambisafe/ui-text/{Normal}",
                                children: {
                                  component: "@ambisafe/locale/{FormattedMessage}",
                                  props: {
                                    id: "listing_secondary_market_body_title"
                                  }
                                }
                              }
                            },
                            {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                mb: "sm"
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  flexWrap: "nowrap"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-image",
                                      props: {
                                        src: "/images/listing/week.svg"
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridRow}",
                                      props: {
                                        height: "100%",
                                        alignItems: "center"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        children: {
                                          component: "@ambisafe/ui-text/{Paragraph}",
                                          props: {
                                            noBottomGutter: true
                                          },
                                          children: {
                                            component: "@ambisafe/locale/{FormattedMessage}",
                                            props: {
                                              id: "listing_secondary_item_1"
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                mb: "sm"
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  flexWrap: "nowrap"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-image",
                                      props: {
                                        src: "/images/listing/email.svg"
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridRow}",
                                      props: {
                                        height: "100%",
                                        alignItems: "center"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        children: {
                                          component: "@ambisafe/ui-text/{Paragraph}",
                                          props: {
                                            noBottomGutter: true
                                          },
                                          children: {
                                            component: "@ambisafe/locale/{FormattedMessage}",
                                            props: {
                                              id: "listing_secondary_item_2"
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                mb: "sm"
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  flexWrap: "nowrap"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-image",
                                      props: {
                                        src: "/images/listing/interface.svg"
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridRow}",
                                      props: {
                                        height: "100%",
                                        alignItems: "center"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        children: {
                                          component: "@ambisafe/ui-text/{Paragraph}",
                                          props: {
                                            noBottomGutter: true
                                          },
                                          children: {
                                            component: "@ambisafe/locale/{FormattedMessage}",
                                            props: {
                                              id: "listing_secondary_item_3"
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                mb: "sm"
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  flexWrap: "nowrap"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-image",
                                      props: {
                                        src: "/images/listing/security.svg"
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridRow}",
                                      props: {
                                        height: "100%",
                                        alignItems: "center"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        children: {
                                          component: "@ambisafe/ui-text/{Paragraph}",
                                          props: {
                                            noBottomGutter: true
                                          },
                                          children: {
                                            component: "@ambisafe/locale/{FormattedMessage}",
                                            props: {
                                              id: "listing_secondary_item_4"
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                mb: "sm"
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  flexWrap: "nowrap"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-image",
                                      props: {
                                        src: "/images/listing/password.svg"
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridRow}",
                                      props: {
                                        height: "100%",
                                        alignItems: "center"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        children: {
                                          component: "@ambisafe/ui-text/{Paragraph}",
                                          props: {
                                            noBottomGutter: true
                                          },
                                          children: {
                                            component: "@ambisafe/locale/{FormattedMessage}",
                                            props: {
                                              id: "listing_secondary_item_5"
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                mb: "xs"
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  flexWrap: "nowrap"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-image",
                                      props: {
                                        src: "/images/listing/support.svg"
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridRow}",
                                      props: {
                                        height: "100%",
                                        alignItems: "center"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        children: {
                                          component: "@ambisafe/ui-text/{Paragraph}",
                                          props: {
                                            noBottomGutter: true
                                          },
                                          children: {
                                            component: "@ambisafe/locale/{FormattedMessage}",
                                            props: {
                                              id: "listing_secondary_item_6"
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                mb: "xs"
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  flexWrap: "nowrap"
                                },
                                children: [
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-image",
                                      props: {
                                        src: "/images/listing/implemented.svg"
                                      }
                                    }
                                  },
                                  {
                                    component: "@ambisafe/ui-layout/{GridCol}",
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridRow}",
                                      props: {
                                        height: "100%",
                                        alignItems: "center"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-layout/{GridCol}",
                                        children: {
                                          component: "@ambisafe/ui-text/{Paragraph}",
                                          props: {
                                            noBottomGutter: true
                                          },
                                          children: {
                                            component: "@ambisafe/locale/{FormattedMessage}",
                                            props: {
                                              id: "listing_secondary_item_7"
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        },
                        {
                          component: "@ambisafe/ui-layout/{GridCol}",
                          children: [
                            {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                my: "lg"
                              },
                              children: {
                                component: "@ambisafe/ui-divider",
                                props: {
                                  opacity: 0.3
                                }
                              }
                            },
                            {
                              component: "@ambisafe/ui-text/{Heading4}",
                              props: {
                                textAlign: "center"
                              },
                              children: {
                                component: "@ambisafe/ui-text/{Normal}",
                                children: {
                                  component: "@ambisafe/locale/{FormattedMessage}",
                                  props: {
                                    id: "listing_secondary_footer_description"
                                  }
                                }
                              }
                            },
                            {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                mt: "md"
                              },
                              children: {
                                component: "@ambisafe/react-router-dom/{Link}",
                                props: props.linkAttribute
                                  ? { to: "#", customDataAttr: props.linkAttribute }
                                  : { to: "https://goo.gl/forms/302pSDeDNkmhveFP2" },
                                children: {
                                  component: "@ambisafe/ui-button",
                                  props: {
                                    width: "100%"
                                  },
                                  children: {
                                    component: "@ambisafe/ui-layout/{Spacing}",
                                    props: {
                                      py: "xs",
                                      px: "xs"
                                    },
                                    children: {
                                      component: "@ambisafe/ui-text/{Heading4}",
                                      props: {
                                        noBottomGutter: true,
                                        textAlign: "center"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Normal}",
                                        children: {
                                          component: "@ambisafe/locale/{FormattedMessage}",
                                          props: {
                                            id: "listing_secondary_button"
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          ]
                        }
                      ]
                    }
                  }
                }
              }
            }
          ]
        }
      ]
    }
  }
})