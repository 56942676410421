/* eslint-disable no-template-curly-in-string */
export const SubmitErrors = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${submitErrors}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pt: "xxs"
    },
    children: {
      component: "@ambisafe/ui-form/{SubmitError}"
    }
  }
};
