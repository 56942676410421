/* eslint-disable no-template-curly-in-string */
import { CurrentProject } from "./CurrentProject";
import { UpcomingProject } from "./UpcomingProject";
import { SecondaryProject } from "./SecondaryProject";

export const CurrentProjects = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${investBank.currentProjects.length}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    children: [
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          pb: "xl"
        },
        children: {
          component: "@ambisafe/ui-text/{Heading3}",
          props: {
            noBottomGutter: true
          },
          children: {
            component: "@ambisafe/ui-text/{Normal}",
            children: {
              component: "react-intl/{FormattedMessage}",
              props: {
                id: "current_projects"
              }
            }
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          mb: "sm"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: {
            component: "@ambisafe/ui-list",
            props: {
              variable: "${investBank.currentProjects}"
            },
            children: CurrentProject
          }
        }
      }
    ]
  }
};

export const UpcomingProjects = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${investBank.upcomingProjects.length}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    children: [
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          pb: "xl"
        },
        children: {
          component: "@ambisafe/ui-text/{Heading3}",
          props: {
            noBottomGutter: true
          },
          children: {
            component: "@ambisafe/ui-text/{Normal}",
            children: {
              component: "react-intl/{FormattedMessage}",
              props: {
                id: "upcoming_projects"
              }
            }
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          mb: "sm"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: {
            component: "@ambisafe/ui-list",
            props: {
              variable: "${investBank.upcomingProjects}"
            },
            children: UpcomingProject
          }
        }
      }
    ]
  }
};

export const SecondaryProjects = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${investBank.secondaryProjects.length}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    children: [
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          pb: "xl"
        },
        children: {
          component: "@ambisafe/ui-text/{Heading3}",
          props: {
            noBottomGutter: true
          },
          children: {
            component: "@ambisafe/ui-text/{Normal}",
            children: {
              component: "react-intl/{FormattedMessage}",
              props: {
                id: "listing_secondary_market_title"
              }
            }
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          mb: "sm"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: {
            component: "@ambisafe/ui-list",
            props: {
              variable: "${investBank.secondaryProjects}"
            },
            children: SecondaryProject
          }
        }
      }
    ]
  }
};
