/* eslint-disable no-template-curly-in-string */
import {
  PayPal,
  FormErrors,
  WireButton,
  AmountField,
  AmountHeading,
  HiddenSubmitButton,
  PaymentMethodHeading,
} from "./components";

export const DepositForm = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: {
      mobile: "100%",
      tablet: "265px"
    }
  },
  children: [
    {
      component: "@ambisafe/ui-form/{Form}",
      props: {
        onSubmit: "${handleFiatDepositSubmit}",
      },
      children: [
        AmountHeading,
        AmountField,
        FormErrors,
        PaymentMethodHeading,
        WireButton,
        PayPal
      ]
    },
  ]
};
