/* eslint-disable no-template-curly-in-string */

import { Fee } from "./Fee";
import { DepositAddress } from "./DepositAddress";

export const Crypto = {
  component: "@ambisafe/fe-statements/{IfEqualToSome}",
  props: {
    if: "${type}",
    to: ["CRYPTO", "PROJECT", "BTC", "CREDIT"]
  },
  children: [
    {
      component: "@ambisafe/ui-divider",
      props: {
        opacity: 0.2
      }
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Fee,
        DepositAddress
      ]
    }
  ]
};
