/* eslint-disable no-template-curly-in-string */
export default {
  component: "@orderbook/fe-recovery-request",
  children: {
    component: "@ambisafe/ui-layout/{GridContainer}",
    children: {
      component: "@ambisafe/ui-layout/{GridRow}",
      props: {
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        minHeight: "calc(100vh - 54px)"
      },
      children: {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          span: {
            mobile: 12,
            tablet: 6,
            tabletLandscape: 4
          }
        },
        children: {
          component: "@ambisafe/ui-layout/{Container}",
          props: {
            borderRadius: "30px",
            overflow: "hidden"
          },
          children: {
            component: "@ambisafe/ui-background",
            props: {
              backgroundColor: "white100"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                p: {
                  mobile: "md",
                  tablet: "xl"
                }
              },
              children: [
                {
                  component: "@ambisafe/ui-text/{Heading2}",
                  props: {
                    noBottomGutter: true
                  },
                  children: {
                    component: "react-intl/{FormattedMessage}",
                    props: {
                      id: "please"
                    }
                  }
                },
                {
                  component: "@ambisafe/ui-text/{Heading2}",
                  props: {
                    color: "primary.main",
                    lineHeight: 1
                  },
                  children: {
                    component: "react-intl/{FormattedMessage}",
                    props: {
                      id: "enter_your_email"
                    }
                  }
                },
                {
                  component: "@ambisafe/ui-form/{Form}",
                  props: {
                    onSubmit: "${requestRecoverySubmit}"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-layout/{Spacing}",
                      props: {
                        pb: "xxs"
                      },
                      children: {
                        component: "@ambisafe/ui-form/{Field}",
                        props: {
                          name: "email",
                          type: "email",
                          label: "form.email",
                          placeholder: "",
                          validate: ["required", "isEmail"]
                        }
                      }
                    },
                    {
                      component: "@ambisafe/fe-statements/{IfTrue}",
                      props: {
                        if: "${submitErrors}"
                      },
                      children: {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          pb: "xxs"
                        },
                        children: {
                          component: "@ambisafe/ui-form/{SubmitError}"
                        }
                      }
                    },
                    {
                      component: "@ambisafe/ui-button",
                      props: {
                        type: "submit",
                        width: "100%"
                      },
                      children: {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        props: {
                          py: "xxs",
                          px: "xs"
                        },
                        children: {
                          component: "@ambisafe/ui-text/{Paragraph}",
                          props: {
                            noBottomGutter: true,
                            textAlign: "center",
                            textTransform: "uppercase"
                          },
                          children: {
                            component: "react-intl/{FormattedMessage}",
                            props: {
                              id: "confirm_email"
                            }
                          }
                        }
                      }
                    }
                  ]
                }
              ]
            }
          }
        }
      }
    }
  }
};
