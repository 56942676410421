/* eslint-disable no-template-curly-in-string */

export const Icon = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "64px"
  },
  children: {
    component: "@ambisafe/ui-image",
    props: {
      width: "32px",
      height: "32px",
      src: "${icon}"
    }
  }
};
