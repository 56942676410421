/* eslint-disable no-template-curly-in-string */
import { SuccessMessage } from "./components";

export const PayPalSuccesfullyApprovedOrderDialog = {
  component: "@ambisafe/ui-modal",
  props: {
    disableScroll: true,
    onClose: "${handleModalClose}",
    initialUrl: "/account/assets",
    width: {
      mobile: "90%",
      tablet: "auto"
    },
    maxWidth: "700px",
    px: {
      mobile: "xxs",
      mobileLandscape: "sm"
    },
    py: "sm"
  },
  children: [
    SuccessMessage
  ]
};
