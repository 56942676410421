/* eslint-disable no-template-curly-in-string */
export const Links = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    alignRight: true,
    height: "100%"
  },
  children: {
    component: "@orderbook/fe-user",
    children: [
      {
        component: "@ambisafe/fe-statements/{IfTrue}",
        props: {
          if: "${user}"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          props: {
            height: "100%",
            alignItems: "center"
          },
          children: [
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                hide: {
                  mobile: true,
                  tablet: false
                }
              },
              children: {
                component: "@ambisafe/react-router-dom/{Link}",
                props: {
                  to: "/account/assets",
                  display: "block",
                  height: "100%"
                },
                children: {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  props: {
                    height: "100%",
                    alignItems: "center"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{GridCol}",
                    children: {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: {
                          component: "react-intl/{FormattedMessage}",
                          props: {
                            id: "account"
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                hide: {
                  tablet: true
                }
              },
              children: {
                component: "@ambisafe/react-router-dom/{Link}",
                props: {
                  to: "/account",
                  display: "block",
                  height: "100%"
                },
                children: {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  props: {
                    height: "100%",
                    alignItems: "center"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{GridCol}",
                    children: {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: {
                          component: "react-intl/{FormattedMessage}",
                          props: {
                            id: "account"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          ]
        }
      },
      {
        component: "@ambisafe/fe-statements/{IfFalse}",
        props: {
          if: "${user}"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          props: {
            height: "100%"
          },
          children: [
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              children: {
                component: "@ambisafe/react-router-dom/{Link}",
                props: {
                  to: "/auth/login",
                  display: "block",
                  height: "100%"
                },
                children: {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  props: {
                    height: "100%",
                    alignItems: "center"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{GridCol}",
                    children: {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: {
                          component: "react-intl/{FormattedMessage}",
                          props: {
                            id: "login"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          ]
        }
      }
    ]
  }
};
