/* eslint-disable no-template-curly-in-string */
import { KYCVerification } from "./KYCVerification";
import { InvestorAccreditation } from "./InvestorAccreditation";
import { DesktopMenu, MobileMenu } from "./Menu";
import { ActionsHistory } from "./ActionsHistory";
import { BankInfo } from "./BankInfo";
import { Portfolio } from "./Portfolio";
import { Settings } from "./Settings";

export const Account = {
  component: "@orderbook/fe-user",
  children: {
    component: "@ambisafe/react-router-dom/{RedirectFalsyStatement}",
    props: {
      statement: "${user}",
      to: "/auth/login"
    },
    children: {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        fluid: false
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          py: {
            mobile: "md",
            tablet: "xxl"
          }
        },
        children: {
          component: "@ambisafe/ui-layout/{GridContainer}",
          children: {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [
              DesktopMenu,
              {
                component: "@ambisafe/ui-layout/{GridCol}",
                props: {
                  flex: 1,
                  maxWidth: "100%"
                },
                children: [
                  MobileMenu,
                  KYCVerification,
                  InvestorAccreditation,
                  Portfolio,
                  ActionsHistory,
                  BankInfo,
                  // Settings
                ],
              },
            ],
          },
        },
      },
    },
  },
};
