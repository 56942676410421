/* eslint-disable no-template-curly-in-string */
import { VerifyIdentity } from "../VerifyIdentity";
import { Login } from "../Login";
import { SignDocuments } from "./SignDocuments";

export const SecondaryMarket = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${project.projectPair}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mt: "md"
    },
    children: {
      component: "@ambisafe/react-router-dom/{WithRouter}",
      children: [
        {
          component: "@ambisafe/fe-statements/{IfTrue}",
          props: {
            if: "${isUserValid}"
          },
          children: [
            SignDocuments,
            VerifyIdentity
          ]
        },
        {
          component: "@ambisafe/fe-statements/{IfFalse}",
          props: {
            if: "${isUserValid}"
          },
          children: Login
        }
      ]
    }
  }
};
