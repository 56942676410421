/* eslint-disable no-template-curly-in-string */
import { InvoiceContainer } from "../InvoiceContainer";
import { MessageContainer } from "./MessageContainer";
import { Divider } from "../Divider";

const FormattedMessage = (id, values) => ({
  component: "react-intl/{FormattedMessage}",
  props: {
    id,
    values
  }
});

const WarningIcon = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "top"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pb: {
        mobile: "xxs",
        tablet: "xxs"
      }
    },
    children: {
      component: "@ambisafe/ui-icons/{Warning}",
      props: {
        width: "5em",
        height: "5em"
      }
    }
  }
};

export const NotEnoughBalanceMessage = {
  component: "@ambisafe/ui-layout/{GridContainer}",
  props: {
    height: "100%",
    display: "grid",
    gutter: 16,
    gap: "1rem",
    gridTemplateColumns: {
      mobile: "repeat(minmax(340px, 1fr))",
      tabletLandscape: "1fr 5px 1fr"
    }
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        p: {
          mobile: "xs",
          tablet: "xl"
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "inherit"
        },
        children: [
          WarningIcon,
          MessageContainer(
            FormattedMessage("paid_acion.action_description", {
              providerId: "${payableActionData.providerDescription}",
              payableActions: "${payableActionData.actionName}"
            })
          ),
          MessageContainer(
            FormattedMessage("paid_action.fee_for_action", {
              amount: "${payableActionData.amount}",
              assetSymbol: "${payableActionData.assetSymbol}"
            })
          )
        ]
      }
    },
    Divider,
    InvoiceContainer
  ]
};
