/* eslint-disable no-template-curly-in-string */
export const SecondaryLink = (to, title, onClick = () => {}) => ({
  component: "@ambisafe/react-router-dom/{Link}",
  props: {
    to,
    onClick
  },
  children: {
    component: "@ambisafe/ui-button",
    props: {
      width: "100%",
      disabled: "${shouldDisableInvest}"
    },
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        p: "xxs"
      },
      children: {
        component: "@ambisafe/ui-text/{Heading4}",
        props: {
          noBottomGutter: true,
          textAlign: "center"
        },
        children: {
          component: "@ambisafe/ui-text/{Normal}",
          children: {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: title
            }
          }
        }
      }
    }
  }
});

export const SecondaryMarketLinks = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    display: "flex"
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        pr: "15px",
        width: "100%"
      },
      children: SecondaryLink("${match.url}/secondary/buy", "buy")
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        pl: "15px",
        width: "100%"
      },
      children: SecondaryLink("${match.url}/secondary/sell", "sell")
    }
  ]
};
