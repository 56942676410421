/* eslint-disable no-template-curly-in-string */
export const OperationDescription = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pb: "md"
  },
  children: {
    component: "@ambisafe/ui-text/{Paragraph}",
    props: {
      noBottomGutter: true
    },
    children: {
      component: "react-intl/{FormattedMessage}",
      props: {
        id: "buy_asset_description"
      }
    }
  }
};
