/* eslint-disable no-template-curly-in-string */
export const ProjectVideo = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${project.youtubeId}"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "xl"
    },
    children: {
      component: "@ambisafe/fe-statements/{IfTrue}",
      props: {
        if: "${project.youtubeId}"
      },
      children: {
        component: "react-iframe",
        props: {
          url: "https://www.youtube.com/embed/${project.youtubeId}?rel=0",
          width: "100%",
          height: "380px",
          frameborder: "0",
          allowFullScreen: true
        }
      }
    }
  }
};
