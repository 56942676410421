/* eslint-disable no-template-curly-in-string */
//
//  Components
//
const FormattedMessage = (id, values) => ({
  component: "react-intl/{FormattedMessage}",
  props: {
    id,
    values
  }
});

const SuccessIcon = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "top"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      pb: {
        mobile: "xxs",
        tablet: "xxs"
      }
    },
    children: {
      component: "@ambisafe/ui-icons/{CheckCircleOutline}",
      props: {
        width: "5em",
        height: "5em"
      }
    }
  }
};

//
//  Success Message
//
export const SuccessMessage = {
  component: "@ambisafe/ui-layout/{GridContainer}",
  props: {
    display: "grid",
    justiryContent: "center",
    gap: "1rem",
    width: {
      mobile: "50ch",
      tablet: "80ch"
    }
  },
  children: {
    component: "@ambisafe/fe-statements/{IfEqualTo}",
    props: {
      if: "${paidAction.paymentStatus}",
      to: "SUCCESS"
    },
    children: [
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          p: "xl"
        },
        children: {
          component: "@ambisafe/ui-layout/{Container}",
          props: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "inherit"
          },
          children: [
            SuccessIcon,
            {
              component: "@ambisafe/ui-text/{Paragraph}",
              props: {
                textAlign: "left",
                display: "border-box",
                fontSize: {
                  mobile: "0.85em",
                  tabletLandscape: "1em",
                  wide: "1em"
                }
              },
              children: FormattedMessage("paid_action.payment_success")
            }
          ]
        }
      }
    ]
  }
};

//
// Error Message
//
export const ErrorMessage = {
  component: "@ambisafe/fe-statements/{IfEqualToSome}",
  props: {
    if: "${paidAction.paymentStatus}",
    to: ["FAILED", "SERVER_ERROR"],
    color: "red"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      width: "100%",
      p: {
        mobile: "xxs",
        tablet: "xxs"
      }
    },
    children: {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        display: "flex",
        maxWidth: "100%",
        justifyContent: "center",
        alignItems: "center"
      },
      children: {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          textAlign: "left",
          display: "border-box",
          fontSize: {
            mobile: "0.85em",
            tabletLandscape: "1em",
            wide: "1em"
          }
        },
        children: FormattedMessage("paid_action.payment_failed")
      }
    }
  }
};
