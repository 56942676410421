/* eslint-disable no-template-curly-in-string */
export const Settings = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.url}/settings"
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        hide: {
          tablet: true
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          mb: "sm"
        },
        children: {
          component: "@ambisafe/react-router-dom/{Link}",
          props: {
            to: "/account"
          },
          children: {
            component: "@ambisafe/ui-icons/{ArrowLeft}"
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-text/{Heading2}",
      children: {
        component: "react-intl/{FormattedMessage}",
        props: {
          id: "account_settings"
        }
      }
    },
    {
      component: "@orderbook/pg-account/{Settings}"
    }
  ]
};
