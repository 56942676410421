/* eslint-disable no-template-curly-in-string */
import { Field } from "../FieldSets";

export const PersonalData = [
  {
    component: "@ambisafe/ui-layout/{GridRow}",
    children: [
      Field({
        component: "@ambisafe/ui-form/{Field}",
        props: {
          name: "firstName",
          type: "name",
          label: "form.first_name",
          placeholder: "",
          validate: ["required", "requiredLatinOnly", ["minLength", 2], ["maxLength", 50]]
        }
      }),
      Field({
        component: "@ambisafe/ui-form/{Field}",
        props: {
          name: "lastName",
          type: "name",
          label: "form.last_name",
          placeholder: "",
          validate: ["required", "requiredLatinOnly", ["minLength", 2], ["maxLength", 50]]
        }
      })
    ]
  },
  {
    component: "@ambisafe/ui-layout/{GridRow}",
    children: [
      Field({
        component: "@ambisafe/ui-form/{Field}",
        props: {
          name: "dateOfBirth",
          type: "date",
          label: "form.date_of_birth",
          placeholder: "",
          validate: ["required", "isAdult"]
        }
      })
    ]
  }
];
