/* eslint-disable no-template-curly-in-string */
export const ProjectDocuments = {
  component: "@orderbook/fe-user",
  children: [
    {
      component: "@ambisafe/fe-statements/{IfTrue}",
      props: {
        if: "${user}"
      },
      children: {
        component: "@ambisafe/fe-statements/{IfTrue}",
        props: {
          if: "${project.documents.length}"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridContainer}",
          children: {
            component: "@ambisafe/ui-layout/{GridRow}",
            props: {
              flexDirection: {
                mobile: "column",
                tablet: "row"
              }
            },
            children: {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                span: {
                  mobile: 12,
                  tablet: 7
                }
              },
              children: {
                component: "@ambisafe/ui-layout/{Spacing}",
                children: [
                  {
                    component: "@ambisafe/ui-text/{Heading4}",
                    props: {
                      noBottomGutter: true,
                      textTransform: "capitalize",
                      opacity: 0.6
                    },
                    children: {
                      component: "@ambisafe/ui-text/{Normal}",
                      children: {
                        component: "react-intl/{FormattedMessage}",
                        props: {
                          id: "documents"
                        }
                      }
                    }
                  },
                  {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      pb: "sm"
                    }
                  },
                  {
                    component: "@ambisafe/ui-layout/{GridRow}",
                    children: {
                      component: "@ambisafe/ui-list",
                      props: {
                        variable: "${project.documents}"
                      },
                      children: {
                        component: "@ambisafe/ui-layout/{GridCol}",
                        props: {
                          span: 6
                        },
                        children: {
                          component: "@ambisafe/ui-layout/{Spacing}",
                          props: {
                            mb: "sm"
                          },
                          children: {
                            component: "@ambisafe/react-router-dom/{Link}",
                            props: {
                              to: "${link}",
                              rel: "noopener noreferrer",
                              target: "_blank"
                            },
                            children: {
                              component: "@ambisafe/ui-layout/{GridRow}",
                              props: {
                                flexWrap: "no-wrap"
                              },
                              children: [
                                {
                                  component: "@ambisafe/ui-layout/{GridCol}",
                                  children: {
                                    component: "@ambisafe/ui-image",
                                    props: {
                                      src: "/images/document_pic.svg",
                                      width: "36px",
                                      height: "42px"
                                    }
                                  }
                                },
                                {
                                  component: "@ambisafe/ui-layout/{GridCol}",
                                  children: {
                                    component: "@ambisafe/ui-layout/{GridRow}",
                                    props: {
                                      alignItems: "center",
                                      height: "100%"
                                    },
                                    children: {
                                      component: "@ambisafe/ui-layout/{GridCol}",
                                      props: {
                                        width: "100%"
                                      },
                                      children: {
                                        component: "@ambisafe/ui-text/{Paragraph}",
                                        props: {
                                          noBottomGutter: true
                                        },
                                        children: "${title}"
                                      }
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        }
                      }
                    }
                  }
                ]  
              }
            }
          }
        }
      }
    }
  ]
};
