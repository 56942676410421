/* eslint-disable no-template-curly-in-string */
export const SignForm = {
  component: "@ambisafe/ui-form/{Form}",
  props: {
    onSubmit: "${signDocuments}",
    initialValues: "${documentsToSignInitialValues}"
  },
  children: [
    {
      component: "@ambisafe/ui-list",
      props: {
        variable: "${documentsToSign}"
      },
      children: [
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: {
            component: "@ambisafe/ui-layout/{GridCol}",
            props: {
              span: {
                mobile: 12
              }
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                pb: "xs"
              },
              children: [
                {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  props: {
                    alignItems: "center"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-layout/{GridCol}",
                      children: {
                        component: "@ambisafe/ui-form/{Field}",
                        props: {
                          name: "${documentName}",
                          type: "checkbox",
                          disabled: "${signed}",
                          validate: ["required"]
                        },
                        children: {
                          component: "@ambisafe/ui-form/{Input}"
                        }
                      }
                    },
                    {
                      component: "@ambisafe/ui-layout/{GridCol}",
                      props: {
                        flex: 1
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Paragraph}",
                        props: {
                          noBottomGutter: true
                        },
                        children: {
                          component: "@ambisafe/react-router-dom/{WithRouter}",
                          children: {
                            component: "@ambisafe/react-router-dom/{Link}",
                            props: {
                              to: "${match.url}/view/${documentId}"
                            },
                            children: "${displayName}"
                          }
                        }
                      }
                    }
                  ]
                },
              ],
            },
          },
        },
      ],
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          pb: "xs",
        },
        children: {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: { span: { mobile: 12 } },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: { pb: "xs" },
            children: {
              component: "@ambisafe/ui-form/{Field}",
              props: {
                type: "checkbox",
                name: "consent_to_use_electronic_signatures",
                label: "form.consent_to_use_electronic_signatures",
              }
            }
          }
        }
      }
    },
    {
      component: "@ambisafe/fe-statements/{IfTrue}",
      props: {
        if: "${submitErrors}"
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          pb: "xxs"
        },
        children: {
          component: "@ambisafe/ui-form/{SubmitError}"
        }
      }
    },
    {
      component: "@ambisafe/ui-button",
      props: {
        type: "submit",
        width: "100%"
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          py: "xxs",
          px: "xs"
        },
        children: {
          component: "@ambisafe/ui-text/{Paragraph}",
          props: {
            noBottomGutter: true,
            textAlign: "center",
            textTransform: "uppercase"
          },
          children: {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "sign_documents"
            }
          }
        }
      }
    }
  ]
};
