/* eslint-disable no-template-curly-in-string */
export const MobilePanel = {
  component: "@orderbook/fe-user",
  chldren: [
    {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 2,
        boxShadow: "0 -3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        hide: {
          mobileLandscape: true,
        },
      },
      children: {
        component: "@ambisafe/ui-background",
        props: {
          backgroundColor: "elements.headerBackground",
          height: "100%",
        },
        children: {
          component: "@ambisafe/ui-layout/{GridContainer}",
          children: {
            component: "@ambisafe/ui-layout/{GridRow}",
            props: {
              height: "54px",
              alignItems: "center",
              justifyContent: "center",
            },
            children: [
              {
                component: "@ambisafe/ui-layout/{GridCol}",
                children: {
                  component: "@ambisafe/react-router-dom/{Link}",
                  props: {
                    to: "/trade",
                    display: "block",
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{GridRow}",
                    props: {
                      alignItems: "center",
                      flexDirection: "column",
                    },
                    children: [
                      {
                        component: "@ambisafe/ui-layout/{GridCol}",
                        children: {
                          component: "@ambisafe/ui-icons/{TrendingUp}",
                        },
                      },
                      {
                        component: "@ambisafe/ui-layout/{GridCol}",
                        children: {
                          component: "@ambisafe/ui-text/{Paragraph}",
                          props: {
                            fontSize: "sm",
                            noBottomGutter: true,
                            color: "primary.main",
                          },
                          children: {
                            component: "@ambisafe/locale/{FormattedMessage}",
                            props: {
                              id: "trade",
                            },
                          },
                        },
                      },
                    ],
                  },
                },
              },
              {
                component: "@ambisafe/fe-statements/{IfTrue}",
                props: {
                  if: "${user}",
                },
                children: {
                  component: "@ambisafe/ui-layout/{GridCol}",
                  children: {
                    component: "@ambisafe/react-router-dom/{Link}",
                    props: {
                      to: "/account",
                      display: "block",
                    },
                    children: {
                      component: "@ambisafe/ui-layout/{GridRow}",
                      props: {
                        alignItems: "center",
                        flexDirection: "column",
                      },
                      children: [
                        {
                          component: "@ambisafe/ui-layout/{GridCol}",
                          children: {
                            component: "@ambisafe/ui-icons/{Account}",
                          },
                        },
                        {
                          component: "@ambisafe/ui-layout/{GridCol}",
                          children: {
                            component: "@ambisafe/ui-text/{Paragraph}",
                            props: {
                              fontSize: "sm",
                              noBottomGutter: true,
                              color: "primary.main",
                            },
                            children: {
                              component: "@ambisafe/locale/{FormattedMessage}",
                              props: {
                                id: "account",
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                },
              },
              {
                component: "@ambisafe/fe-statements/{IfFalse}",
                props: {
                  if: "${user}",
                },
                children: [
                  {
                    component: "@ambisafe/ui-layout/{GridCol}",
                    children: {
                      component: "@ambisafe/react-router-dom/{Link}",
                      props: {
                        to: "/auth/login",
                        display: "block",
                      },
                      children: {
                        component: "@ambisafe/ui-layout/{GridRow}",
                        props: {
                          alignItems: "center",
                          flexDirection: "column",
                        },
                        children: [
                          {
                            component: "@ambisafe/ui-layout/{GridCol}",
                            children: {
                              component: "@ambisafe/ui-icons/{Login}",
                            },
                          },
                          {
                            component: "@ambisafe/ui-layout/{GridCol}",
                            children: {
                              component: "@ambisafe/ui-text/{Paragraph}",
                              props: {
                                fontSize: "sm",
                                noBottomGutter: true,
                                color: "primary.main",
                              },
                              children: {
                                component: "@ambisafe/locale/{FormattedMessage}",
                                props: {
                                  id: "login",
                                },
                              },
                            },
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        },
      },
    },
  ],
};
