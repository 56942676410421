/* eslint-disable no-template-curly-in-string */
import { Contribute } from "../Contribute";
import { SecondaryLink } from "../Contribute/SecondaryMarketLinks";

export const SignDocuments = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${isKycValid}",
  },
  children: [
    Contribute,
    {
      component: "@ambisafe/fe-statements/{IfTrue}",
      props: {
        if: "${project.areThereDocumentsToSign}"
      },
      children: {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          display: "flex"
        },
        children: [
          {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pr: "15px",
              width: "100%"
            },
            children: SecondaryLink("${match.url}/documents?redirectTo=${match.url}/secondary/buy", "buy", "${assignInvoiceContract}")
          },
          {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pl: "15px",
              width: "100%"
            },
            children: SecondaryLink("${match.url}/documents?redirectTo=${match.url}/secondary/buy", "sell", "${assignInvoiceContract}")
          }
        ]
      }
    }
  ]
};
