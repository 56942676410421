/* eslint-disable no-template-curly-in-string */

import EmailInput from "./EmailInput";
import EmailSent from "./EmailSent";
import ChangePassword from "./ChangePassword";
import PhoneConfirm, { MigrationPhoneConfirm } from "./PhoneConfirm";
import Success from "./Success";

export const Recovery = [
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/request"
    },
    children: EmailInput
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/request-success/:email?"
    },
    children: EmailSent
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/change-password"
    },
    children: ChangePassword
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/phone-code"
    },
    children: PhoneConfirm
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/migration-phone-code"
    },
    children: MigrationPhoneConfirm
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/confirm-new-password"
    },
    children: {
      component: "@orderbook/fe-confirm-new-password",

      children: {
        component: "@orderbook/shared/{ConfirmNewPassword}"
      }
    }
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/success"
    },
    children: Success
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/confirm-new-phone"
    },
    children: {
      component: "@orderbook/fe-confirm-change-phone",
      children: {
        component: "@orderbook/shared/{ConfirmPhoneNumber}"
      }
    }
  },
  {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: "${match.url}/discard"
    },
    children: {
      component: "@orderbook/fe-discard-recovery",
      children: {
        component: "@orderbook/shared/{DiscardRecovery}"
      }
    }
  }
];
