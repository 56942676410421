/* eslint-disable no-template-curly-in-string */
/*
const Category = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    position: "absolute",
    top: "10px",
    left: "0px"
  },
  children: {
    component: "@ambisafe/ui-background",
    props: {
      backgroundColor: "primary.main"
    },
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        p: "xxs"
      },
      children: {
        component: "@ambisafe/ui-text/{Heading6}",
        props: {
          color: "text.contrast",
          noBottomGutter: true
        },
        children: {
          component: "@ambisafe/ui-text/{Medium}",
          children: "${category}"
        }
      }
    }
  }
};
*/
const Logo = {
  component: "@ambisafe/ui-layout/{GridRow}",
  props: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  children: {
    component: "@ambisafe/ui-image",
    props: {
      src: "${logoImage}",
      lazy: true,
      height: "60%",
      width: "auto"
    }
  }
};

export const CoverImage = {
  component: "@ambisafe/ui-layout/{GridCol}",
  children: {
    component: "@ambisafe/ui-background",
    props: {
      backgroundImage: "url('${coverImage}')",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100%",
      height: "200px"
    },
    children: [
      Logo
    ]
  }
};
