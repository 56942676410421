/* eslint-disable no-template-curly-in-string */
export const FormFields = {
  component: "@ambisafe/ui-layout/{GridRow}",
  props: {},
  children: [
    {
      component: "@ambisafe/ui-layout/{GridCol}",
      props: {
        span: {
          mobile: 12,
          tablet: 12,
          tabletLandscape: 7,
          laptop: 6
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          mb: "xs"
        },
        children: {
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "withdrawToAddress",
            type: "text",
            label: "form.withdraw_to_address",
            placeholder: "",
            validate: ["required", ["length", 42]]
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-layout/{GridCol}",
      props: {
        span: {
          mobile: 12,
          tablet: 6,
          tabletLandscape: 2,
          laptop: 3
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          mb: "xs"
        },
        children: {
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "amount",
            type: "text",
            label: "form.amount",
            placeholder: "",
            validate: ["required"],
            onChange: "${handleWithdrawAmountChange}"
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-layout/{GridCol}",
      props: {
        span: {
          mobile: 12,
          tablet: 6,
          tabletLandscape: 3,
          laptop: 3
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          mb: "xs"
        },
        children: [
          {
            component: "@ambisafe/ui-text/{Paragraph}",
            props: {
              fontSize: "sm"
            },
            children: [
              {
                component: "@ambisafe/locale/{FormattedMessage}",
                props: {
                  id: "total"
                }
              },
              {
                component: "@ambisafe/fe-statements/{IfgtElse}",
                props: {
                  if: "${withdrawFee}",
                  else: "0"
                },
                children: [
                  {
                    component: "span",
                    children: " ("
                  },
                  {
                    component: "@ambisafe/locale/{FormattedMessage}",
                    props: {
                      id: "incl_fee"
                    }
                  },
                  {
                    component: "span",
                    children: " "
                  },
                  {
                    component: "span",
                    children: "${withdrawFee}"
                  },
                  {
                    component: "span",
                    children: "${displaySymbol}"
                  },
                  {
                    component: "span",
                    children: ")"
                  }
                ]
              }
            ]
          },
          {
            component: "@ambisafe/ui-text/{Paragraph}",
            props: {
              noBottomGutter: true,
              lineHeight: 1,
              fontSize: "lg"
            },
            children: "${totalWithdrawAmount}"
          },
          {
            component: "@ambisafe/ui-text/{Paragraph}",
            props: {
              noBottomGutter: true,
              color: "text.secondary",
              lineHeight: 1,
              fontSize: "sm"
            },
            children: [
              {
                component: "span",
                children: "≈"
              },
              {
                component: "@ambisafe/locale/{FormattedNumber}",
                props: {
                  value: "${totalWithdrawPriceInUSD}"
                }
              },
              {
                component: "span",
                children: " "
              },
              {
                component: "@ambisafe/locale/{FormattedMessage}",
                props: {
                  id: "usd"
                }
              }
            ]
          }
        ]
      }
    }
  ]
};
