/* eslint-disable no-template-curly-in-string */
const FooterLink = ({ name, path, newTab }) => ({
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    span: {
      mobile: 12,
      mobileLandscape: 4
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Container}",
    props: {
      margin: {
        mobile: "8px auto",
        mobileLandscape: "0 0"
      }
    },
    children: {
      component: "@ambisafe/react-router-dom/{Link}",
      props: {
        to: path,
        rel: "noopener noreferrer",
        target: newTab ? "_blank" : "",
        height: "100%"
      },
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            py: {
              mobileLandscape: "xxs",
              tablet: "xxs"
            }
          },
          children: {
            component: "@ambisafe/ui-text/{Heading5}",
            props: {
              color: "grey100",
              textAlign: "center"
            },
            children: {
              component: "@ambisafe/ui-text/{Normal}",
              children: {
                component: "react-intl/{FormattedMessage}",
                props: {
                  id: name
                }
              }
            }
          }
        }
      }
    }
  }
});

export const Legend = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    span: {
      mobile: 12,
      tablet: 6
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Container}",
    props: {
      margin: {
        mobileLandscape: "0 20px",
        tablet: "0 0"
      }
    },
    children: {
      component: "@ambisafe/ui-layout/{GridCol}",
      props: {
        minWidth: "320px"
      },
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        props: {
          display: "flex",
          flexWrap: "wrap",
          alignContent: "space-around",
        },
        children: [
          FooterLink({
            name: "Listing request",
            path: "https://form.ohanae.com/listing-request/",
            newTab: true
          }),
          FooterLink({
            name: "Recovery",
            path: "/recovery/request",
            newTab: false
          }),
          FooterLink({
            name: "Terms of service",
            path: "https://www.ohanae.com/docs/agreement.pdf",
            newTab: true
          }),
          FooterLink({
            name: "Help center",
            path: "https://www.ohanae.com/faq/",
            newTab: true
          }),
          FooterLink({
            name: "Assets list",
            path: "/account/assets",
            newTab: false
          }),
          FooterLink({
            name: "Trading API",
            path: "/trade",
            newTab: false
          }),
          FooterLink({
            name: "Privacy policy",
            path: "https://www.ohanae.com/docs/privacy.pdf",
            newTab: true
          })
        ]
      }
    }
  }
};
