/* eslint-disable no-template-curly-in-string */

export const TransactionHash = {
  component: "@ambisafe/ui-text/{Paragraph}",
  props: {
    lineHeight: 1,
    color: "text.secondary"
  },
  children: [
    {
      component: "@ambisafe/locale/{FormattedMessage}",
      props: {
        id: "transaction"
      }
    },
    {
      component: "span",
      children: ": "
    },
    {
      component: "@ambisafe/react-router-dom/{Link}",
      props: {
        to: "http://chainexplorer.westus2.cloudapp.azure.com/transactions/${hash}",
        target: "_blank",
        rel: "noopener noreferrer",
        display: "inline-block"
      },
      children: "${hashTruncated}"
    }
  ]
};
