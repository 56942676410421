/* eslint-disable no-template-curly-in-string */
export const Buttons = {
  component: "@ambisafe/ui-layout/{GridRow}",
  children: {
    component: "@ambisafe/ui-layout/{GridCol}",
    props: {
      span: 12,
      display: "flex"
    },
    children: [
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          pr: "15px",
          width: "100%"
        },
        children: {
          component: "@ambisafe/react-router-dom/{Link}",
          props: {
            to: "${projectPath}",
          },
          children: {
            component: "@ambisafe/ui-button",
            props: {
              width: "100%",
              type: "button"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                p: "xxs"
              },
              children: {
                component: "@ambisafe/ui-text/{Heading4}",
                props: {
                  noBottomGutter: true,
                  textAlign: "center"
                },
                children: {
                  component: "@ambisafe/ui-text/{Normal}",
                  children: {
                    component: "@ambisafe/locale/{FormattedMessage}",
                    props: {
                      id: "back"
                    }
                  }
                }
              }
            }
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          pl: "15px",
          width: "100%"
        },
        children: {
          component: "@ambisafe/ui-button",
          props: {
            width: "100%",
            type: "submit",
            disabled: "${SM_isOperationButtonDisabled}"
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: "xxs"
            },
            children: {
              component: "@ambisafe/ui-text/{Heading4}",
              props: {
                noBottomGutter: true,
                textAlign: "center"
              },
              children: {
                component: "@ambisafe/ui-text/{Normal}",
                children: {
                  component: "@ambisafe/locale/{FormattedMessage}",
                  props: {
                    id: "${match.params.operation}"
                  }
                }
              }
            }
          }
        }
      }
    ]
  }
};
