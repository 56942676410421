/* eslint-disable no-template-curly-in-string */
export const QrCode = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pb: "md",
    pt: "md"
  },
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    children: [
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {},
        children: [
          {
            component: "@ambisafe/fe-statements/{IfEqualTo}",
            props: {
              if: "${type}",
              to: "CRYPTO"
            },
            children: {
              component: "@ambisafe/ui-qrcode",
              props: {
                value: "${project.invoiceContract.invoiceAddress}"
              }
            }
          },
          {
            component: "@ambisafe/fe-statements/{IfEqualTo}",
            props: {
              if: "${type}",
              to: "BTC"
            },
            children: {
              component: "@ambisafe/ui-qrcode",
              props: {
                value: "${project.invoiceContract.btcInvoiceAddress}"
              }
            }
          }
        ]
      },
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          flex: 1
        },
        children: {
          component: "@ambisafe/ui-text/{Paragraph}",
          props: {
            noBottomGutter: true
          },
          children: {
            component: "react-intl/{FormattedMessage}",
            props: {
              id: "token_price_may_change_disclaimer"
            }
          }
        }
      }
    ]
  }
};
