/* eslint-disable no-template-curly-in-string */
import { SecondaryMarketLinks } from "./SecondaryMarketLinks";
import { PrimaryMarketLink } from "./PrimaryMarketLinks";

export const Contribute = {
  component: "@ambisafe/fe-statements/{IfFalse}",
  props: {
    if: "${project.areThereDocumentsToSign}"
  },
  children: [
    {
      component: "@ambisafe/fe-statements/{IfgtElse}",
      props: {
        if: "${project.endDate}",
        else: "${project.currentDate}"
      },
      children: {
        component: "@ambisafe/fe-statements/{IfgtElse}",
        props: {
          if: "${project.currentDate}",
          else: "${project.startDate}"
        },
        children: {
          component: "@ambisafe/fe-statements/{IfNotEqualTo}",
          props: {
            if: "${project.status}",
            to: "SECONDARY_MARKET"
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              mt: "xs"
            },
            children: PrimaryMarketLink
          }
        }
      }
    },
    {
      component: "@ambisafe/fe-statements/{IfTrue}",
      props: {
        if: "${project.projectPair.isActive}"
      },
      children: {
        component: "@ambisafe/fe-statements/{IfEqualTo}",
        props: {
          if: "${project.status}",
          to: "SECONDARY_MARKET"
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            mt: "xs"
          },
          children: SecondaryMarketLinks
        }
      }
    }
  ]
};
