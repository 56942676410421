const {
  REACT_APP_CLIENT_APP_SERVER_HTTP_ENDPOINT,
  REACT_APP_CLIENT_APP_SERVER_WS_ENDPOINT,
} = process.env;

const isLocalhost = window.location.href.indexOf('localhost') !== -1;

const generatedUrl = (url: string, protocol: 'https' | 'wss') => {
  //eslint-disable-next-line
  const regex = url.match(/((https|http):\/\/)(w{3,}\.)?((develop|staging)\.)?(([\w\d\-\.]{1,})[\.:])((\d{4,5})\.)?(\w{2,})\/?/);

  const exceptionHosts: { [key: string]: string } = {
    'platform.new.ohanae': `${protocol}://api.platform.prod.new.ohanae.com`,
    'platform.ohanae': `${protocol}://api.platform.ohanae.com`,
  };

  const exception = exceptionHosts[regex?.[7] || ''];
  if (exception) {
    return exception;
  }

  return `${protocol}://api.${regex?.[5] || 'sto'}.${regex?.[7] === 'orderbook' ? 'orderbook.io' : `${regex?.[7]}.ambisafe.com`}`;
};

const getEndpoint = (localUrl: string, url: string) => isLocalhost ? localUrl : url;

export function getHttpEndpoint(): string {
  return getEndpoint(
    REACT_APP_CLIENT_APP_SERVER_HTTP_ENDPOINT!,
    generatedUrl(window.location.href, 'https')
  );
}

export function getWsEndpoint(): string {
  return getEndpoint(
    REACT_APP_CLIENT_APP_SERVER_WS_ENDPOINT!,
    generatedUrl(window.location.href, 'wss')
  );
}
