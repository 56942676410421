/* eslint-disable no-template-curly-in-string */
import { ActionHistory } from "./ActionHistory";
import { ArrowRight } from "./ArrowRight";
import { Heading } from "./Heading";
import { NoActionsMessage } from "./NoActionsMessage";

export const ActionsHistory = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.url}/actions-history"
  },
  children: {
    component: "@orderbook/fe-actions-history",
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        mb: "xl"
      },
      children: [
        ArrowRight,
        Heading,
        NoActionsMessage,
        ActionHistory,
        {
          component: "@ambisafe/ui-layout/{Container}",
          props: {
            display: "flex",
            justifyContent: "right",
          },
          children: {
            component: "@ambisafe/ui-page-selector",
            props: {
              totalCount: "${pagesTotal}",
              isLoading: "${actionsLoading}",
              onPageChange: "${handlePageChange}",
              isVisible: "${shouldShowPagination}"
            }
          }
        }
      ]
    }
  }
};
