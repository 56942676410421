export const RestrictedLocation = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    position: "fixed",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    top: "0px",
    left: "0px",
    zIndex: "999"
  },
  children: {
    component: "@ambisafe/ui-background",
    props: {
      backgroundColor: "elements.bodyBackground",
      width: "100%",
      height: "100%",
      minHeight: "100vh"
    },
    children: {
      component: "@ambisafe/ui-layout/{GridContainer}",
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        props: {
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          minHeight: "100vh"
        },
        children: {
          component: "@orderbook/fe-location-restriction/{RestrictedLocationPage}"
        }
      }
    }
  }
};
