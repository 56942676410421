/* eslint-disable no-template-curly-in-string */

export const Document = (
  projectId = "${projectId}",
  serverCode = undefined,
  url = "${match.url}/view/:documentId"
) => ({
  component: "@ambisafe/react-router-dom/{WithRouter}",
  children: {
    component: "@ambisafe/react-router-dom/{Route}",
    props: {
      path: url,
    },
    children: {
      component: "@orderbook/fe-project-show-document",
      props: {
        projectId,
        documentId: "${match.params.documentId}",
        serverCode,
      },
      children: {
        component: "@ambisafe/ui-fullscreen-modal",
        props: {
          onClose: "${onDocumentClose}"
        },
        children: {
          component: "@ambisafe/ui-show-base64-file",
          props: {
            fileBase64: "${fileBase64}"
          }
        }
      }
    }
  }
});
