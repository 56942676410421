/* eslint-disable no-template-curly-in-string */
import { AmountToPay } from "./AmountToPay";
import { AmountToGet } from "./AmountToGet";
import { Discount } from "./Discount";
import { Fee } from "./Fee";
import { BuyButton, EmailCode } from "./Controls";
import { OperationDescription } from "../OperationDescritpion";
import { CurrentTokenPrice } from "./CurrentTokenPrice";

export const DepositCalculator = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mt: "sm"
  },
  children: [
    {
      component: "@orderbook/fe-deposit-calculator",
      props: {
        counterAssetDisplaySymbol: "${displaySymbol}",
        platformBuyerFee: "${platformBuyerFee}",
        project: "${project}",
        user: "${user}",
      },
      children: [
        {
          component: "@ambisafe/ui-form/{Form}",
          props: {
            onSubmit: "${handleWithdrawalRequest}",
            formOnChange: "${handleCalculatorFormChange}"
          },
          children: [
            CurrentTokenPrice,
            OperationDescription,
            AmountToPay,
            AmountToGet,
            Discount,
            Fee,
            BuyButton
          ]
        },
        EmailCode
      ]
    }
  ]
};
