/* eslint-disable no-template-curly-in-string */

export const DisplaySymbol = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: "160px"
  },
  children: {
    component: "@ambisafe/ui-text/{Paragraph}",
    props: {
      noBottomGutter: true,
      fontSize: "lg"
    },
    children: "${displaySymbol}"
  }
};
