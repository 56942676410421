/* eslint-disable no-template-curly-in-string */

export const TimeInfo = {
  component: "@ambisafe/ui-layout/{GridRow}",
  children: [
    {
      component: "@ambisafe/ui-layout/{GridCol}",
      children: {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          lineHeight: 1,
          noBottomGutter: true,
          color: "text.secondary"
        },
        children: [
          {
            component: "@ambisafe/locale/{FormattedDate}",
            props: {
              value: "${createdAt}",
              year: "numeric",
              month: "long",
              day: "2-digit"
            }
          }
        ]
      }
    },
    {
      component: "@ambisafe/ui-layout/{GridCol}",
      children: {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          lineHeight: 1,
          noBottomGutter: true,
          color: "text.secondary"
        },
        children: [
          {
            component: "@ambisafe/locale/{FormattedTime}",
            props: {
              value: "${createdAt}",
              hour: "numeric",
              minute: "numeric",
              second: "numeric"
            }
          }
        ]
      }
    }
  ]
};
