/* eslint-disable no-template-curly-in-string */
export const Login = {
  component: "@orderbook/fe-ohanae-login",
  children: {
    component: "@ambisafe/ui-layout/{GridContainer}",
    children: {
      component: "@ambisafe/ui-layout/{GridRow}",
      props: {
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        minHeight: "calc(100vh - 54px)"
      },
      children: {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          span: {
            mobile: 12,
            tablet: 6,
            tabletLandscape: 4
          }
        },
        children: {
          component: "@ambisafe/ui-layout/{Container}",
          props: {
            borderRadius: "30px",
            overflow: "hidden"
          },
          children: {
            component: "@ambisafe/ui-background",
            props: {
              backgroundColor: "elements.cardBackground"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                p: {
                  mobile: "md",
                  tablet: "xl"
                }
              },
              children: [
                {
                  component: "@ambisafe/ui-text/{Heading2}",
                  props: {
                    textAlign: "center"
                  },
                  children: {
                    component: "@ambisafe/locale/{FormattedMessage}",
                    props: {
                      id: "sign_in_with_ohanae"
                    }
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{GridRow}",
                  props: {
                    justifyContent: "center"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{GridCol}",
                    children: {
                      component: "@ambisafe/ui-qrcode",
                      props: {
                        value: "${loginQRCode}",
                        size: 256
                      }
                    }
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    mt: "xs"
                  },
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      noBottomGutter: true,
                      textAlign: "center"
                    },
                    children: {
                      component: "react-intl/{FormattedMessage}",
                      props: {
                        id: "scan_qr_with_ohanae_app_to_log_in"
                      }
                    }
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    mt: "xs"
                  },
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      noBottomGutter: true,
                      textAlign: "center"
                    },
                    children: [
                      {
                        component: "@ambisafe/react-router-dom/{Link}",
                        props: {
                          to:
                            "ohanae://ohanaeconnect/v1.0/?secret=${loginData.secret}&sid=${loginData.sid}&exp=${loginData.exp}#Intent;scheme=ohanae;package=ohanae.android.mobile;end",
                          target: "_blank",
                          rel: "noopener noreferrer"
                        },
                        children: {
                          component: "react-intl/{FormattedMessage}",
                          props: {
                            id: "sign_in"
                          }
                        }
                      },
                      {
                        component: "span",
                        children: " "
                      },
                      {
                        component: "react-intl/{FormattedMessage}",
                        props: {
                          id: "ohanae_sign_in_from_windows_or_mac"
                        }
                      }
                    ]
                  }
                },
                {
                  component: "@ambisafe/fe-statements/{IfEqualTo}",
                  props: {
                    if: "${loginType}",
                    to: "REGISTER"
                  },
                  children: {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      mt: "sm"
                    },
                    children: {
                      component: "@ambisafe/ui-form/{Form}",
                      props: {
                        onSubmit: "${handleRegisterSubmit}"
                      },
                      children: [
                        {
                          component: "@ambisafe/ui-layout/{GridRow}",
                          children: [
                            {
                              component: "@ambisafe/ui-layout/{GridCol}",
                              props: {
                                span: {
                                  mobile: 12
                                }
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  pb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-form/{Field}",
                                  props: {
                                    name: "termsOfServiceAgreement",
                                    type: "checkbox",
                                    label: "ohanae_i_accept_term_of_service_and_privacy_policy",
                                    validate: ["required"]
                                  }
                                }
                              }
                            }
                          ]
                        },
                        {
                          component: "@ambisafe/ui-layout/{GridRow}",
                          children: [
                            {
                              component: "@ambisafe/ui-layout/{GridCol}",
                              props: {
                                span: {
                                  mobile: 12
                                }
                              },
                              children: {
                                component: "@ambisafe/ui-layout/{Spacing}",
                                props: {
                                  pb: "xs"
                                },
                                children: {
                                  component: "@ambisafe/ui-form/{Field}",
                                  props: {
                                    name: "subscriptionAgreement",
                                    type: "checkbox",
                                    label: "ohanae_subscription_agreement"
                                  }
                                }
                              }
                            }
                          ]
                        },
                        {
                          component: "@ambisafe/fe-statements/{IfTrue}",
                          props: {
                            if: "${submitErrors}"
                          },
                          children: {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              pb: "xs"
                            },
                            children: {
                              component: "@ambisafe/ui-form/{SubmitError}"
                            }
                          }
                        },
                        {
                          component: "@ambisafe/ui-layout/{Spacing}",
                          props: {
                            pb: "xxs"
                          },
                          children: {
                            component: "@ambisafe/ui-button",
                            props: {
                              type: "submit",
                              width: "100%"
                            },
                            children: {
                              component: "@ambisafe/ui-layout/{Spacing}",
                              props: {
                                py: "xxs",
                                px: "xs"
                              },
                              children: {
                                component: "@ambisafe/ui-text/{Paragraph}",
                                props: {
                                  noBottomGutter: true,
                                  textAlign: "center",
                                  textTransform: "uppercase"
                                },
                                children: {
                                  component: "react-intl/{FormattedMessage}",
                                  props: {
                                    id: "register"
                                  }
                                }
                              }
                            }
                          }
                        }
                      ]
                    }
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    mt: "xs"
                  },
                  children: {
                    component: "@ambisafe/ui-text/{Paragraph}",
                    props: {
                      noBottomGutter: true,
                      textAlign: "center"
                    },
                    children: [
                      {
                        component: "react-intl/{FormattedMessage}",
                        props: {
                          id: "dont_have_ohanae"
                        }
                      },
                      {
                        component: "span",
                        children: " "
                      },
                      {
                        component: "react-intl/{FormattedMessage}",
                        props: {
                          id: "signup"
                        }
                      },
                      {
                        component: "span",
                        children: " "
                      },
                      {
                        component: "@ambisafe/react-router-dom/{Link}",
                        props: {
                          to:
                            "https://ohanae.com/register",
                          target: "_blank",
                          rel: "noopener noreferrer"
                        },
                        children: {
                          component: "react-intl/{FormattedMessage}",
                          props: {
                            id: "signup.here"
                          }
                        }
                      }
                    ]
                  }
                },
                /**
                 * Ohanae request to delete Link to Mac OS and hide the rest until good times come.
                 * I comment also Mac OS link because deleting implementation is bad practice.
                 * Have chance that it will be need again in future.
                 * 
                 * V2: Ohanae has requested to delete all icons and change text.
                 */
                // {
                //   component: "@ambisafe/ui-layout/{Spacing}",
                //   props: {
                //     mt: "xs"
                //   },
                //   children: {
                //     component: "@ambisafe/ui-layout/{GridRow}",
                //     props: {
                //       justifyContent: "center"
                //     },
                //     children: [
                //       {
                //         component: "@ambisafe/ui-layout/{GridCol}",
                //         children: {
                //           component: "@ambisafe/react-router-dom/{Link}",
                //           props: {
                //             to: "https://apps.apple.com/us/app/ohanae/id748278967",
                //             target: "_blank",
                //             rel: "noopener noreferrer"
                //           },
                //           children: {
                //             component: "@ambisafe/ui-image",
                //             props: {
                //               src: "/images/ios.png",
                //               width: "150px",
                //               height: "45px"
                //             }
                //           }
                //         }
                //       },
                //       // {
                //       //   component: "@ambisafe/ui-layout/{GridCol}",
                //       //   children: {
                //       //     component: "@ambisafe/react-router-dom/{Link}",
                //       //     props: {
                //       //       to: "https://apps.apple.com/sg/app/ohanae/id1200684542",
                //       //       target: "_blank",
                //       //       rel: "noopener noreferrer"
                //       //     },
                //       //     children: {
                //       //       component: "@ambisafe/ui-image",
                //       //       props: {
                //       //         src: "/images/macos.png",
                //       //         width: "150px",
                //       //         height: "45px"
                //       //       }
                //       //     }
                //       //   }
                //       // }
                //     ]
                //   }
                // },
                // {
                //   component: "@ambisafe/ui-layout/{Spacing}",
                //   props: {
                //     mt: "xs"
                //   },
                //   children: {
                //     component: "@ambisafe/ui-layout/{GridRow}",
                //     props: {
                //       justifyContent: "center"
                //     },
                //     children: [
                //       {
                //         component: "@ambisafe/ui-layout/{GridCol}",
                //         children: {
                //           component: "@ambisafe/react-router-dom/{Link}",
                //           props: {
                //             to: "https://play.google.com/store/apps/details?id=ohanae.android.mobile",
                //             target: "_blank",
                //             rel: "noopener noreferrer"
                //           },
                //           children: {
                //             component: "@ambisafe/ui-image",
                //             props: {
                //               src: "/images/googleplay.png",
                //               width: "150px",
                //               height: "45px"
                //             }
                //           }
                //         }
                //       },
                //       {
                //         component: "@ambisafe/ui-layout/{GridCol}",
                //         children: {
                //           component: "@ambisafe/react-router-dom/{Link}",
                //           props: {
                //             to: "https://www.microsoft.com/en-sg/p/ohanae/9wzdncrdx2br?activetab=pivot:overviewtab",
                //             target: "_blank",
                //             rel: "noopener noreferrer"
                //           },
                //           children: {
                //             component: "@ambisafe/ui-image",
                //             props: {
                //               src: "/images/windowsstore.png",
                //               width: "150px",
                //               height: "45px"
                //             }
                //           }
                //         }
                //       }
                //     ]
                //   }
                // }
              ]
            }
          }
        }
      }
    }
  }
};
