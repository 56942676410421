/* eslint-disable no-template-curly-in-string */
export const InvoiceAddress = {
  component: "@ambisafe/ui-background",
  props: {
    backgroundColor: "grey300"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      p: "xs"
    },
    children: {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        noBottomGutter: true
      },
      children: [
        {
          component: "@ambisafe/fe-statements/{IfEqualTo}",
          props: {
            if: "${type}",
            to: "CRYPTO"
          },
          children: "${project.invoiceContract.invoiceAddress}"
        },
        {
          component: "@ambisafe/fe-statements/{IfEqualTo}",
          props: {
            if: "${type}",
            to: "BTC"
          },
          children: "${project.invoiceContract.btcInvoiceAddress}"
        }
      ]
    }
  }
};
