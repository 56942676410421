/* eslint-disable no-template-curly-in-string */

import { Assets } from "./Assets";
import { MobileBackLink } from "./MobileBackLink";
import { Heading } from "./Heading";

export const Portfolio = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.url}/assets"
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        mb: "xl"
      },
      children: [
        MobileBackLink,
        Heading,
        Assets
      ]
    }
  ]
};
