/* eslint-disable no-template-curly-in-string */
import { ActiveProjectInfo } from "./Info/ActiveProjectInfo";
import { UpcomingProjectInfo } from "./Info/UpcomingProjectInfo";
import { Links } from "./Links";

export const InfoAndLinks = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    exact: true,
    path: "${match.url}"
  },
  children: [
    ActiveProjectInfo,
    UpcomingProjectInfo,
    Links
  ]
};
