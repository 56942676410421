/* eslint-disable no-template-curly-in-string */
const ClipboardButton = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pl: "13px",
    pt: "5px"
  },
  children: {
    component: "@orderbook/fe-clipboard-button",
    props: {
      copyValue: "${payableActionData.invoiceAddress}",
      hideTooltip: true
    }
  }
};

export const InvoiceAddress = {
  component: "@ambisafe/ui-background",
  props: {
    backgroundColor: "grey300"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      p: "xxs"
    },
    children: {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        display: "flex",
        alignItems: "center",
        maxWidth: "100%"
      },
      children: [
        {
          component: "@ambisafe/ui-layout/{Container}",
          children: {
            component: "@ambisafe/ui-text/{Paragraph}",
            props: {
              fontSize: {
                mobile: "0.65em",
                mobileLandscape: "0.85em"
              },
              noBottomGutter: true
            },
            children: "${payableActionData.invoiceAddress}"
          }
        },
        ClipboardButton
      ]
    }
  }
};
