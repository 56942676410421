/* eslint-disable no-template-curly-in-string */
export const CurrentTokenPrice = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pb: "md"
  },
  children: {
    component: "@ambisafe/ui-text/{Heading5}",
    props: {
      noBottomGutter: true
    },  
    children: {
      component: "@ambisafe/locale/{FormattedMessage}",
      props: {
        id: "project.current_price_per_token",
        values: {
          currentTokenPrice: "${DC_currentTokenPrice.counterCurrency}",
          displaySymbol: "${primaryCounterAsset.displaySymbol}"
        }
      }
    }
  }
};
