/* eslint-disable no-template-curly-in-string */
export default {
  component: "@ambisafe/ui-layout/{GridContainer}",
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    props: {
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      minHeight: "calc(100vh - 54px)"
    },
    children: {
      component: "@ambisafe/ui-layout/{GridCol}",
      props: {
        span: {
          mobile: 12,
          tablet: 6,
          tabletLandscape: 4
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{Container}",
        props: {
          borderRadius: "30px",
          overflow: "hidden"
        },
        children: {
          component: "@ambisafe/ui-background",
          props: {
            backgroundColor: "white100"
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              p: {
                mobile: "md",
                tablet: "xl"
              }
            },
            children: [
              {
                component: "@ambisafe/ui-text/{Heading2}",
                props: {
                  color: "primary.main",
                  textAlign: "center"
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "recovery_email_successfully_sent_title"
                  }
                }
              },
              {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  noBottomGutter: true,
                  textAlign: "center"
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "recovery_email_successfully_sent_description"
                  }
                }
              },
              {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  textAlign: "center"
                },
                children: {
                  component: "@ambisafe/ui-text/{Medium}",
                  children: "${match.params.email}"
                }
              },
              {
                component: "@ambisafe/ui-text/{Paragraph}",
                props: {
                  noBottomGutter: true,
                  textAlign: "center"
                },
                children: {
                  component: "react-intl/{FormattedMessage}",
                  props: {
                    id: "recovery_email_successfully_sent_instructions"
                  }
                }
              }
            ]
          }
        }
      }
    }
  }
};
