/* eslint-disable no-template-curly-in-string */
import { SignDocuments } from "./SignDocuments";
import { Purchase } from "./Purchase";
import { InfoAndLinks } from "./InfoAndLinks";

export const PurchasePanel = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mb: "xl"
  },
  children: {
    component: "@ambisafe/ui-layout/{Container}",
    props: {
      position: "relative",
      borderRadius: "10px",
      overflow: "hidden",
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.05)"
    },
    children: {
      component: "@orderbook/fe-user",
      children: {
        component: "@ambisafe/ui-background",
        props: {
          backgroundColor: "white100"
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            p: "md"
          },
          children: {
            component: "@ambisafe/react-router-dom/{WithRouter}",
            children: {
              component: "@ambisafe/react-router-dom/{Switch}",
              children: [
                InfoAndLinks,
                SignDocuments,
                Purchase
              ]
            }
          }
        }
      }
    }
  }
};
