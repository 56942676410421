/* eslint-disable no-template-curly-in-string */
import { UserBalanceInfo } from "./UserBalanceInfo";
import { DepositCalculator } from "./DepositCalculator";
import { ProcessingFiatAsset } from "./ProcessingFiatAsset";
import { ProcessingCryptoAsset } from "./ProcessingCryptoAsset";
import { Processing } from "./Processing";
import { BackButton } from "./BackButton";

export const PaymentForm = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    // exact: true,
    path: "${match.url}",
    displaySymbol: "${match.params.displaySymbol}"
  },
  children: {
    component: "@ambisafe/fe-helpers/{Find}",
    props: {
      findBy: {
        displaySymbol: "${displaySymbol}"
      },
      array: "${project.assets}"
    },
    children: [
      BackButton,
      UserBalanceInfo,
      DepositCalculator,
      Processing,
      // ProcessingFiatAsset,
      ProcessingCryptoAsset,
    ]
  }
};
