/* eslint-disable no-template-curly-in-string */
export const DepositAddress = {
  component: "@ambisafe/ui-layout/{GridCol}",
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: {
          component: "@ambisafe/locale/{FormattedMessage}",
          props: {
            id: "deposit_address"
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        noBottomGutter: true,
        fontSize: "lg"
      },
      children: [
        {
          component: "@ambisafe/fe-statements/{IfNotEqualTo}",
          props: {
            if: "${type}",
            to: "BTC"
          },
          children: "${depositAddress}"
        },
        {
          component: "@ambisafe/fe-statements/{IfEqualTo}",
          props: {
            if: "${type}",
            to: "BTC"
          },
          children: "${btcDepositAddress}"
        }
      ]
    }
  ]
};
