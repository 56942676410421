/* eslint-disable no-template-curly-in-string */
import { NotEnoughBalanceMessage } from "./NotEnougthBalance";
import { ConfirmToPayMessage } from "./ConfirmToPay";
import { SuccessMessage } from "./ResultMessage";

const CloseButton = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    position: "absolute",
    top: "0.8em",
    right: "1em",
    onClick: "${onCloseHandler}",
    cursor: "pointer"
  },
  children: {
    component: "span",
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      children: {
        component: "@material-ui/icons/{Close}",
        props: {
          style: { color: "#919191" }
        }
      }
    }
  }
};

export const getMessage = () => [
  {
    component: "@ambisafe/fe-statements/{IfFalse}",
    props: {
      if: "${payableActionData.isEnoughBalance}"
    },
    children: NotEnoughBalanceMessage
  },
  {
    component: "@ambisafe/fe-statements/{IfTrue}",
    props: {
      if: "${payableActionData.isEnoughBalance}"
    },
    children: ConfirmToPayMessage
  },
  CloseButton
];
