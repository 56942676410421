/* eslint-disable no-template-curly-in-string */

import { NonTwoFA } from "./NonTwoFA";
import { TwoFA } from "./TwoFA";

export const Withdraw = {
  component: "@ambisafe/fe-statements/{IfEqualTo}",
  props: {
    if: "${assetId}",
    to: "${selectedForWithdrawAssetId}"
  },
  children: [
    NonTwoFA,
    TwoFA
  ]
};
