/* eslint-disable no-template-curly-in-string */

export const CounterAmountInUSD = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${priceInUSD}"
  },
  children: {
    component: "@ambisafe/ui-text/{Paragraph}",
    props: {
      noBottomGutter: true,
      lineHeight: 1,
      color: "text.secondary"
    },
    children: [
      {
        component: "@ambisafe/locale/{FormattedNumber}",
        props: {
          value: "${priceInUSD}"
        }
      },
      {
        component: "span",
        children: " "
      },
      {
        component: "@ambisafe/locale/{FormattedMessage}",
        props: {
          id: "usd"
        }
      }
    ]
  }
};
