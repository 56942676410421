/* eslint-disable no-template-curly-in-string */
export const UserBalanceInfo = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mb: "sm"
  },
  children: {
    component: "@ambisafe/fe-helpers/{Find}",
    props: {
      findBy: { assetId: "${assetId}" },
      array: "${user.assets}"
    },
    children: {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true
      },
      children: [
        {
          component: "react-intl/{FormattedMessage}",
          props: {
            id: "you_have"
          }
        },
        {
          component: "span",
          children: " "
        },
        {
          component: "@ambisafe/locale/{FormattedNumber}",
          props: {
            value: "${balance}"
          }
        },
        {
          component: "span",
          children: " "
        },
        {
          component: "span",
          children: "${displaySymbol}"
        },
        {
          component: "span",
          children: " "
        },
        {
          component: "react-intl/{FormattedMessage}",
          props: {
            id: "on_your_account"
          }
        }
      ]
    }
  }
};
