/* eslint-disable no-template-curly-in-string */
import { Congratulations } from "./Congratulations";
import { PaymentForm } from "./PaymentForm";
import { Document } from "../../../SignDocuments/Document";

export const Payment = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.url}/:displaySymbol"
  },
  children: [
    {
      component: "@ambisafe/fe-statements/{IfFalse}",
      props: {
        if: "${isInvoiceInDoneState}"
      },
      children: PaymentForm
    },
    Congratulations,
    Document(
      "${project.id}",
      "${selectedForBuyServerCode}",
      "${match.url}/view/:documentId"
    ),
  ]
};
