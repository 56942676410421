/* eslint-disable no-template-curly-in-string */
export const ProjectLearnMore = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${project.externalLink}"
  },
  children: {
    component: "@ambisafe/ui-layout/{GridContainer}",
    children: {
      component: "@ambisafe/ui-layout/{GridRow}",
      props: {
        flexDirection: {
          mobile: "column",
          tablet: "row"
        }
      },
      children: {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          span: {
            mobile: 12,
            tablet: 7
          }
        },
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            mb: "xl"
          },
          children: {
            component: "@ambisafe/react-router-dom/{Link}",
            props: {
              to: "${project.externalLink}",
              target: "_blank"
            },
            children: {
              component: "@ambisafe/ui-layout/{GridRow}",
              props: {
                alignItems: "center",
                justifyContent: "center"
              },
              children: {
                component: "@ambisafe/ui-button",
                props: {
                  width: "60%"
                },
                children: {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    py: "xxs",
                    px: "xs"
                  },
                  children: {
                  component: "@ambisafe/ui-text/{Heading4}",
                    props: {
                      noBottomGutter: true,
                      textAlign: "center"
                    },
                    children: {
                      component: "@ambisafe/ui-text/{Normal}",
                      children: "Learn More"
                    }
                  }
                }
              }
            }
          } 
        }
      }
    }
  }
};
