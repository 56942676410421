/* eslint-disable no-template-curly-in-string */

export const Title = {
  component: "@ambisafe/ui-layout/{Spacing}",
  children: {
    component: "@ambisafe/ui-text/{Heading4}",
    props: {
      noBottomGutter: true
    },
    children: {
      component: "@ambisafe/ui-text/{Medium}",
      children: "${title}"
    }
  }
};
