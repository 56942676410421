/* eslint-disable no-template-curly-in-string */
import { Steps } from "./Steps";

export const QrCode = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pb: "md",
    pt: "md"
  },
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    children: [
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          display: {
            mobile: "none",
            tablet: "block"
          }
        },
        children: {
          component: "@ambisafe/fe-statements/{IfTrue}",
          props: {
            if: "${payableActionData.invoiceAddress}"
          },
          children: {
            component: "@ambisafe/ui-qrcode",
            props: {
              value: "${payableActionData.invoiceAddress}"
            }
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          flex: 1
        },
        children: Steps
      }
    ]
  }
};
