/* eslint-disable no-template-curly-in-string */
import { CoverImage } from "./CoverImage";
import { Title } from "./Title";
import { OfferingType } from "./OfferingType";
import { OfferingSize } from "./OfferingSize";
import { StartDate } from "./StartDate";
import { EndDate } from "./EndDate";
import { MinInvestmentInUSD } from "./MinInvestmentInUSD";
import { HardCap } from "./HardCap";

export const SecondaryProject = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    span: {
      mobile: 12,
      tabletLandscape: 4
    }
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      mb: "sm"
    },
    children: {  
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        borderRadius: "10px",
        overflow: "hidden",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.05)"
      },
      children: {
        component: "@ambisafe/react-router-dom/{Link}",
        props: {
          to: "/projects/${link}"
        },
        children: {
          component: "@ambisafe/ui-layout/{GridRow}",
          props: {
            width: "100%",
            height: "100%",
          },
          children: [
            CoverImage,
            {
              component: "@ambisafe/ui-layout/{GridCol}",
              props: {
                flex: 1
              },
              children: {
                component: "@ambisafe/ui-background",
                props: {
                  backgroundColor: "white100",
                  height: "100%",
                },
                children: {
                  component: "@ambisafe/ui-layout/{Container}",
                  children: {
                    component: "@ambisafe/ui-layout/{Spacing}",
                    props: {
                      px: "xs",
                      py: "sm"
                    },
                    children: [
                      Title,
                      {
                        component: "@ambisafe/ui-layout/{Spacing}",
                        children: [
                          {
                            component: "@ambisafe/ui-divider"
                          },
                          {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              pb: "xs"
                            },
                            children: [
                              {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  justifyContent: "space-between"
                                },
                                children: [
                                  OfferingType,
                                  OfferingSize
                                ]
                              }
                            ]
                          },
                          {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              pb: "xs"
                            },
                            children: [
                              {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  justifyContent: "space-between"
                                },
                                children: [
                                  StartDate,
                                  EndDate
                                ]
                              }
                            ]
                          },
                          {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            children: [
                              {
                                component: "@ambisafe/ui-layout/{GridRow}",
                                props: {
                                  justifyContent: "space-between"
                                },
                                children: [
                                  MinInvestmentInUSD,
                                  HardCap
                                ]
                              }
                            ]
                          }
                        ]    
                      },
                      {                     
                        component: "@ambisafe/fe-statements/{IfTrue}",
                        props: {
                          if: "${projectPair.isActive}"
                        },
                        children: [
                          {
                            component: "@ambisafe/ui-layout/{Spacing}",
                            props: {
                              mt: "xs"
                            },
                            children: {
                              component: "@ambisafe/react-router-dom/{Link}",
                              props: {
                                to: "/projects/${link}"
                              },
                              children: {
                                component: "@ambisafe/ui-button",
                                props: {
                                  width: "100%"
                                },
                                children: {
                                  component: "@ambisafe/ui-layout/{Spacing}",
                                  props: {
                                    p: "xxs",
                                  },
                                  children: {
                                    component: "@ambisafe/ui-text/{Heading4}",
                                    props: {
                                      noBottomGutter: true,
                                      textAlign: "center",
                                      textTransform: "uppercase"
                                    },
                                    children: {
                                      component: "@ambisafe/ui-text/{Normal}",
                                      children: {
                                        component: "react-intl/{FormattedMessage}",
                                        props: {
                                          id: "trade"
                                        }
                                      }
                                    }
                                  }
                                }
                              }  
                            }
                          }
                        ]
                      }
                    ]
                  }
                }
              }
            }
          ]
        }
      }
    }
  }
};
