/* eslint-disable no-template-curly-in-string */
import { Divider, Field } from "../FieldSets";

const AddressFields = {
  component: "@ambisafe/ui-layout/{Spacing}",
  children: [
    Divider,
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "crpHumanHolders[fieldIndex].countryOfResidence",
            type: "select",
            label: "form.country_of_residence",
            placeholder: "",
            validate: ["required"],
            options: "${countries}"
          }
        }),
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "crpHumanHolders[fieldIndex].city",
            type: "text",
            label: "form.city",
            placeholder: "",
            validate: ["required"]
          }
        })
      ]
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "crpHumanHolders[fieldIndex].state",
            type: "text",
            label: "form.state",
            placeholder: "",
            validate: ["required"]
          }
        }),
        Field({
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "crpHumanHolders[fieldIndex].street",
            type: "text",
            label: "form.street",
            placeholder: "",
            validate: ["required"]
          }
        })
      ]
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "crpHumanHolders[fieldIndex].building",
              type: "text",
              label: "form.building",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "crpHumanHolders[fieldIndex].apartment",
              type: "text",
              label: "form.apartment",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        ),
        Field(
          {
            component: "@ambisafe/ui-form/{Field}",
            props: {
              name: "crpHumanHolders[fieldIndex].postalCode",
              type: "text",
              label: "form.postcode",
              placeholder: "",
              validate: ["required"]
            }
          },
          4
        )
      ]
    },
    Divider
  ]
};

export const HumanHolder = {
  component: "@ambisafe/ui-layout/{Container}",
  props: {
    borderWidth: "20px",
    borderColor: "transparent",
    boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.15)",
    px: "sm"
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        position: "relative"
      },
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        prop: {
          py: "xxs"
        },
        children: [
          {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              mb: "xl"
            },
            children: ""
          },
          {
            component: "@ambisafe/ui-layout/{Container}",
            props: {
              position: "absolute",
              top: "0px",
              left: "100px",
              height: "80px",
              borderColor: "gray",
              boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.15)"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                py: "xs"
              },
              children: {
                component: "@ambisafe/ui-text/{Heading3}",
                children: {
                  component: "@ambisafe/ui-text/{Bold}",
                  children: "${displayIndex}"
                }
              }
            }
          },
          {
            component: "@ambisafe/ui-layout/{Container}",
            props: {
              type: "button",
              position: "absolute",
              top: "10px",
              right: "30px"
            },
            children: {
              component: "@ambisafe/ui-button",
              props: {
                onClick: "${formDispatch}, ${removeIndividualHolder}, ${index}",
                colors: {
                  default: {
                    color: "text.contrast",
                    borderColor: "transparent",
                    backgroundColor: "transparent"
                  },
                  disabled: {
                    color: "text.contrast",
                    borderColor: "transparent",
                    backgroundColor: "transparent"
                  },
                  hovered: {
                    color: "text.contrast",
                    borderColor: "transparent",
                    backgroundColor: "transparent"
                  },
                  focused: {
                    color: "text.contrast",
                    borderColor: "transparent",
                    backgroundColor: "transparent"
                  }
                }
              },
              children: {
                component: "@ambisafe/ui-icons/{Close}",
                props: {
                  height: "35px",
                  width: "35px"
                }
              }
            }
          }
        ]
      }
    },
    {
      component: "@ambisafe/ui-layout/{Container}",
      props: {
        height: "50px"
      }
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      children: [
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].firstName",
                index: "${index}",
                type: "text",
                label: "form.first_name",
                placeholder: "",
                validate: ["required", "requiredLatinOnly", ["minLength", 2], ["maxLength", 250]]
              }
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].lastName",
                index: "${index}",
                type: "text",
                label: "form.last_name",
                placeholder: "",
                validate: ["required", "requiredLatinOnly", ["minLength", 2], ["maxLength", 250]]
              }
            })
          ]
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].dateOfBirth",
                index: "${index}",
                type: "date",
                label: "form.date_of_birth",
                placeholder: ""
              }
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].phone",
                index: "${index}",
                type: "text",
                label: "form.phone_number",
                placeholder: ""
              }
            })
          ]
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].citizenship",
                index: "${index}",
                type: "select",
                label: "form.citizenship",
                placeholder: "",
                validate: ["required"],
                options: "${countries}"
              }
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].gender",
                index: "${index}",
                type: "select",
                label: "form.gender",
                placeholder: "",
                validate: ["required"],
                options: "${genderTypesList}"
              }
            })
          ]
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].title",
                index: "${index}",
                type: "text",
                label: "form.title",
                placeholder: ""
              }
            })
          ]
        }
      ]
    },
    AddressFields,
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      children: [
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].role",
                index: "${index}",
                type: "select",
                label: "form.role",
                placeholder: "",
                validate: ["required", ["minLength", 2]],
                options: "${roleTypesList}"
              }
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].status",
                index: "${index}",
                type: "select",
                label: "form.status",
                placeholder: "",
                validate: ["required"],
                options: "${statusTypesList}"
              }
            })
          ]
        },
        {
          component: "@ambisafe/ui-layout/{GridRow}",
          children: [
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].dateAppointed",
                index: "${index}",
                type: "date",
                validate: ["required"],
                label: "form.date_appointed",
                placeholder: ""
              }
            }),
            Field({
              component: "@ambisafe/ui-form/{Field}",
              props: {
                name: "crpHumanHolders[fieldIndex].dateResigned",
                index: "${index}",
                type: "date",
                label: "form.date_resigned",
                placeholder: ""
              }
            })
          ]
        }
      ]
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        py: "sm",
        mb: "sm"
      },
      children: ""
    }
  ]
};
