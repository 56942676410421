/* eslint-disable no-template-curly-in-string */
export const AmountToGet = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pb: "md"
  },
  children: {
    component: "@ambisafe/ui-layout/{GridRow}",
    props: {
      justifyContent: "spaceBetween"
    },
    children: [
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          span: 3
        },
        children: {
          component: "@ambisafe/ui-text/{Paragraph}",
          props: {
            noBottomGutter: true
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              mt: "10px"
            },
            children: {
              component: "react-intl/{FormattedMessage}",
              props: {
                id: "you_will_receive"
              }
            }
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          width: "230px"
        },
        children: {
          component: "@ambisafe/ui-form/{Field}",
          props: {
            name: "baseAmount",
            type: "number",
            placeholder: "0",
            min: 0,
            step: "${DC_steps.base}",
            validate: "${DC_validationRules.baseAmount}",
            disabled: "${DC_enoughFunds}"
          }
        }
      },
      {
        component: "@ambisafe/ui-layout/{GridCol}",
        children: {
          component: "@ambisafe/ui-layout/{Spacing}",
          props: {
            mt: "10px"
          },
          children: {
            component: "@ambisafe/ui-text/{Paragraph}",
            props: {
              noBottomGutter: true
            },
            children: "${project.projectAsset.displaySymbol}"
          }
        }
      }
    ]
  }
}
