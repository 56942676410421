/* eslint-disable no-template-curly-in-string */
export const Status = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mb: "md"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        inline: true,
        noBottomGutter: true,
        textTransform: "uppercase"
      },
      children: [
        {
          component: "@ambisafe/locale/{FormattedMessage}",
          props: {
            id: "kyc.status"
          }
        },
        {
          component: "span",
          children: ":"
        }
      ]
    },
    {
      component: "span",
      children: " "
    },
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        inline: true,
        noBottomGutter: true,
        textTransform: "uppercase",
        color: "${kycStatusColor}"
      },
      children: {
        component: "@ambisafe/locale/{FormattedMessage}",
        props: {
          id: "kyc.${kycStatus}"
        }
      }
    }
  ]
};
