/* eslint-disable no-template-curly-in-string */
const BuyButtonComponent = {
  component: "@ambisafe/ui-button",
  props: {
    width: "100%",
    disabled: "${DC_isButtonBlocked}",
    type: "submit"
  },
  children: {
    component: "@ambisafe/ui-layout/{Spacing}",
    props: {
      py: "xxs",
      px: "xs"
    },
    children: {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        noBottomGutter: true,
        textAlign: "center",
        textTransform: "uppercase"
      },
      children: [
        {
          component: "react-intl/{FormattedMessage}",
          props: {
            id: "buy"
          }
        },
        {
          component: "span",
          children: " "
        },
        {
          component: "span",
          children: "${project.projectAsset.displaySymbol}"
        }
      ]
    }
  }
};

const NotEnoughFundMessage = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${DC_notEnoughFundsMessageShown}"
  },
  children: {
    component: "@ambisafe/ui-text/{Paragraph}",
    props: {
      color: "warning.main"
    },
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        mt: "sm"
      },
      children:  "Not enough funds on your Orderbook balance to make an investment. "
    }
  }
};

const NotEnoughAvailableTokensMessage = {
  component: "@ambisafe/fe-statements/{IfTrue}",
  props: {
    if: "${DC_notEnoughTokensAvailable}"
  },
  children: {
    component: "@ambisafe/ui-text/{Paragraph}",
    props: {
      color: "warning.main"
    },
    children: {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        mt: "sm"
      },
      children:  "Not enough tokens available. Try to lessen the amount of desired tokens"
    }
  }
};

export const BuyButton = {
  component: "@ambisafe/fe-statements/{IfFalse}",
  props: {
    if: "${selectedForBuyTwoFAType}"
  },
  children: {
    component: "@ambisafe/fe-statements/{IfFalse}",
    props: {
      if: "${processingPurchase}"
    },
    children: [
      BuyButtonComponent,
      NotEnoughFundMessage,
      NotEnoughAvailableTokensMessage
    ]
  }
};
