/* eslint-disable no-template-curly-in-string */
export const Congratulations = {
  component: "@ambisafe/react-router-dom/{Route}",
  props: {
    path: "${match.path}/congratulations",
    prevPath: "${match.url}"
  },
  children: [
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      children: "Congrats! Your transaction is being processed.",
    },
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      children: [
        "You can check this transaction status at the ",
        {
          component: "@ambisafe/react-router-dom/{Link}",
          props: {
            to: "/account/actions-history"
          },
          children: "transaction history page."
        }
      ]
    },
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      children: "New balances:"
    },
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        noBottomGutter: true,
        inline: true,
        opacity: 0.6,
      },
      children: ["${SM_projectAssetDisplaySymbol}", " ="]
    },
    {
      component: "span",
      children: " "
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        inline: true,
        color: "#2386db",
        noBottomGutter: true
      },
      children: "${SM_projectAssetBalance}"
    },
    {
      component: "br",
    },
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        noBottomGutter: true,
        inline: true,
        opacity: 0.6
      },
      children: ["${SM_counterAssetDisplaySymbol}", " ="]
    },
    {
      component: "span",
      children: " "
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        inline: true,
        color: "#2386db",
        noBottomGutter: false
      },
      children: "${SM_counterAssetBalance}"
    },
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: {
        component: "@ambisafe/ui-layout/{GridCol}",
        props: {
          span: 12,
        },
        children: {
          component: "@ambisafe/react-router-dom/{Link}",
          props: {
            to: "${prevPath}"
          },
          children: {
            component: "@ambisafe/ui-button",
            props: {
              width: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Spacing}",
              props: {
                p: "xxs"
              },
              children: {
                component: "@ambisafe/ui-text/{Heading4}",
                props: {
                  noBottomGutter: true,
                  textAlign: "center"
                },
                children: {
                  component: "@ambisafe/ui-text/{Normal}",
                  children: {
                    component: "@ambisafe/locale/{FormattedMessage}",
                    props: {
                      id: "trade_more"
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  ]
};
