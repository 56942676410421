/* eslint-disable no-template-curly-in-string */
const Fee = {
  component: "@ambisafe/ui-layout/{GridCol}",
  children: [
    {
      component: "@ambisafe/ui-text/{Heading5}",
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: {
          component: "@ambisafe/locale/{FormattedMessage}",
          props: {
            id: "fee"
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        noBottomGutter: true,
        fontSize: "lg"
      },
      children: [
        {
          component: "span",
          children: "${depositFee}"
        },
        {
          component: "span",
          children: " "
        },
        {
          component: "span",
          children: "${displaySymbol}"
        }
      ]
    }
  ]
};

const DepositAddress = {
  component: "@ambisafe/ui-layout/{GridCol}",
  children: [
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        ml: "xs",
        mr: "xs",
        mb: "xxs"
      },
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        props: {
          alignItems: "center"
        },
        children: [
          {
            component: '@material-ui/icons/{Warning}'
          },
          {
            component: "@ambisafe/ui-text/{Heading5}",
            props: {
              whiteSpace: "pre",
              noBottomGutter: true,
              inline: true
            },
            children: {
              component: "@ambisafe/ui-text/{Bold}",
              children: [" ", "${providerDescription}", " "]
            }
          },
          {
            component: "@ambisafe/ui-text/{Heading5}",
            props: {
              textTransform: 'lowercase',
              whiteSpace: "pre",
              noBottomGutter: true,
              inline: true
            },
            children: {
              component: "@ambisafe/ui-text/{Normal}",
              children: [
                {
                  component: "@ambisafe/locale/{FormattedMessage}",
                  props: {
                    id: "deposit_address"
                  }
                },
                ". "
              ]
            }
          },
          {
            component: "@ambisafe/ui-text/{Heading5}",
            props: {
              whiteSpace: "pre",
              noBottomGutter: true,
              inline: true
            },
            children: {
              component: "@ambisafe/ui-text/{Normal}",
              children: {
                component: "@ambisafe/locale/{FormattedHTMLMessage}",
                props: {
                  id: "portfolio.make_sure_you_are_using",
                  values: {
                    providerDescription: "${providerDescription}"
                  }
                }
              }
            }
          }
        ]
      }
    },
    {
      component: "@ambisafe/ui-text/{Paragraph}",
      props: {
        fontSize: "lg"
      },
      children: [
        {
          component: "@ambisafe/fe-statements/{IfNotEqualTo}",
          props: {
            if: "${type}",
            to: "BTC"
          },
          children: "${depositAddress}"
        },
        {
          component: "@ambisafe/fe-statements/{IfEqualTo}",
          props: {
            if: "${type}",
            to: "BTC"
          },
          children: "${btcDepositAddress}"
        }
      ]
    },
    {
      component: "@ambisafe/ui-text/{Heading5}",
      props: {
        noBottomGutter: true
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: {
          component: "@ambisafe/locale/{FormattedMessage}",
          props: {
            id: "portfolio.deposit_disclaimer"
          }
        }
      }
    }
  ]
};

const QrCode = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pr: "16px",
    pl: "16px",
    pt: "5px",
  },
  children: {
    component: "@ambisafe/ui-qrcode",
    props: {
      value: "${depositAddress}",
      size: 100,
    }
  }
}

const ClipboardButton = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    pr: "10px",
    pl: "13px",
    pt: "30px",
  },
  children: {
    component: "@orderbook/fe-clipboard-button",
    props: {
      copyValue: "${depositAddress}",
    }
  }
}

export const Receive = {
  component: "@ambisafe/fe-statements/{IfEqualTo}",
  props: {
    if: "${assetId}",
    to: "${selectedForReceiveAssetId}"
  },
  children: [
    {
      component: "@ambisafe/ui-divider",
      props: {
        opacity: 0.2
      }
    },
    {
      component: "@ambisafe/ui-layout/{GridContainer}",
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        props: {
          display: "flex",
          justifyContent: "space-between",
        },
        children: [
          {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [
              Fee,
              DepositAddress,
              ClipboardButton
            ]
          },
          {
            component: "@ambisafe/ui-layout/{GridRow}",
            children: [
              QrCode
            ]
          }
        ]
      }
    }
  ]
};
