/* eslint-disable no-template-curly-in-string */
export const ProjectAbout = {
  component: "@ambisafe/ui-layout/{Spacing}",
  children: [
    {
      component: "@ambisafe/ui-text/{Heading4}",
      props: {
        noBottomGutter: true,
        textTransform: "capitalize",
        opacity: 0.6
      },
      children: {
        component: "@ambisafe/ui-text/{Normal}",
        children: {
          component: "react-intl/{FormattedMessage}",
          props: {
            id: "about_project"
          }
        }
      }
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        pb: "sm"
      }
    },
    {
      component: "@ambisafe/ui-text/{Heading4}",
      props: {
        noBottomGutter: true,
      },
      children: {
        component: "@ambisafe/ui-text/{Light}",
          children: {
            component: "renderHTML",
            children: "${project.description}"
        }  
      }
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        pb: "xl"
      }
    }
  ]
};
