import React from 'react';

function renderChildWithProps(child, props) {
    if (!props || !Object.keys(props).length) {
        return child;
    }
    if (typeof child === "function") {
        return child(props);
    }
    if (!React.isValidElement(child)) {
        throw new Error(`Invalid react element ${child}`);
    }
    const newProps = Object.assign(Object.assign({}, props), child.props);
    return React.cloneElement(child, newProps);
}
function renderChildrenWithProps(children, props) {
    if (!children) {
        return null;
    }
    if (Array.isArray(children)) {
        return React.Children.map(children, child => renderChildWithProps(child, props));
    }
    return renderChildWithProps(children, props);
}

export { renderChildrenWithProps as default };
