/* eslint-disable no-template-curly-in-string */
import { Contribute } from "../Contribute";
import { SignDocuments } from "../SignDocuments";
import { VerifyIdentity } from "../VerifyIdentity";
import { Login } from "../Login";

export const PrimaryMarket = {
  component: "@ambisafe/fe-statements/{IfgtElse}",
  props: {
    if: "${project.endDate}",
    else: "${project.currentDate}"
  },
  children: {
    component: "@ambisafe/fe-statements/{IfgtElse}",
    props: {
      if: "${project.currentDate}",
      else: "${project.startDate}"
    },
    children: {
      component: "@ambisafe/fe-statements/{IfFalse}",
      props: {
        if: "${isSoldOut}",
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          mt: "md"
        },
        children: {
          component: "@ambisafe/react-router-dom/{WithRouter}",
          children: [
            {
              component: "@ambisafe/fe-statements/{IfTrue}",
              props: {
                if: "${isUserValid}"
              },
              children: [
                {
                  component: "@ambisafe/fe-statements/{IfTrue}",
                  props: {
                    if: "${isKycValid}"
                  },
                  children: [Contribute, SignDocuments]
                },
                VerifyIdentity
              ]
            },
            {
              component: "@ambisafe/fe-statements/{IfFalse}",
              props: {
                if: "${isUserValid}"
              },
              children: Login
            }
          ]
        }
      }
    }
  }
};

