/* eslint-disable no-template-curly-in-string */
export const ProjectInfo = {
  component: "@ambisafe/ui-layout/{Spacing}",
  children: [
    {
      component: "@ambisafe/ui-layout/{GridRow}",
      children: [
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 6
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "capitalize",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: "Offering"
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children:  "${project.offeringType}"
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 6
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "capitalize",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: "Shares Offered"
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children:  "${project.instrumentType}"
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 6
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "capitalize",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: "Open Date"
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children: {
                          component: "react-intl/{FormattedDate}",
                          props: {
                            value: "${project.startDate}",
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                          }
                        }
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 6
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "capitalize",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: "Closing Date"
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children: {
                          component: "react-intl/{FormattedDate}",
                          props: {
                            value: "${project.endDate}",
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                          }
                        }
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 6
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "capitalize",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: "Price per Share"
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children: [
                          {
                            component: "span",
                            children: "$"
                          },
                          {
                            component: "react-intl/{FormattedNumber}",
                            props: {
                              value: "${project.priceInUSD}"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 6
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "capitalize",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: "Min Investment"
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children: [
                          {
                            component: "span",
                            children: "$"
                          },
                          {
                            component: "react-intl/{FormattedNumber}",
                            props: {
                              value: "${project.minInvestmentInUSD}"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 6
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "capitalize",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: "Offering Size"
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children: [
                          {
                            component: "span",
                            children: "$"
                          },
                          {
                            component: "react-intl/{FormattedNumber}",
                            props: {
                              value: "${project.offeringSize}"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 6
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "capitalize",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: "Offering Min"
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children: [
                          {
                            component: "span",
                            children: "$"
                          },
                          {
                            component: "react-intl/{FormattedNumber}",
                            props: {
                              value: "${project.softCap}"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 6
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "capitalize",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: "Offering Max"
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children: [
                          {
                            component: "span",
                            children: "$"
                          },
                          {
                            component: "react-intl/{FormattedNumber}",
                            props: {
                              value: "${project.hardCap}"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          component: "@ambisafe/ui-layout/{GridCol}",
          props: {
            span: {
              mobile: 6,
              tablet: 6
            }
          },
          children: {
            component: "@ambisafe/ui-layout/{Spacing}",
            props: {
              pb: "sm",
              height: "100%"
            },
            children: {
              component: "@ambisafe/ui-layout/{Container}",
              props: {
                display: "flex",
                height: "100%"
              },
              children: [
                {
                  component: "@ambisafe/ui-divider",
                  props: {
                    vertical: true,
                    noGutter: true
                  }
                },
                {
                  component: "@ambisafe/ui-layout/{Spacing}",
                  props: {
                    pl: "xxs"
                  },
                  children: [
                    {
                      component: "@ambisafe/ui-text/{Heading5}",
                      props: {
                        noBottomGutter: true,
                        textTransform: "capitalize",
                        opacity: 0.6
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Normal}",
                        children: "Valuation"
                      }
                    },
                    {
                      component: "@ambisafe/ui-text/{Heading4}",
                      props: {
                        noBottomGutter: true
                      },
                      children: {
                        component: "@ambisafe/ui-text/{Light}",
                        children: [
                          {
                            component: "span",
                            children: "$"
                          },
                          {
                            component: "react-intl/{FormattedNumber}",
                            props: {
                              value: "${project.valuationInUSD}"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          }
        }
      ]
    },
    {
      component: "@ambisafe/ui-layout/{Spacing}",
      props: {
        pb: "sm"
      }
    }
  ]
};
