/* eslint-disable no-template-curly-in-string */
export const BalanceInfo = {
  component: "@ambisafe/ui-layout/{GridRow}",
  children: {
    component: "@ambisafe/ui-layout/{GridCol}",
    children: [
      {
        component: "@ambisafe/ui-text/{Heading5}",
        props: {
          opacity: 0.6,
          inline: true,
          noBottomGutter: true
        },
        children: {
          component: "@ambisafe/ui-text/{Normal}",
          children: [
            "${SM_projectAssetDisplaySymbol}",
            {
              component: "span",
              children: " "
            },
            {
              component: "@ambisafe/locale/{FormattedMessage}",
              props: {
                id: "sm.balance"
              }
            }
          ]
        }
      },
      {
        component: "span",
        children: " "
      },
      {
        component: "@ambisafe/ui-text/{Heading5}",
        props: {
          inline: true,
          color: "#2386db",
          noBottomGutter: true
        },
        children: "${SM_projectAssetBalance}"
      },
      {
        component: "br",
        children: " "
      },
      {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          inline: true,
          opacity: 0.6,
          noBottomGutter: true
        },
        children: [
          "${SM_counterAssetDisplaySymbol}",
          {
            component: "span",
            children: " "
          },
          {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: "sm.balance"
            }
          }
        ]
      },
      {
        component: "span",
        children: " "
      },
      {
        component: "@ambisafe/ui-text/{Heading5}",
        props: {
          inline: true,
          color: "#2386db",
          noBottomGutter: true
        },
        children: "${SM_counterAssetBalance}"
      },
      {
        component: "br",
        children: " "
      },
      {
        component: "@ambisafe/ui-text/{Paragraph}",
        props: {
          noBottomGutter: true,
          inline: true,
          opacity: 0.6,
        },
        children: {
          component: "@ambisafe/locale/{FormattedMessage}",
          props: {
            id: 'sm.transaction_fee'
          }
        }
      },
      {
        component: "span",
        children: " "
      },
      {
        component: "@ambisafe/ui-text/{Heading5}",
        props: {
          inline: true,
          color: "#2386db",
          noBottomGutter: true,
        },
        children: ["$", "${SM_fee}"]
      }
    ]
  }
};
