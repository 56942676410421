/* eslint-disable no-template-curly-in-string */
export const VerifyIdentity = {
  component: "@ambisafe/fe-statements/{IfFalse}",
  props: {
    if: "${isKycValid}",
  },
  children: {
    component: "@ambisafe/react-router-dom/{Link}",
    props: {
      to: "${kycRedirectUrl}"
    },
    children: {
      component: "@ambisafe/ui-button",
      props: {
        width: "100%"
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          p: "xxs"
        },
        children: {
          component: "@ambisafe/ui-text/{Heading4}",
          props: {
            noBottomGutter: true,
            textAlign: "center"
          },
          children: {
            component: "@ambisafe/ui-text/{Normal}",
            children: {
              component: "@ambisafe/locale/{FormattedMessage}",
              props: {
                id: "verify_identity"
              }
            }
          }
        }
      }
    }
  }
};
