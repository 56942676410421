/* eslint-disable no-template-curly-in-string */
export const Explanation = {
  component: "@ambisafe/ui-text/{Paragraph}",
  children: [
    {
      component: "@ambisafe/fe-statements/{IfFalse}",
      props: {
        if: "${project.projectPair.isActive}"
      },
      children: {
        component: "@ambisafe/locale/{FormattedMessage}",
        props: {
          id: "sign_documents_explanation"
        }
      }
    },
    {
      component: "@ambisafe/fe-statements/{IfTrue}",
      props: {
        if: "${project.projectPair.isActive}"
      },
      children: {
        component: "span",
        children: [
          {
            component: "@ambisafe/locale/{FormattedMessage}",
            props: {
              id: "secondary_market_sign_documents_explanation",
            }
          },
          {
            component: "span",
            children: " "
          },
          {
            component: "span",
            children: "${project.projectAsset.displaySymbol}"
          }
        ]
      }
    }
  ]
};
