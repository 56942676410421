/* eslint-disable no-template-curly-in-string */

import { Balance } from "./Balance";
import { DisplaySymbol } from "./DisplaySymbol";
import { Icon } from "./Icon";
import { DepositLink, ReceiveLink, SendLink, WithdrawLink } from "./Links";

export const AssetItem = {
  component: "@ambisafe/ui-layout/{GridRow}",
  props: {
    alignItems: "center",
    justifyContent: "space-between"
  },
  children: [
    {
      component: "@ambisafe/ui-layout/{GridCol}",
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        children: [
          Icon,
          DisplaySymbol,
          Balance,
        ]
      }
    },
    {
      component: "@ambisafe/ui-layout/{GridCol}",
      children: {
        component: "@ambisafe/ui-layout/{GridRow}",
        children: [
          DepositLink,
          {
            component: "@ambisafe/fe-statements/{IfEqualToSome}",
            props: {
              if: "${type}",
              to: ["FIAT", "CRYPTOFIAT"]
            },
            children: [
              ReceiveLink,
              SendLink,
            ]
          },
          WithdrawLink
        ]
      }
    }
  ]
};
