/* eslint-disable no-template-curly-in-string */

const WireDisclaimer = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mt: "xs"
  },
  children: {
    component: "@ambisafe/ui-layout/{Container}",
    props: {
      borderColor: "warning.main",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "5px"
    },
    children: {
      component: "@ambisafe/ui-background",
      props: {
        backgroundColor: "warning.light"
      },
      children: {
        component: "@ambisafe/ui-layout/{Spacing}",
        props: {
          p: "xs"
        },
        children: [
          {
            component: "@ambisafe/ui-text/{Paragraph}",
            props: {
              color: "warning.main",
              textAlign: "center"
            },
            children: {
              component: "@ambisafe/locale/{FormattedMessage}",
              props: {
                id: "deposit_fiat_transaction_id_disclaimer"
              }
            }
          },
          {
            component: "@ambisafe/ui-text/{Heading4}",
            props: {
              color: "warning.main",
              noBottomGutter: true,
              textAlign: "center"
            },
            children: [
              {
                component: "@ambisafe/ui-text/{Normal}",
                children: {
                  component: "@ambisafe/locale/{FormattedMessage}",
                  props: {
                    id: "your_reference_id"
                  }
                }
              },
              {
                component: "span",
                children: ": "
              },
              {
                component: "br"
              },
              {
                component: "span",
                children: "${wireTransferCode}"
              }
            ]
          }
        ]
      }
    }
  }
};

const PayPalFee = {
  component: "@ambisafe/ui-layout/{Spacing}",
  props: {
    mt: "xs"
  },
  children: {
    component: "@ambisafe/ui-text/{Paragraph}",
    children: "Card fee: 3.49% + $0.49"
  }
};

export const Disclaimer = {
  component: "@ambisafe/ui-layout/{GridCol}",
  props: {
    width: {
      mobile: "100%",
      tablet: "calc(100% - 265px)"
    }
  },
  children: [
    WireDisclaimer,
    PayPalFee
  ]
};
